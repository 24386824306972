import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card, Modal, Button, Form} from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';

import CardForklift from '../components/CardForklift';
import img from '../img/img_agregar_plus_250x250.png';
import Swal from 'sweetalert2';

function GestionMantetenimientoMontaCarga () {
  const cookie = new Cookies ();
  const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');

  const [showModalInsert, setShowModalInsert] = useState (false);
  const [dataForklift, setDataForklift] = useState ([]);
  const [dataCentros, setDataCentros] = useState ([]);
  const [infoForklift, setInfoForklift] = useState ({
    descripcion_montacarga: '',
    promedio_dia: '',
    id_centro_fk: '',
  });
  const [imgForklift, setImgForklift] = useState (false);

  const queryData = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR +
          '/api/v2/list/montacargas/mantenimiento',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataForklift (data);
    } catch (error) {
      console.log ('Error while fetching data from server: ' + error.message);
    }
  };

  const queryCentros = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/centro',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCentros (data);
    } catch (error) {
      console.log (
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };

  const queryInserForklift = async e => {
    e.preventDefault ();

    // Construye el formdata para enviar la imagen del vehiculo
    let formData = new FormData ();
    formData.append ('img_montacarga_mantenimiento', imgForklift);

    try {
      if (infoForklift.descripcion_montacarga === '') {
        Swal.fire ({
          icon: 'error',
          title: 'La descripción es obligatorio',
        });
      } else if (infoForklift.promedio_dia === '') {
        Swal.fire ({
          icon: 'error',
          title: 'Promedio día es obligatorio',
        });
      } else if (
        infoForklift.id_centro_fk === 'no existe' ||
        infoForklift.id_centro_fk === ''
      ) {
        Swal.fire ({
          icon: 'error',
          title: 'Centro es obligatorio',
        });
      } else {
        const {data} = await axios.post (
          process.env.REACT_APP_SERVIDOR +
            '/api/v2/insert/montacarga/mantenimiento',
          infoForklift,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: '1',
            },
          }
        );
        if (imgForklift) {
          await axios.post (
            process.env.REACT_APP_SERVIDOR +
              `/api/v2/insert/img/montacarga/mantenimiento/${infoForklift.descripcion_montacarga}/${infoForklift.id_centro_fk}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }
        if (data.message === 'success') {
          setShowModalInsert (false);
          setInfoForklift ({
            placa: '',
            promedio_dia: '',
            id_centro: '',
          });
          setImgForklift (false);
          queryData ();
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log ('Error while inserting data car server: ' + error.message);
    }
  };

  const handleCloseModalInsert = () => {
    setShowModalInsert (false);
  };

  const onChangeInfoForklift = e => {
    const {name, value} = e.target;
    setInfoForklift ({
      ...infoForklift,
      [name]: value,
    });
  };

  const onChangeImg = e => {
    setImgForklift (e.target.files[0]);
  };

  const modalInsert = (
    <Modal
      show={showModalInsert}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Información Montacarga</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '2%'}}>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              name="descripcion_montacarga"
              type="text"
              placeholder="Descripción"
              onChange={onChangeInfoForklift}
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Promedio día</Form.Label>
            <Form.Control
              name="promedio_dia"
              type="text"
              placeholder="Promedio día"
              onChange={onChangeInfoForklift}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Centro</Form.Label>
            <Form.Select
              name="id_centro_fk"
              placeholder="Centro"
              onChange={onChangeInfoForklift}
            >
              <option value="no existe">Elige centro</option>
              {dataCentros.map ((item, i) => (
                <option key={i} value={item.id_centro}>
                  {item.centro_descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              type="file"
              placeholder="Imagen"
              onChange={onChangeImg}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={e => queryInserForklift (e)}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect (() => {
    queryCentros ();
    queryData ();
  }, []);

  return (
    <Container className="mt-2 text-center">
      <Row>
        <h1>MONTACARGAS</h1>
      </Row>
      <br/>
      <Row lg={3} md={2} xs={1}>
        {dataForklift.map ((item, i) => (
          <Col key={i} className="mb-5" style={{maxHeight: '270px'}}>
            <CardForklift
              props={{
                name: item.descripcion_montacarga,
                img: item.img_montacarga,
                data: item,    
                queryData: queryData, 
                id_centro: item.id_centro_fk,
              }}
            />
          </Col>
        ))}
        <Col className="mb-5" style={{maxHeight: '270px'}}>
          <Card
            className="mx-auto"
            style={{width: '18rem', height: '100%'}}
            onClick={() => setShowModalInsert (true)}
          >
            <Card.Img variant="top" src={img} style={{padding: '8%'}} />
          </Card>
          {modalInsert}
        </Col>
      </Row>

    </Container>
  );
}
export default GestionMantetenimientoMontaCarga;
