import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Swal from "sweetalert2";

function Consultar() {

    const cookie = new Cookies;
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    const tipo_vehiculo = cookie.get('tipo_vehiculo');

    const [validarRegistros, setValidarRegistros] = useState(false)
    const [consultarRutina, setConsultarRutina] = useState([])
    const [rutina, setRutina] = useState([])
    const [filtro, setFiltro] = useState({rutina: ''})
    
    const ConsultarFiltro = async () => {
        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/listar/rutinas', {tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        if(data.estado){
            setConsultarRutina(data.datos);
        }
    }
    const ConsultarRutinas = async () => {       
         
        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/listar/consultar_rutinas', {tipo: tipo_vehiculo, filtros: filtro.rutina },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        if(data.estado){
            setRutina(data.datos);
        }else{
            setValidarRegistros(true)
        }
    }
    const DeleteRutina = async (id) => {

        try {
            const { data } = await axios.post(
                process.env.REACT_APP_SERVIDOR + '/api/v2/mantenimiento/delete_rutina',
                { id_rutina : id },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Permission: '1',
                    },
                }
            );

            if (data.message === "success") {
                mostrarAlert("success", "Mensaje", "Se Elimino Exitosamente");
                ConsultarRutinas();
            }
        } catch (error) {
            console.log('Error delete rutina: ' + error.message);
            if (error.response.status === 403) {
                Swal.fire ({
                  icon: 'error',
                  title: 'No tienes permiso para esta acción',
                });
              }
        }
    }
    const onChangeInfo = e => {
        const { name, value } = e.target;
        setFiltro({
            ...filtro,
            [name]: value,
        });
    };
    const mostrarAlert = (icono, titulo, mensaje) => {
        Swal.fire({
            icon: icono,
            title: mensaje,
        });
    }

    useEffect(() => {
        ConsultarFiltro();
        ConsultarRutinas();
    }, []);

    return (
        <>
            <Container className="mt-3 text-center">
                <h1>Consultar Rutinas</h1>
                <Row className="mb-3">
                    <Form>
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="auto"><Form.Label className="fs-6">Rutinas</Form.Label></Col>
                            <Col xs="auto">
                                <Form.Select name="rutina" value={filtro.rutina} onChange={onChangeInfo}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        consultarRutina.map((fila, id) => (
                                            <option key={id} value={fila.rutina}>{fila.rutina}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col xs="auto" className="mb-2">
                                <Button variant='dark' onClick={() => ConsultarRutinas()}>Consultar</Button>
                            </Col>
                            <Col xs="auto" className="mb-2">
                                <Button variant='success' href='../gestion-mantenimiento/rutinas/Registrar' >Registrar</Button>
                            </Col>
                        </Row>
                    </Form>
                    <br/><br/><br/>                    
                    <Table stickyHeader aria-label="table-sticky">
                        <thead>
                            <tr>
                                <th>Rutina</th>
                                <th>Elemento</th>
                                <th>Sistema</th>
                                <th>Actividad</th>
                                <th colSpan={2}>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rutina.map((fila, id) =>
                                    <tr key={id}>               
                                        <td>{fila.rutina}</td>
                                        <td>{fila.tipo}</td>
                                        <td>{fila.operaciones}</td>
                                        <td>{fila.actividad}</td>
                                        <td><Button variant='warning' href={'../gestion-mantenimiento/rutinas/Modificar?id=' + fila.id_rutina} >Modificar</Button></td>
                                        <td><Button variant='danger' onClick={() => DeleteRutina(fila.id_rutina)}>Eliminar</Button></td>
                                    </tr>
                                )
                            }
                            {
                                validarRegistros
                                    ?
                                    <tr><td colspan='8'>No hay datos registrados</td></tr>
                                    :
                                    <tr></tr>
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}
export default Consultar;