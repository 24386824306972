import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {Card, Col, Container, Row, Modal, Button, Form} from 'react-bootstrap';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import CardDrivers from '../components/CardDrivers';

import img from '../img/img_agregar_plus_250x250.png';
function GestionMantenimientoCoductores () {
  const cookie = new Cookies ();
  const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');

  const [showModalInsert, setShowModalInsert] = useState (false);
  const [dataDrivers, setDataDrivers] = useState ([]);
  const [dataCentros, setDataCentros] = useState ([]);
  const [tipoVehiculo, setTipoVehiculo] = useState ([]);
  const [infoDriver, setInfoDriver] = useState ({
    documento_conductor: '',
    nombre_conductor: '',
    tipo_conductor: '',
    id_centro_fk: '',
  });
  const [imgDriver, setImgDriver] = useState (false);

  const queryData = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR +
          '/api/v2/list/conductores/mantenimiento',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataDrivers (data);
    } catch (error) {
      console.log ('Error while fetching data drivers from server');
    }
  };

  const queryCentros = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/centro',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCentros (data);
    } catch (error) {
      console.log (
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
const queryTipoVehiculo = async () => {
  try {
    const {data} = await axios.get (
      process.env.REACT_APP_SERVIDOR + '/api/v2/listar/vehiculos',
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    setTipoVehiculo (data.datos);
    
  } catch (error) {
    console.log (
      'Error while fetching data vehiculo from server: ' + error.message
    );
  }
};
  const queryInsetDrivers = async e => {
    e.preventDefault ();

    // Construye el formdata para enviar la imagen
    let formData = new FormData ();
    formData.append ('img_driver_mantenimiento', imgDriver);
    try {
      if (infoDriver.nombre_conductor === '') {
        Swal.fire ({
          icon: 'error',
          title: 'El nombre es obligatorio',
        });
      } else if (infoDriver.documento_conductor === '') {
        Swal.fire ({
          icon: 'error',
          title: 'El documento es obligatorio',
        });
      } else if (
        infoDriver.tipo_conductor === '' ||
        infoDriver.tipo_conductor === 'no existe'
      ) {
        Swal.fire ({
          icon: 'error',
          title: 'El tio de conductor es obligatorio',
        });
      } else if (
        infoDriver.id_centro_fk === '' ||
        infoDriver.id_centro_fk === 'no existe'
      ) {
        Swal.fire ({
          icon: 'error',
          title: 'El centro es obligatorio',
        });
      } else {
        const {data} = await axios.post (
          process.env.REACT_APP_SERVIDOR +
            '/api/v2/insert/conductores/mantenimiento',
          infoDriver,
          {
            headers: {
              Authorization: 'Bearer ' + access_token,
              Permission: '1',
            },
          }
        );
        if (imgDriver) {
          await axios.post (
            process.env.REACT_APP_SERVIDOR +
              `/api/v2/insert/img/conductores/mantenimiento/${infoDriver.nombre_conductor}/${infoDriver.id_centro_fk}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }

        if (data.message === 'success') {
          setShowModalInsert (false);
          setInfoDriver ({
            documento_conductor: '',
            nombre_conductor: '',
            tipo_conductor: '',
            id_centro_fk: '',
          });
          setImgDriver (false);
          queryData ();
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
        console.log (
          'Error while inserting data driver server: ' + error.message
        );
      }
    }
  };

  const handleCloseModalInsert = () => {
    setShowModalInsert (false);
  };

  const onChangeInfoDriver = e => {
    const {name, value} = e.target;
    setInfoDriver ({
      ...infoDriver,
      [name]: value,
    });
  };

  const onChangeImg = e => {
    setImgDriver (e.target.files[0]);
  };

  const modalInsert = (
    <Modal
      show={showModalInsert}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Información Conductor</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '2%'}}>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              name="nombre_conductor"
              type="text"
              placeholder="Nombre"
              onChange={onChangeInfoDriver}
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Documento</Form.Label>
            <Form.Control
              name="documento_conductor"
              type="number"
              placeholder="Documento"
              onChange={onChangeInfoDriver}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Tipo conductor</Form.Label>
            <Form.Select
              name="tipo_conductor"
              placeholder="Tipo conductor"
              onChange={onChangeInfoDriver}
            >
              <option value="no existe">Elige tipo conductor</option>                
                {tipoVehiculo.map((item, i) => (
                <option key={i} value={item.nombre}>
                  {item.nombre}
                </option>
              ))}

            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Centro</Form.Label>
            <Form.Select
              name="id_centro_fk"
              placeholder="Centro"
              onChange={onChangeInfoDriver}
            >
              <option value="no existe">Elige centro</option>
              {dataCentros.map ((item, i) => (
                <option key={i} value={item.id_centro}>
                  {item.centro_descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              name="img_driver"
              type="file"
              placeholder="Imagen"
              onChange={onChangeImg}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={e => queryInsetDrivers (e)}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect (() => {
    queryTipoVehiculo ();
    queryData ();
    queryCentros ();
  }, []);

  return (
    <Container className="mt-2 text-center">
      <Row>
        <h1>CONDUCTORES</h1>
      </Row>
      <Row lg={4} md={2} xs={1}>
        {dataDrivers.map ((item, i) => (
          <Col key={i} className="mb-5" style={{maxHeight: '300px', maxWidth: 'max-content',textAlign: 'center', fontSize: '18px !important'}}>
            <CardDrivers
              props={{
                nombre: item.nombre_conductor,
                img: item.img_conductor,
                data: item,
                queryData,
                id_centro_fk: item.id_centro_fk,
              }}
            />
          </Col>
        ))}
        <Col className="mb-5" style={{maxHeight: '270px'}}>
          <Card
            className="mx-auto"
            style={{width: '18rem', height: '100%'}}
            onClick={() => setShowModalInsert (true)}
          >
            <Card.Img variant="top" src={img} style={{padding: '8%'}} />
          </Card>
          {modalInsert}
        </Col>
      </Row>
    </Container>
  );
}

export default GestionMantenimientoCoductores;
