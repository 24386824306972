import { Container, Table } from 'react-bootstrap'
import caracteristicas from '../img/caracteristicasEmpresas.png';

function CaracteristicasEmpresa() {
    
    return (
        <>
            <br />
            <h1>CARACTERISTICAS DE LA EMPRESA</h1>

            <Container>
                <br />
                <div>
                    <p style={{ textAlign: 'center' }}><img src={caracteristicas} alt='Caracteristicas de la empresa' /></p>
                    <p style={{ textAlign: 'right' }}><a href='https://archivodocumentos.soferco.tech/pdf_pesv/CERTIFICADO%20DE%20CAMARA%20FEBRERO%20%2015%20%202022.pdf'>Anexo 3. Certificado de Cámara de Comercio</a></p>{/* Ingresar la camara de comercio, validar si eso se esta renovando */}
                    <br />
                    <h5>Análisis del Personal y Desplazamientos</h5>
                    <br />
                    <Table>
                        <thead>
                            <th>ÁREA</th>
                            <th>MOTIVO DE DESPLAZAMIENTO</th>
                            <th>DESCRIPCIÓN DEL DESPLAZAMIENTO</th>
                            <th>ROL EN LA VÍA</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Administrativo</td>
                                <td>Desplazamiento al lugar de trabajo</td>
                                <td>Vehículos propios del personal o en transporte público</td>
                                <td>Conductor y pasajero</td>
                            </tr>
                            <tr>
                                <td>Comercial</td>
                                <td>Desplazamiento al lugar de trabajo. Promotores y vendedores externos, visitas clientes</td>
                                <td>Vehículos propios del personal o en transporte público</td>
                                <td>Conductor y pasajero</td>
                            </tr>
                            <tr>
                                <td>Logística</td>
                                <td>Desplazamiento al lugar de trabajo</td>
                                <td>Vehículos propios del personal o en transporte público</td>
                                <td>Conductor y pasajero</td>
                            </tr>
                            <tr>
                                <td>Operativos</td>
                                <td>Desplazamiento al lugar de trabajo. Los conductores y ayudantes se trasladan a entregar mercancía</td>
                                <td>Vehículos propios del personal o en transporte público.</td>
                                <td>Conductor y pasajero</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <h5>Servicios que presta la empresa</h5>
                    <br />
                    <p className='texto'>Comercialización al por mayor y al detal de artículos de ferretería, y entrega a domicilio por solicitud del cliente.</p>

                    <br />
                    <h5 style={{ textAlign: 'center' }}>Población de la compañía</h5>
                    <h6 style={{ textAlign: 'center' }}>Tamaño de la empresa: <strong>157</strong> Trabajadores</h6>
                    <Table>
                        <thead>
                            <th>TIPO</th>
                            <th>CANTIDAD DE EMPLEADOS</th>
                            <th>%</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Directos</td>
                                <td>115</td>
                                <td>68%</td>
                            </tr>
                            <tr>
                                <td>Temporales</td>
                                <td>42</td>
                                <td>24.9%</td>
                            </tr>
                            <tr>
                                <td>Contratistas</td>
                                <td>12</td>
                                <td>7.1%</td>
                            </tr>
                            <tr style={{ background: '#80808069' }}>
                                <td>TOTAL</td>
                                <td>157</td>
                                <td>100%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <h5>Tipo de vehículos</h5>
                    <Table>
                        <thead>
                            <th>TIPO</th>
                            <th>CANTIDAD DE EMPLEADOS</th>
                            <th>PROPIOS</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Camioneta</td>
                                <td>21</td>
                                <td>11 y 10 Contratistas</td>
                            </tr>
                            <tr>
                                <td>Montacargas</td>
                                <td>6</td>
                                <td>6</td>
                            </tr>
                            <tr style={{ background: '#80808069' }}>
                                <td>TOTAL</td>
                                <td>169</td>
                                <td>100%</td>
                            </tr>
                        </tbody>
                    </Table>

                    <br />
                    <h5>Ciudades donde opera la empresa</h5>
                    <br />
                    <Table>
                        <thead>
                            <th colSpan={2} style={{ background: '#80808069', textAlign: 'center' }}>SEDE PRINCIPAL</th>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Departamento</td>
                                <td>Valle Del Cauca</td>
                            </tr>
                            <tr>
                                <td>Ciudad</td>
                                <td>Guadalajara de Buga</td>
                            </tr>
                            <tr>
                                <td>Cantidad de empleados</td>
                                <td>71</td>
                            </tr>
                            <tr>
                                <td>Vehículos </td>
                                <td>6 propios y 4 contratistas</td>
                            </tr>
                            <tr>
                                <td>Dirección</td>
                                <td>Carrera 11 #7-34</td>
                            </tr>
                            <tr>
                                <td>Teléfono</td>
                                <td>2363000</td>
                            </tr>
                            <tr>
                                <td>Nombre de la ARL</td>
                                <td>Sura</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <Table>
                        <thead>
                            <th colSpan={2} style={{ background: '#80808069', textAlign: 'center' }}>SUCURSAL</th>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Nombre de la Sede</td>
                                <td>FERRETERÍA MASTER Palmira</td>
                            </tr>
                            <tr>
                                <td>Departamento</td>
                                <td>Valle Del Cauca</td>
                            </tr>
                            <tr>
                                <td>Ciudad</td>
                                <td>Palmira</td>
                            </tr>
                            <tr>
                                <td>Cantidad de empleados </td>
                                <td>82</td>
                            </tr>
                            <tr>
                                <td>Vehículos</td>
                                <td>5 propios y 6 contratistas</td>
                            </tr>
                            <tr>
                                <td>Dirección</td>
                                <td>Carrera 33A # 28 -24</td>
                            </tr>
                            <tr>
                                <td>Teléfono</td>
                                <td>2660066</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <Table>
                        <thead>
                            <th colSpan={2} style={{ background: '#80808069', textAlign: 'center' }}>SUCURSAL</th>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Nombre de la Sede</td>
                                <td>FERRETERÍA MASTER Tulua</td>
                            </tr>
                            <tr>
                                <td>Departamento</td>
                                <td>Valle Del Cauca</td>
                            </tr>
                            <tr>
                                <td>Ciudad</td>
                                <td>Tulua</td>
                            </tr>
                            <tr>
                                <td>Cantidad de empleados </td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>Dirección</td>
                                <td>Cra. 40 #33-21</td>
                            </tr>
                            <tr>
                                <td>Teléfono</td>
                                <td>2339733</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <h5>Mecanismo de contratación de vehículos</h5>
                    <br />
                    <p className='texto'>
                        La SOCIEDAD FERRETERA DE COMERCIO S.A, contrata los vehículos para el transporte de mercancía desde el área de logística, teniendo en cuenta las siguientes condiciones:<br />
                        ▪	Para realizar contratación con terceros independientes para la prestación de algún tipo de servicio tales como: transporte, descargue, parqueadero, reparaciones, publicidad, entre otros se deben tener en cuenta los siguientes aspectos legales: <br />
                        ▪	Quienes perciban ingresos mensuales o superiores a un (1) salario mínimo mensual legal vigente (SMMLV) cotiza al Sistema de Seguridad social sobre un ingreso base de cotización (IBC) mínimo del 40% del valor mensual de sus ingresos sin incluir el valor del IVA cuando a ello haya lugar. <br />
                        ▪	El contratista debe realizar aportes al sistema de seguridad social como salud, pensión y riesgos profesionales de forma independiente no bajo la modalidad de asociaciones y agremiaciones, quien a su vez debe suministrar copia de la planilla de pago de seguridad social la cual debe estar vigente. <br />
                        ▪	Si el contratista no cumple con estos requisitos no podrá realizar la prestación del servicio, ya que al incurrir en un gasto sin el cumplimiento, el monto del servicio prestado no será reconocido como deducible de renta por la entidad reguladora (DIAN). <br />
                        ▪	Adicionalmente a las implicaciones de carácter tributario y, de acuerdo al Decreto 1072 de 2015 el cual regula el sector del trabajo, la empresa debe exigir que todas las personas que vayan a prestar servicios para la misma, estén afiliados al sistema de seguridad social y aporten en debida forma, en aras de evitar perjuicios para la empresa que se traduzcan en responsabilidad civil. <br />
                    </p>
                    <p style={{ textAlign: 'right' }}>Anexo No. 4. Contrato de servicio de transporte</p>

                    <br />
                    <h5>Mecanismo de contratación de conductores</h5>
                    <br />
                    <p className='texto'>
                        La empresa SOCIEDAD FERRETERA DE COMERCIO S.A, contrata al personal de conductores que se encarga de la operación de vehículos propios para el transporte de mercancías dentro de sus sedes y en el despacho a los clientes según la operación
                        Con las hojas de vida que se reciben antes en físico y a partir de agosto de 2017 en el siguiente link: https://consultas.ferremaster.com.co:8080/calidad/CONSULTA/HojasVida/form.php
                        seleccionan los candidatos que cumplen con el perfil y se establecen las actividades necesarias para definir la persona que se va a contratar.<br />
                        Dentro de estas actividades están:<br />

                        ▪	Aplicación de pruebas para establecer habilidades, competencias o conocimientos (Pruebas Teóricas)<br />
                        ▪	Pruebas Prácticas<br />
                        ▪	Pruebas Médicas de Control (Examen Médico Ocupacional y pruebas Complementarias)<br />
                        ▪	Copia de la licencia de conducción vigente<br />
                        ▪	El trabajador debe presentar el certificado de aptitud como resultado de los exámenes Psicosensométrico y de idoneidad del establecimiento que lo expide.<br />
                        ▪	Entrevista con el Gestor de área o inmediato<br />
                        ▪	Verificación de referencias laborales<br />
                        ▪	Antecedentes disciplinarios<br />
                        ▪	Copia ampliada de la cédula de ciudadanía al 150%<br />
                        ▪	Paz y Salvo SIMIT o acuerdo de pago al día con copias de las consignaciones<br />
                        ▪	Paz y Salvo del último empleador<br />
                        ▪	Apertura de cuenta para el pago de nómina<br />
                        ▪	Firma del contrato laboral según corresponda<br />
                        ▪	fotos recientes<br />

                        La empresa cuenta con una página web en la cual por medio del link https://master507.ferremaster.com.co/calidad/CONSULTA/SubirArchivo/Lista.php?Area=PL&COD2=SST&Tipo=&buscar=Consultar se puede consultar el procedimiento de contratación para personal tiempo completo en un documento y en otro el protocolo para contratación de trabajadores por prestación de servicios, honorarios y comisiones de manera interna para todos los trabajadores que tienen acceso a la página y requieran la información.<br />

                    </p>
                    <br/>
                    <p style={{ textAlign: 'right' }}>Anexo 5. protocolo para contratación de trabajadores por prestación de servicios, honorarios y comisiones</p>

                </div>
            </Container>
        </>
    );
}
export default CaracteristicasEmpresa;