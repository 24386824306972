import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Swal from "sweetalert2";
import XLSX from 'sheetjs-style';
import { saveAs } from 'file-saver';

function ConsultarMantenimiento() {

    const cookie = new Cookies;
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    const tipo_vehiculo = cookie.get('tipo_vehiculo');

    const [validarRegistros, setValidarRegistros] = useState(false)
    const [mantenimiento, setMantenimiento] = useState([])
    const [centroCompania, setCentroCompania] = useState([])
    const [vehiculo, setVehiculo] = useState([])
    const [filtro, setFiltro] = useState({
        centro: '',
        vehiculo: '',
        rangoFechaInicio: '',
        rangoFechaFinal: ''
    })
    
    const [showExportExcel, setShowExportExcel] = useState(false);
   
    const ConsultarVehiculos = async () => {

        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/mantenimiento/consultar/vehiculos', { tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        setVehiculo(data.datos);
    }
    const ConsultarCentro = async () => {

        const { data } = await axios.get(
            process.env.REACT_APP_SERVIDOR + '/api/v2/list/centro',
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        setCentroCompania(data);
    }
    const ConsultarKilometraje = async () => {
        try {
            setMantenimiento([]);

            const objDatos = {
                tipo: tipo_vehiculo,
                filtro: filtro
            };

            const { data } = await axios.post(
                process.env.REACT_APP_SERVIDOR +
                '/api/v2/listar/consultar/Mantenimiento',
                objDatos,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );
            if (data.estado) {
                
                setMantenimiento(data.datos);
                setValidarRegistros(false);
            } else {
                setValidarRegistros(true);
            }

        } catch (error) {
            console.log('Error consular mantenimiento:' + error)
        }
    }
    const DeleteMantenimiento = async (id) => {
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_SERVIDOR + '/api/v2/mantenimiento/delete_mantenimiento',
                { id_mantenimiento : id },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Permission: '1',
                    },
                }
            );

            if (data.message === "success") {
                mostrarAlert("success", "Mensaje", "Se Elimino Exitosamente");
                ConsultarKilometraje();
                await queryCumplimiento();
            }
        } catch (error) {
            console.log('Error delete mantenimiento: ' + error.message);
            if (error.response.status === 403) {
                Swal.fire ({
                  icon: 'error',
                  title: 'No tienes permiso para esta acción',
                });
              }
        }
    }
    const queryCumplimiento = async () =>{
        await axios.post(
            process.env.REACT_APP_SERVIDOR + '/api/v2/listar/mantenimiento/informe_cumplimiento/tipo_vehiculo',
            {tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
    }
    const onChangeInfo = e => {
        const { name, value } = e.target;
        setFiltro({
            ...filtro,
            [name]: value,
        });
    };
    const preciofactura = mantenimiento.map(item => ({
        Precio_Factura: item.precio_factura
      }));
      const sumaTotal = preciofactura.reduce((acumulador, itemActual) => acumulador + itemActual.Precio_Factura, 0);

    

    const queryExportExcel = async e => {
        try {
            const mantenimientoExcel = mantenimiento.map(item => ({
                Fecha: item.fecha,
                Rutina: item.id_rutina_fk,
                Vehiculo: item.placa,
                kilometraje: item.kilometraje,
                Descripcion: item.descripcion,
                Proveedor: item.proveedor,
                Factura: item.factura,
                Precio_Factura: item.precio_factura
              }));
          const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    
          const ws = XLSX.utils.json_to_sheet(mantenimientoExcel);
          const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
          const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' });
          const file = new Blob([excelBuffer], { type: fileType });
          saveAs(
            file,
            `mantenimiento_fecha_consulta${moment().format('DD-MM-YYYY')}_${moment().unix()}.xlsx`
          );
          setShowExportExcel(false);
          
         
        } catch (error) {
          console.log('Error exporting Excel file: ' + error.message);
        } 
      };

    const mostrarAlert = (icono, titulo, mensaje) => {
        Swal.fire({
            icon: icono,
            title: mensaje,
        });
    }

    useEffect(() => {
        ConsultarCentro();
        ConsultarVehiculos();
        ConsultarKilometraje();
        
    }, []);
    return (
        <>
            <Container className="mt-3 text-center">
                <h1>Consultar Mantenimiento</h1>
                <Row className="mb-3">
                    <Form>
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="auto"><Form.Label className="fs-6">Centros</Form.Label></Col>
                            <Col xs="auto">
                                <Form.Select name="centro" value={filtro.centro} onChange={onChangeInfo}
                                >
                                    <option value="">Seleccione...</option>
                                    {

                                        centroCompania.map((fila, id) => (
                                            <option key={id} value={fila.id_centro}>{fila.centro_descripcion}</option>
                                        ))
                                    }

                                </Form.Select>
                            </Col>
                            <Col xs="auto"><Form.Label className="fs-6">vehículo</Form.Label></Col>
                            <Col xs="auto">
                                <Form.Select
                                    name="vehiculo"
                                    value={filtro.vehiculo}
                                    onChange={onChangeInfo}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        vehiculo.map((fila, id) => (
                                            <option key={id} value={fila.id_vehiculo}>{fila.placa}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col xs="auto"><Form.Label className="fs-6">Rango de fechas</Form.Label></Col>
                            <Col xs="auto" className="mb-2">
                                <Form.Control type="date" name="rangoFechaInicio" value={filtro.rangoFechaInicio} onChange={onChangeInfo} />
                            </Col>
                            <Col xs="auto" className="mb-2">
                                <Form.Control type="date" name="rangoFechaFinal" value={filtro.rangoFechaFinal} onChange={onChangeInfo} />
                            </Col>
                            <Col xs="auto" className="mb-2">
                                <Button variant='dark' onClick={() => ConsultarKilometraje()}>Consultar</Button>
                            </Col>
                            <Col xs="auto" className="mb-2">
                                <Button variant='success' href='../gestion-mantenimiento/registrar-mantenimiento/Registrar' >Registrar</Button>
                            </Col>
                            <Col xs="2" className="mb-2">
                                <Button variant="success" onClick={() => queryExportExcel()} >Exportar Excel</Button>
                            </Col>
                        </Row>
                        
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="auto" className="mb-4 " >
                                <h3 variant="success"><b>Total Factura: $ {sumaTotal}</b> </h3>
                            </Col>
                        </Row>
                    </Form>
                    <br />
                    <Table  className="table table-bordered table-striped table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Rutina</th>
                                        <th>Vehículo</th>
                                        {tipo_vehiculo === '4' ? <th>Horometro</th>:<th>Kilometraje</th> }
                                        <th>Descripción</th>
                                        <th>Proveedor</th>
                                        <th>Factura</th>
                                        <th>Precio Factura</th>
                                        <th>Documento Factura</th>
                                        <th colSpan={2}>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        mantenimiento.map((fila, id) =>
                                            <tr key={id}>
                                                <td>{moment(fila.fecha).format('YYYY-MM-DD')}</td>
                                                <td>{fila.id_rutina_fk}</td>
                                                <td>{fila.placa}</td>
                                                <td>{fila.kilometraje}</td>
                                                <td>{fila.descripcion}</td>
                                                <td>{fila.proveedor}</td>
                                                <td>{fila.factura}</td>
                                                <td>{fila.precio_factura}</td>
                                                <td>
                                                { 
                                                    fila.documento === '' || fila.documento === null || undefined
                                                    ?
                                                    <a></a>
                                                    :
                                                    <a href={process.env.REACT_APP_HOST_FTP +'documentosFacturasVehiculos/' +fila.documento } target="_blank" rel="noreferrer noopener" className="text-decoration-none">Documento</a>
                                                }
                                                </td>
                                                <td><Button variant='warning' href={'../gestion-mantenimiento/registrar-mantenimiento/Modificar?id=' + fila.id_mantenimiento} >Modificar</Button></td>
                                                <td><Button variant='danger' onClick={() => DeleteMantenimiento(fila.id_mantenimiento )}>Eliminar</Button></td>
                                            </tr>
                                        )
                                    }
                                    {
                                        validarRegistros
                                            ?
                                            <tr><td colspan='8'>No hay datos registrados</td></tr>
                                            :
                                            <tr></tr>
                                    }
                                </tbody>
                            </Table>
                </Row>
            </Container>
        </>
    )
}
export default ConsultarMantenimiento;