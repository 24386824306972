import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Swal from "sweetalert2";

function IndicadorFlota() {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');

    const [validated, setValidated] = useState(false);
    const [datos, setDatos] = useState({
        cpk: '',
        disponibilidad: ''
    });
    const consularFlota = async () => {
        const { data } = await axios.get(
            process.env.REACT_APP_SERVIDOR + '/api/v2/listar/indicadorFlota',
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );

        if (data.estado) {
            setDatos({
                cpk: data.datos[0].cpk,
                disponibilidad: data.datos[0].disponibilidad
            });
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Mensaje',
                text: 'No existen indicadores registrados',
                showConfirmButton: false
            })
        }
    }
    const validityForm = async (e) => {
        try {
            const form = e.currentTarget;

            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
                setValidated(true);
            } else {
                e.preventDefault();

                const { data } = await axios.post(
                    process.env.REACT_APP_SERVIDOR + '/api/v2/modificar/indicadorFlota', { dato: datos },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                            Permission: '1',
                        },
                    }
                );

                if (data.message === "success") {
                    mostrarAlert("success", "Mensaje", "Actualización Exitoso");
                    consularFlota();
                    setValidated(false);

                }
            }
        } catch (error) {
            if (error.response.status === 403) {
                Swal.fire({
                    icon: 'error',
                    title: 'No tienes permiso para esta acción',
                });
            }
        }

    };
    const onChangeInfo = e => {
        const { name, value } = e.target;
        setDatos({
            ...datos,
            [name]: value,
        });
    };
    const mostrarAlert = (icono, titulo, mensaje) => {
        Swal.fire({
            icon: icono,
            title: mensaje,
        });
    }
    useEffect(() => {
        consularFlota();
    }, []);
    return (
        <>
            <Container className="text-center mt-3">
                <Row className="mb-3">
                    <h1>Gestión de Indicadores Flota</h1>
                </Row>
                <Row className="mb-3">
                    <Form noValidate validated={validated} onSubmit={e => validityForm(e)}>
                        <br /><br />
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Form.Label>CPK: </Form.Label></Col>
                            <Col xs="3">
                                <Form.Group >
                                    <Form.Control required name="cpk" type="number" value={datos.cpk} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid">Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="mb-1 align-items-center justify-content-center">

                            <Col xs="2"><Form.Label>Disponibilidad: </Form.Label></Col>
                            <Col xs="3">
                                <Form.Group >
                                    <Form.Control required name="disponibilidad" type="number" value={datos.disponibilidad} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid">Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Button type="submit" variant='dark'>Aceptar</Button></Col>
                        </Row>
                    </Form>
                    <br />
                </Row>
            </Container>
        </>
    );
}
export default IndicadorFlota;