import {useLocation} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {Badge, Button, Col, Container, Form, Row} from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

function RegistrarUsuario () {
  
  const location = useLocation();
  
  const [mensaje, setMensaje] = useState ();
  const [idUsuario, setIdUsuario] = useState ();
  const [validated, setValidated] = useState (false);
  const [permisos, setPermisos] = useState ([]);
  const [perfiles, setPerfiles] = useState ([]);
  const [user, setUser] = useState ({
    nombre: '',
    usuario: '',
    contrasena: '',
    confirmar_contrasena: '',
    perfiles: '',
    permisos: '',
    declaracion: '',
    fecha_declaracion: ''
  });
  const [validacionDeclaracion, setValidacionDeclaracion] = useState ('block');

  const consultarValidacion = async () =>{
    const cookie = new Cookies ();
      const access_token = cookie.get (
        'perv_v2_cookies_user_kdfoeinapjdkfawep'
      );

    let rutaActual = window.location.search;
    
    if(rutaActual !== ''){
      setMensaje('Modificar');
      let id = rutaActual.split('=');
      id = id[1];
      setIdUsuario(id)
      const objDatos = await axios.get (
        process.env.REACT_APP_SERVIDOR + `/consultar_usuario?id=${id} `,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      
      const {nombre, usuario, contrasena, perfiles,id_permiso, declaracion, fecha_declaracion} = objDatos.data[0];
      
      if(declaracion === 'si'){
        setValidacionDeclaracion('block')
        document.getElementById('fecha_declaracion').required = true;
      }else{
        setValidacionDeclaracion('none')
        document.getElementById('fecha_declaracion').required = false;
      }

      setUser ({
        nombre: nombre,
        usuario: usuario,
        contrasena: contrasena,
        confirmar_contrasena: contrasena,
        perfiles: perfiles,
        permisos: id_permiso,
        declaracion: declaracion,
        fecha_declaracion: fecha_declaracion
      });
      
    }else{
      
      setMensaje('Registrar');
    }
  }

  const queryPerfilesPermisos = async () => {
    try {
      const cookie = new Cookies ();
      const access_token = cookie.get (
        'perv_v2_cookies_user_kdfoeinapjdkfawep'
      );

      const queryPerfiles = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/perfiles/usuarios',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setPerfiles (queryPerfiles.data);

      const queryPermisos = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/permisos/usuarios',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      setPermisos (queryPermisos.data);
    } catch (error) {
      console.log (
        'Error while querying permissions and perfiles: ' + error.message
      );
    }
  };

  const onChangeUser = e => {
    const {name, value} = e.target;

    setUser ({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async event => {
    const cookie = new Cookies ();
    const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');
    const form = event.currentTarget;
    if (form.checkValidity () === false) {
      event.preventDefault ();
      event.stopPropagation ();
    } else {
      event.preventDefault ();
      // Valida que las contraseñas sean iguales
      if (user.contrasena !== user.confirmar_contrasena) {
        Swal.fire ({
          icon: 'error',
          title: 'Las contraseñas no coinciden',
        });
        return;
      }
      try {
        
        if(mensaje === 'Registrar'){
          
          const {data} = await axios.post (
            process.env.REACT_APP_SERVIDOR + '/api/v2/insert/usuario',
            user,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
              },
            }
          );
          if (data.message === 'success') {
            Swal.fire ({
              icon: 'success',
              title: 'Registro Exitoso',
            });
          }
          
          window.location.href= '/GestionUsuario';
          
        }else{
          
          const {data} = await axios.put (
          process.env.REACT_APP_SERVIDOR + '/api/v2/updat/usuario',
          [user, idUsuario],
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
              },
            }
          );
          if (data.message === 'success') {
            Swal.fire ({
              icon: 'success',
              title: 'Actualizacion Exitosa',
            });
          }

        }

      } catch (error) {
        if (error.response.status === 403) {
          Swal.fire({
            icon: 'error',
            title: 'No tienes permiso para esta acción',
          });
        }
      }
    }

    setValidated (true);
    
  };

  const handleValidarDeclaracion = (e) =>{
    let respuesta = e.target.value;
    
    setValidacionDeclaracion('block')
    if(respuesta === 'si'){
      setValidacionDeclaracion('block')
      document.getElementById('fecha_declaracion').required = true;
    }else{
      setValidacionDeclaracion('none')
      document.getElementById('fecha_declaracion').required = false;
    }
    onChangeUser(e);
  }
  useEffect (() => {
    consultarValidacion();
    queryPerfilesPermisos ();
  }, []);

  return (
    <Container
      className="mt-3"
      style={{
        width: '50%',
        border: '1px solid black',
        padding: '2.5%',
        borderRadius: '5px',
      }}
    >
      <Row className="text-center">
        <h1><Badge bg="dark">{mensaje} usuario</Badge></h1>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nombre"
              name="nombre"
              value={user.nombre}
              onChange={onChangeUser}
            />
            <Form.Control.Feedback type="invalid">
              Nombre obligatorio
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Usuario"
              name="usuario"
              value={user.usuario}
              onChange={onChangeUser}
            />
            <Form.Control.Feedback type="invalid">
              Usuario obligatorio
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Contraseña"
              required
              name="contrasena"
              value={user.contrasena}
              onChange={onChangeUser}
            />
            <Form.Control.Feedback type="invalid">
              Contraseña obligatoria
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom04">
            <Form.Label>Confirmar contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirmar contraseña"
              name="confirmar_contrasena"
              required
              value={user.confirmar_contrasena}
              onChange={onChangeUser}
            />
            <Form.Control.Feedback type="invalid">
              Confirmación obligatoria
            </Form.Control.Feedback>
          </Form.Group>

        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="hla">
            <Form.Label>Perfil</Form.Label>
            <Form.Select
              placeholder="Perfil"
              name="perfiles"
              value={user.perfiles}
              onChange={onChangeUser}
              required
            >
              <option value="">Asigna un perfil</option>
              {perfiles.map ((element, e) => (
                <option key={e} value={element.descripcion_perfil}>
                  {element.descripcion_perfil}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Perfil obligatorio
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="hla">
            <Form.Label>Permisos</Form.Label>
            <Form.Select
              placeholder="Permisos"
              name="permisos"
              value={user.permisos}
              onChange={onChangeUser}
              required
            >
              <option value="">Asigna un permiso</option>
              {permisos.map ((item, i) => (
                <option key={i} value={item.id_permiso}>
                  {item.desc_permiso}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Permiso obligatorio
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="hla">
            <Form.Label>Declaración</Form.Label>
            <Form.Select
              placeholder="Declaración"
              name="declaracion"
              value={user.declaracion}
              onChange={handleValidarDeclaracion}
              required
            >
              <option value="">Confirmar Declaración</option>
              <option value="si" >Si</option>
              <option value="no" >No</option>

            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Declaracion obligatorio
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="hla" style={{display: validacionDeclaracion}}>
            <Form.Label>Asignacion de declaración</Form.Label>
            <Form.Select
              placeholder="Fecha de declaración"
              id="fecha_declaracion"
              name="fecha_declaracion"
              value={user.fecha_declaracion}
              onChange={onChangeUser}
              required
            >
              <option value="">Confirmar Fecha de declaración</option>
              <option value="1">LUNES</option>
              <option value="2">MARTES</option>
              <option value="3">MIERCOLES</option>
              <option value="4">JUEVES</option>
              <option value="5">VIERNES</option>
              <option value="6">SABADO</option>
              
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Declaracion obligatorio
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="text-center">
          <Button type="submit">{mensaje} usuario</Button>
        </Row>
      </Form>
    </Container>
  );
}

export default RegistrarUsuario;
