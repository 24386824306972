import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import Swal from "sweetalert2";
import CardGraficas from '../components/CardGraficas';


function Indicador() {

    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');


    const Formulario = () => { navigate('/FormularioIndicadores') }
    const [dataIndicadores, setDataIndicadores] = useState ([]);
    const navigate = useNavigate();

    const consultar = async () => {
        try {
            const {data} = await axios.get(
                process.env.REACT_APP_SERVIDOR +`/api/v2/consultarIndicadores/Grafica`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Permission: '1',
                    },
                }
            )
            
            if(data.message){
                setDataIndicadores(data.resultado)
            }else{
                Swal.fire({
                    icon: 'warning',
                    title: "ADVERTENCIA",
                    html:`<strong>No tienes indicadores registrados</strong> <br/> Ingresa
                    <a target={'_new'} href="/FormularioIndicadores">aquí</a> si deseas inscribir tus indicadores.` ,
                    showConfirmButton: false,
                    showCloseButton: true,
                    focusConfirm: false,
                    confirmButtonText: "Aceptar",
                })
            }
          } catch (error) {
            console.log ('Error while fetching data from server: ' + error.message);
          }     
    }

    useEffect(() => {
        consultar();
    }, [])

    return (
        <>
            <Container className="text-center mt-3">
                <h1>INDICADORES</h1>
                <br/>
                <Row className="mb-2">
                    <Col xs={8}>
                        <Button style={{textAling: 'center',width: '50%',  marginLeft: '50%'}} variant="dark" onClick={() => Formulario()}>REGISTRAR</Button>
                    </Col>
                </Row> 
                <br/>
                <Row lg={2} md={1} xs={1} >
                            {
                                dataIndicadores.map ((item, i) => (
                                    <Col key={i} className="mb-8" style={{border: '1px solid'}}>
                                        <CardGraficas
                                        
                                            props={{
                                                id: item.id,
                                                nombre: item.nombre
                                            }}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    
            </Container>
        </>
       
    )
}



export default Indicador;