import React, {useEffect, useState} from 'react';
import {Container, Card, Row, Col, Modal, Button, Form, Badge} from 'react-bootstrap';
import {BsFillTrashFill, BsPencilSquare, BsTools } from 'react-icons/bs';
import {RiFileEditFill} from 'react-icons/ri';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import icono_carro from '../img/iconoCarro.png';
import icono_maquina from '../img/iconoMaquinaria.png';
import icono_moto from '../img/iconoMoto.png';
import icono_vehiculo from '../img/iconoVehiculo.png';

function CardVehiculos({props}) {
  const cookie = new Cookies ();
  const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');
  const now = moment().utc().subtract(5, 'hours');

  const {id, tipo, name, img, centro, data, queryData, id_centro} = props;
  const [img_car, setImgCar] = useState(img);
  const [showInfo, setShowInfo] = useState (false);
  const [document, setDocument] = useState('');
  const [showModalFile, setShowModalFile] = useState (false);  
  const [tipoVehiculo, setTipoVehiculo] = useState ([]);  
  const [tipoVehiculoRutina, setTipoVehiculoRutina] = useState ([]);  
  const [dataCentros, setDataCentros] = useState ([]);
  const [kilometraje, setKilometraje] = useState(null);
  
  const [showModalInsertRutina, setShowModalInsertRutina] = useState (false);  
  const [showModalInsert, setShowModalInsert] = useState (false);  
  const [imgVehiculo, setImgVehiculo] = useState (false);

  const [infoVehiculoRutina, setInfoVehiculoRutina]= useState ({
    id_vehiculo: id,
    kilometro: '',
    id_rutina:'',
  });


  const infoDeleteFile = {
    placa: name,
    id_centro
  }
  const [infoInsertFile, setInfoInsertFile] = useState({
    file: '',
    fecha: '',
  });  
  const [infoCar, setInfoCar] = useState ({
    id_vehiculo: id,
    placa: '',
    tipo_vehiculo: '',
    promedio_dia: '',
    id_centro: '',
    propietario: '',
  });
  const [infoCarVehiculoRutina, setInfoCarVehiculoRutina] = useState ({
    id_vehiculo: id,
  });

  const onChangeFilePdf = async e => {
    let file = e.target.files[0];
    setInfoInsertFile({
      ...infoInsertFile,
      file: file,
    })
  }

  const onChangeFecha = e => {
    setInfoInsertFile({
      ...infoInsertFile,
      fecha: e.target.value
    })
  }

  const queryDeleteFileFtp = async (document) => {
    try {
      const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/delete/files/ftp/document/cars', {...infoDeleteFile, document: document},
      {
        headers: {
          Permission: '1',
          Authorization: `Bearer ${access_token}`,
        }
      });
      if(data.message === 'success'){
        queryData()
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log('Error while deleting file from ftp: ' + error.message);
    }
  }

  const queryDeleteCar = async (id_car, id_centro) => {
    try {
      const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/delete/car',{
        id_car,
        id_centro
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permission: '1'
        }
      });

      if(data.message === 'success'){
        localStorage.clear();
        queryData();
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permiso para esta acción'
        })
      }
      console.log('Error while deleting car: ' + error.message);
    }
  }
  const queryTipoVehiculo = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/listar/vehiculos',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setTipoVehiculo (data.datos);
      
    } catch (error) {
      console.log (
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  const queryTipoVehiculoRutina = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/rutinas/compania',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setTipoVehiculoRutina (data.datos);
      
    } catch (error) {
      console.log (
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  const queryCentros = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/centro',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCentros (data);
      
    } catch (error) {
      console.log (
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  const handleCloseModalInsert = () => {
    setShowModalInsert (false);
  };
  
  const handleCloseModalInsertRutina = () => {
    setShowModalInsertRutina (false);
  };
  const onChangeInfoCar = e => {
    const {name, value} = e.target;
    setInfoCar ({
      ...infoCar,
      [name]: value,
    });
  };

  const onChangeInfoVehiculoRutina = e => {
    const {name, value} = e.target;
    setInfoCarVehiculoRutina ({
      ...infoCarVehiculoRutina,
      [name]: value,
    });
  };

  const info = (
    <Modal
      show={showInfo}
      onHide={() => setShowInfo (false)}
    >
      <Modal.Header closeButton >
        <Modal.Title style={{marginLeft: '0px'}}>
          {'Placa: ' + name + ' - Centro: '+ centro}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '2%'}}>        
          {
            tipo === 1 || tipo === 2 || tipo === 3
            ?
            <Container>
            <Row>            
              <Col xs={3} md={3}>
              Licencia de tránsito
              </Col>
              <Col xs={7} md={7}>
                {data.tarjeta_propiedad === '' || data.tarjeta_propiedad === null || undefined ? 'Sin documento' : moment(data.tarjeta_propiedad_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='text-truncate col-10' ><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.tarjeta_propiedad} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.tarjeta_propiedad}</a></Badge> : <Badge bg="success" className='text-truncate col-10'><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.tarjeta_propiedad} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.tarjeta_propiedad}</a></Badge> }
              </Col>
              <Col xs={1} md={1}>
                <label htmlFor="btn-file-pdf">
                  <RiFileEditFill aria-label="upload pdf" onClick={() => {
                    setShowModalFile(true);
                    setDocument('tarjeta_propiedad')}}/>
                </label>
              </Col>
              <Col xs={1} md={1}>
                <BsFillTrashFill onClick={() => queryDeleteFileFtp('tarjeta_propiedad')} />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={3} md={3}>
                Soat
              </Col>
              <Col xs={7} md={7}>
                {data.soat === '' || data.soat === null || undefined ? 'Sin documento' : moment(data.soat_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='text-truncate col-10' ><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.soat} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.soat}</a></Badge> : <Badge bg="success" className='text-truncate col-10'><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.soat} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.soat}</a></Badge> }
              </Col>
              <Col xs={1} md={1}>
                <label htmlFor="btn-file-pdf">
                  <RiFileEditFill aria-label="upload pdf" onClick={() => {
                    setShowModalFile(true);
                    setDocument('soat')}} />
                </label>
              </Col>
              <Col xs={1} md={1}>
                <BsFillTrashFill onClick={() => queryDeleteFileFtp('soat')}/>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={3} md={3}>
                Revisión
              </Col>
              <Col xs={7} md={7}>
                {data.revision === '' || data.revision === null || undefined ? 'Sin documento' : moment(data.revision_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='text-truncate col-10' ><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.revision} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.revision}</a></Badge> : <Badge bg="success" className='text-truncate col-10'><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.revision} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.revision}</a></Badge> }
              </Col>
              <Col xs={1} md={1}>
                <label htmlFor="btn-file-pdf">
                  <RiFileEditFill aria-label="upload pdf" onClick={() => {
                    setShowModalFile(true);
                    setDocument('revision')}}/>
                </label>
              </Col>
              <Col xs={1} md={1}>
                <BsFillTrashFill onClick={() => queryDeleteFileFtp('revision')}/>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={3} md={3}>
                Poliza
              </Col>
              <Col xs={7} md={7}>
                {data.poliza === '' || data.poliza === null || undefined ? 'Sin documento' : moment(data.poliza_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='text-truncate col-10' ><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.poliza} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.poliza}</a></Badge> : <Badge bg="success" className='text-truncate col-10'><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.poliza} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.poliza}</a></Badge> }
              </Col>
              <Col xs={1} md={1}>
                <label htmlFor="btn-file-pdf">
                  <RiFileEditFill aria-label="upload pdf" onClick={() => {
                    setShowModalFile(true);
                    setDocument('poliza')}}/>
                </label>  
              </Col>
              <Col xs={1} md={1}>
                <BsFillTrashFill onClick={() => queryDeleteFileFtp('poliza')}/>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={3} md={3}>
                Historico 1
              </Col>
              <Col xs={7} md={7}>
                {data.historico1 === '' || data.historico1 === null || undefined ? 'Sin documento' : moment(data.historico1_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='text-truncate col-10' ><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.historico1} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.historico1}</a></Badge> : <Badge bg="success" className='text-truncate col-10'><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.historico1} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.historico1}</a></Badge> }
              </Col>
              <Col xs={1} md={1}>
                <label htmlFor="btn-file-pdf">
                  <RiFileEditFill aria-label="upload pdf" onClick={() => {
                    setShowModalFile(true);
                    setDocument('historico1')}}/>
                </label>  
              </Col>
              <Col xs={1} md={1}>
                <BsFillTrashFill onClick={() => queryDeleteFileFtp('historico1')}/>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={3} md={3}>
                Historico 2
              </Col>
              <Col xs={7} md={7}>
                {data.historico2 === '' || data.historico2 === null || undefined ? 'Sin documento' : moment(data.historico2_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='text-truncate col-10' ><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.historico2} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.historico2}</a></Badge> : <Badge bg="success" className='text-truncate col-10'><a href={process.env.REACT_APP_HOST_FTP +'pdfsVehiculos/' +  data.historico2} style={{color: 'white'}} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.historico2}</a></Badge> }
              </Col>
              <Col xs={1} md={1}>
                <label htmlFor="btn-file-pdf">
                  <RiFileEditFill aria-label="upload pdf" onClick={() => {
                    setShowModalFile(true);
                    setDocument('historico2')}}/>
                </label>  
              </Col>
              <Col xs={1} md={1}>
                <BsFillTrashFill onClick={() => queryDeleteFileFtp('historico2')}/>
              </Col>
            </Row>
            </Container>
            :
            tipo === 4
            ?
            <Container>
            <Row>
              <Col xs={7} md={3}>
                Declaración de Importación
              </Col>
              <Col xs={2} md={7}>
                {
                  data.declaracion_importacion === '' || data.declaracion_importacion === null || undefined ?
                    'Sin documento' :
                      <Badge bg="success" className='text-truncate col-10' style={{cursor: 'pointer'}}>
                        <a href={process.env.REACT_APP_HOST_FTP + 'pdfsMontacargas/' + data.declaracion_importacion} style={{ color: 'white' }} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.declaracion_importacion}</a>
                      </Badge> 
                }
              </Col>
              <Col xs={1} md={1} >
                <label htmlFor="btn-file-pdf" style={{cursor: 'pointer'}}>
                  <RiFileEditFill aria-label="upload pdf" onClick={() => {
                    setShowModalFile(true);
                    setDocument('declaracion_importacion')
                  }} />
                </label>
              </Col>
              <Col xs={1} md={1} style={{cursor: 'pointer'}}>
                <BsFillTrashFill  onClick={() => queryDeleteFileFtp('declaracion_importacion')} />
              </Col>
            </Row>
            </Container>
            :
            <Container></Container>
          }
        
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowInfo (false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
  const queryUpdateVehiculos = async e => {
    e.preventDefault ();

    // Construye el formdata para enviar la imagen del vehiculo
    let formData = new FormData ();
    formData.append ('formData', imgVehiculo);

    try {
      if (infoCar.placa === '') {
        Swal.fire ({
          icon: 'error',
          title: 'La placa es obligatorio',
        });
      }else if (infoCar.tipo_vehiculo === '') {
        Swal.fire ({
          icon: 'error',
          title: 'El tipo de vehículo es obligatorio',
        });
      } else if (infoCar.promedio_dia === '') {
        Swal.fire ({
          icon: 'error',
          title: 'Promedio día es obligatorio',
        });
      } else if (
        infoCar.id_centro === 'no existe' ||
        infoCar.id_centro === ''
      ) {
        Swal.fire ({
          icon: 'error',
          title: 'Centro es obligatorio',
        });
      } else if (infoCar.propietario === '') {
        Swal.fire ({
          icon: 'error',
          title: 'Propietario es obligatorio',
        });
      } else {
        const {data} = await axios.post (
          process.env.REACT_APP_SERVIDOR +
            `/api/v2/update/vehiculos/mantenimiento`,
            infoCar,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
              },
            }
          );
        if (imgVehiculo) {
          await axios.post (
            process.env.REACT_APP_SERVIDOR +
              `/api/v2/insert/img/vehiculos/mantenimiento/${infoCar.placa}/${infoCar.id_centro}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            }
          );         
          const {data} = await axios.get (
            process.env.REACT_APP_SERVIDOR + '/api/v2/list/vehiculos/mantenimiento',
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
          setImgCar(data.img_carro)
          console.log(data)
        }
        if (data.message === 'success') {
          setShowModalInsert (false);
          setInfoCar({
            id_vehiculo: id,
            placa: '',
            tipo_vehiculo: '',
            promedio_dia: '',
            id_centro: '',
            propietario: '',
          });
          setImgVehiculo (false);
          await queryData ();
        }else{
          Swal.fire ({
            icon: 'info',
            titulo: "Mensaje",
            title: data.message,
          });
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log ('Error while inserting data car server: ' + error.message);
    }
  };

  
  const queryUpdateVehiculosRutinas = async e => {
    e.preventDefault ();


    try {
          console.log("INFOCAR",infoCarVehiculoRutina);
        const {data} = await axios.post (
          process.env.REACT_APP_SERVIDOR +
            `/api/v2/update/vehiculos/rutina`,
            infoCarVehiculoRutina,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
              },
            }
          );
        if (data.message === 'success') {
          setShowModalInsertRutina (false);
          setInfoVehiculoRutina({
            id_vehiculo: id,
            kilometraje: '',
            id_rutina: '',
          });
          await queryData ();
        }else{
          Swal.fire ({
            icon: 'info',
            titulo: "Mensaje",
            title: data.message,
          });
        }
      
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log ('Error while inserting data car server: ' + error.message);
    }
  };

  const onChangeImg = e => {
    setImgVehiculo (e.target.files[0]);
  };
  const queryUpdateVehiculo =async (id)=> {
    
    const {data} = await axios.post(process.env.REACT_APP_SERVIDOR +'/api/v2/list/vehiculos', {id_vehiculo: id});
  
    if(data.length > 0){
      const { id_centro_fk, tipo_vehiculo_fk, placa, promedio_dia, propietario} = data[0];
      console.log("DATOS VEHICULO",infoVehiculoRutina);
      console.log(tipoVehiculoRutina);
      setInfoCar({
        id_vehiculo: id,
        placa: placa,
        tipo_vehiculo: tipo_vehiculo_fk,
        promedio_dia: promedio_dia,
        id_centro: id_centro_fk,
        propietario: propietario
      });
    }
    setShowModalInsert(true);
    
  };
  const queryUpdateVehiculoRutina = async (id) => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/listar/rutina/vehiculos', { id_vehiculo: id });
      
      if (data?.datos?.length > 0) {
        const vehiculoRutina = data.datos.map(item => ({
          id_vehiculo: id,
          kilometraje: item.kilometraje,
          id_rutina: item.id_rutina
          
        }));
        
        console.log("DATOS VEHICULO", vehiculoRutina);
      console.log(tipoVehiculoRutina);
      setInfoVehiculoRutina(vehiculoRutina);
      console.log("DATO",vehiculoRutina[0]);
      } else {
        const vehiculoRutina = data.datos.map(item => ({
          id_vehiculo: id,
          kilometraje: "",
          id_rutina: ""
          
        }));
        
        console.log("DATOS VEHICULO", vehiculoRutina);
        setInfoVehiculoRutina(vehiculoRutina);
      console.log("DATO",vehiculoRutina[0]);
      }
      
      console.log("IMPRIME INFO VEHICULO RUTINA", id);
     // console.log(data);
      setShowModalInsertRutina(true);
    } catch (error) {
      console.log('Error fetching vehicle routines:', error.message);
    }
  };
  const modalInsert = (
    <Modal
      show={showModalInsert}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title><h1>Información Vehículo</h1></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '2%'}}>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Placa</Form.Label>
            <Form.Control
              value={infoCar.placa}
              name="placa"
              type="text"
              placeholder="Placa"
              onChange={onChangeInfoCar}
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Tipo de Vehículo</Form.Label>
              <Form.Select placeholder="tipo_vehiculo" name="tipo_vehiculo" value={infoCar.tipo_vehiculo} onChange={onChangeInfoCar}>                
              <option value="no existe">Elige el tipo</option>
                
                {tipoVehiculo.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.nombre}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Promedio de kilometros/horas por día</Form.Label>
            <Form.Control
              value={infoCar.promedio_dia}
              name="promedio_dia"
              type="text"
              placeholder="Promedio de día"
              onChange={onChangeInfoCar}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Centro</Form.Label>
            <Form.Select
              value={infoCar.id_centro}
              name="id_centro"
              placeholder="Centro"
              onChange={onChangeInfoCar}
            >
              <option value="no existe">Elige centro</option>
              {dataCentros.map ((item, i) => (
                <option key={i} value={item.id_centro}>
                  {item.centro_descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Propietario</Form.Label>
            <Form.Select name="propietario" value={infoCar.propietario} onChange={onChangeInfoCar}>
              <option value="">Elige un propietario</option>
              <option value="PROPIO">PROPIO</option>
              <option value="COLABORADOR">COLABORADOR</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              name="img_carro"
              type="file"
              placeholder="Imagen"
              onChange={onChangeImg}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={e => queryUpdateVehiculos (e)}>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const obtenerKilometraje = (ide) => {
   
    try{
    let vehiculo = [];
     vehiculo = infoVehiculoRutina.find(items => items.id_rutina == ide);
     
    return vehiculo ? vehiculo.kilometraje : '0';
   // return "HOLA";
    }catch (error) {
    //  console.error('Error en obtenerKilometraje:', error);
      return '';
    }}
  
  const modalInsertRutina = (
    <Modal
      show={showModalInsertRutina}
      onHide={handleCloseModalInsertRutina}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title><h1>Información Rutina Vehículo</h1></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '2%'}}>
        <Form>
            {tipoVehiculoRutina.map ((item, i) => (
          <Form.Group  key={i} className="mb-3" controlId={`rutina-${i}`}>
          <Form.Label> Rutina {item.rutina}</Form.Label>
          <Form.Control
            name={item.rutina}
            type="text"
            placeholder={obtenerKilometraje(item.rutina)}
            onChange={(e) => onChangeInfoVehiculoRutina(e, item.rutina)}
            autoFocus={i === 0}
          />
          </Form.Group>
))}
          
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsertRutina}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={e => queryUpdateVehiculosRutinas (e)}>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const queryInserFileCar = async () => {
    let formData = new FormData();
    formData.append("filePdfVehiculos", infoInsertFile.file);
    try {
      if(infoInsertFile.file === '' || infoInsertFile.file === null){
        Swal.fire({
          icon: 'error',
          title: 'Debes elegir un archivo'
        });
      }else if(infoInsertFile.fecha === ''){
        Swal.fire({
          icon: 'error',
          title: 'Debes elegir una fecha de vencimiento'
        })
      }else{
        const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + `/api/v2/files/documentation/cars/${name}/${document}/${id_centro}/${infoInsertFile.fecha}`,
        formData,
        {
          headers: {
            Permission: '1',
            Authorization: `Bearer ${access_token}`,
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          }
        });
        if(data.message === 'success'){
          setShowModalFile(false);
          setInfoInsertFile({
            fecha: '',
            file: ''
          })
          queryData();
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
    }
  }

  const modalFile = (
    <Modal show={showModalFile} onHide={() => setShowModalFile(false)}>
    <Modal.Body style={{margin: '2%'}}>
      <Form.Group className="mb-3">
        <Form.Label>File</Form.Label>
        <Form.Control type="file" accept='application/pdf' onChange={onChangeFilePdf} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Fecha vencimiento</Form.Label>
        <Form.Control type="date" name="fecha_vencimiento" onChange={onChangeFecha}/>
      </Form.Group>
      <Button onClick={() => queryInserFileCar()}>Guardar</Button>
    </Modal.Body>
  </Modal>
  )

  useEffect(() => {
    const updateImg = () => {
      setImgCar(img);
    }
    updateImg();
    queryTipoVehiculo();
    queryTipoVehiculoRutina();
    queryCentros();
  }, [img])
  
  return (
   <>
    <Card
      className="mx-auto"
      style={{width: '20rem', height: '100%'}}
      
    >
      <Card.Img onClick={() => setShowInfo (true)} style={{padding: '8%', height: '200px'}} variant="top" 
      src={img ? process.env.REACT_APP_HOST_FTP + 'imagenesVehiculos/' + img_car : tipo === 1 ?  icono_moto : tipo === 2 ? icono_carro : tipo === 3 ? icono_vehiculo : icono_maquina} />
      <Card.Body className="mb-2" style={{paddingBlock: '0px'}}>
        <Row className="mb-3">
          <Col className="text-start" xs="12">
            <Card.Title style={{textAlign: 'center', margin: '-15px 0px 20px 0px'}}>{name}</Card.Title>
          </Col>
        </Row>
        <Row >
        <Col className="text-center" xs="6">
            <Badge bg="secondary">{data.propietario}</Badge>
          </Col>
          <Col className='text-end' xs="2">
            <Button onClick={() => queryUpdateVehiculoRutina(data.id_vehiculo)} variant="primary" style={{width: '30px', height: '30px', padding: '0px'}}><BsTools /></Button>
          </Col>
          <Col className='text-end' xs="2">
            <Button onClick={() => queryUpdateVehiculo(data.id_vehiculo)} variant="primary" style={{width: '30px', height: '30px', padding: '0px'}}><BsPencilSquare /></Button>
          </Col>
          <Col className="text-end" xs="2">
          <Button onClick={() => queryDeleteCar(data.id_vehiculo, id_centro)} variant="danger" style={{width: '30px', height: '30px', padding: '0px'}}><BsFillTrashFill /></Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    {info}
    {modalFile}
    {modalInsert}
    {modalInsertRutina}
    </>
  );
}

export default CardVehiculos;
