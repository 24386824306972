import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Swal from "sweetalert2";

function InformeKilometraje() {
    const cookie = new Cookies;
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    const tipo_vehiculo = cookie.get('tipo_vehiculo');

    const [htmlString, setHtmlString] = useState('');
    
    const consultaKilometraje = async () =>{
        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/mantenimiento/informe_kilometraje', { tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        setHtmlString("")    
        if (data !== '') {
            setHtmlString(data)
        }else{
            Swal.fire({
                icon: 'warning',
                title: 'Mensaje',
                text: 'No existen kilometrajes registrados',
                showConfirmButton: false,
                footer: '<a href="/gestion-mantenimiento/consultar-kilometraje">REGISTRAR</a>'
            })
        }
    }
   console.log('data');
   console.log(htmlString);
    useEffect(() => {
        consultaKilometraje();
    }, [])
    return (  
        <>
            <Container className="mt-5 text-center">
                <Row className="mb-5">
                    <Col>
                        <h2>INFORME MENSUAL DE KILOMETRAJE/GALONES</h2>
                    </Col>
                    <br/><br/>
                    <Col xs="20">
                        <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
                    </Col>
                </Row>
            </Container>
            
        </>
    );

}
export default InformeKilometraje;