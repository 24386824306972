import React, { useEffect, useState } from 'react';
import { Container, Card, Modal, Row, Col, Badge, Form, Button } from 'react-bootstrap';
import { BsFillTrashFill } from 'react-icons/bs';
import { RiFileEditFill } from 'react-icons/ri';
import montacarga from '../img/montacarga_img.png';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'universal-cookie';

function CardVehiculos({ props }) {
  const cookie = new Cookies(); const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');

  const { name, img, data, queryData, id_centro } = props;
  const [img_forklift, setImgForklift] = useState(img);

  const [showModalFile, setShowModalFile] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [document, setDocument] = useState('');
  const [infoInsertFile, setInfoInsertFile] = useState({
    file: ''
  });
  const infoDeleteFile = {
    placa: name,
    id_centro
  }

  const onChangeFilePdf = async e => {
    let file = e.target.files[0];
    setInfoInsertFile({
      ...infoInsertFile,
      file: file,
    })
  }
  
  const queryInserFileMontacarga = async () => {
    let formData = new FormData();
    formData.append("filePdfMontacarga", infoInsertFile.file);
    try {
      if(infoInsertFile.file === '' || infoInsertFile.file === null){
        Swal.fire({
          icon: 'error',
          title: 'Debes elegir un archivo'
        });
      }else{
        const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + `/api/v2/files/documentation/montacarga/${name}/${document}/${id_centro}`,/* ${name}/${document}/${id_centro}/${infoInsertFile.fecha}`, */
        formData,
        {
          headers: {
            Permission: '1',
            Authorization: `Bearer ${access_token}`,
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          }
        });
        if(data.message === 'success'){
          setShowModalFile(false);
          setInfoInsertFile({
            file: ''
          })
          queryData();
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
    }
  }

  const queryDeleteFileFtp = async (document) => {
    try {
      const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/delete/files/ftp/document/motacarga', {...infoDeleteFile, document: document},
      {
        headers: {
          Permission: '1',
          Authorization: `Bearer ${access_token}`,
        }
      });
      if(data.message === 'success'){
        queryData()
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log('Error while deleting file from ftp: ' + error.message);
    }
  }

  const info = (
    <Modal
      show={showInfo}
      onHide={() => setShowInfo(false)}
    >
      <Modal.Header closeButton >
        <Modal.Title style={{ marginLeft: '0px' }}>
          {'Montacarga: ' + name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ margin: '2%' }}>
        <Container>
          <Row>
            <Col xs={7} md={3}>
              Declaración de Importación
            </Col>
            <Col xs={2} md={7}>
              {
                data.declaracion_importacion === '' || data.declaracion_importacion === null || undefined ?
                  'Sin documento' :
                    <Badge bg="success" className='text-truncate col-10' style={{cursor: 'pointer'}}>
                      <a href={process.env.REACT_APP_HOST_FTP + 'pdfsMontacargas/' + data.declaracion_importacion} style={{ color: 'white' }} target="_blank" rel="noreferrer noopener" className="text-decoration-none">{data.declaracion_importacion}</a>
                    </Badge> 
              }
            </Col>
            <Col xs={1} md={1} >
              <label htmlFor="btn-file-pdf" style={{cursor: 'pointer'}}>
                <RiFileEditFill aria-label="upload pdf" onClick={() => {
                  setShowModalFile(true);
                  setDocument('declaracion_importacion')
                }} />
              </label>
            </Col>
            <Col xs={1} md={1} style={{cursor: 'pointer'}}>
              <BsFillTrashFill  onClick={() => queryDeleteFileFtp('declaracion_importacion')} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowInfo (false)}>Close</Button>
      </Modal.Footer>              
    </Modal>
  )
  
  const modalFile = (
    <Modal show={showModalFile} onHide={() => setShowModalFile(false)}>
    <Modal.Body style={{margin: '2%'}}>
      <Form.Group className="mb-3">
        <Form.Label>File</Form.Label>
        <Form.Control type="file" accept='application/pdf' onChange={onChangeFilePdf} />
      </Form.Group>
      <Button onClick={() => queryInserFileMontacarga()}>Guardar</Button>
    </Modal.Body>
  </Modal>
  )

  useEffect(() => {
    const updateImg = () => {
      setImgForklift(img);
    }
    updateImg();
  }, [img])

  return (
    <>
      <Card
        className="mx-auto"
        style={{ width: '18rem', height: '100%' }}
      >
        <Card.Img onClick={() => setShowInfo(true)} style={{ padding: '8%', height: '220px' }} variant="top" src={img ? process.env.REACT_APP_HOST_FTP + 'imagenesMontacargas/' + img_forklift : montacarga} />
        <Card.Body className="text-center">
          <Card.Title>{name}</Card.Title>
        </Card.Body>
      </Card>
      {info}
      {modalFile}
    </>
  );
}

export default CardVehiculos;
