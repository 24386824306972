import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


function FomularioDescripcionCargo() {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep')

    const [usuarioLogeado, setUsuarioLogeado] = useState(localStorage.getItem('ajfdkjakfljasdkljflkasjdflkasjdlfkaslkjffklasjdflkajslkfjalkdfjaksljdfklajdlfkjjfalk'));
    const [ocultar, setOcultar] = useState('block');


    const [idUsuario, setIdUsuario] = useState('');
    const [nombre, setNombre] = useState('');
    const [usuario, setUsuario] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [perfiles, setPerfiles] = useState('');
    const [estado, setEstado] = useState('');

    const [registrar, setRegistrar] = useState('none');
    const [modificar, setModificar] = useState('none');

    const [codEvaluador, setCodEvaluador] = useState('none');

    const [validatedFormulario1, setValidated1] = useState(false);

    let icono = "";
    let titulo = "";
    let mensaje = "";

    let locacion = useLocation();

    const handleSubmit1 = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated1(true);
        event.preventDefault();
    };
    function Registrar() {

        if (nombre !== '' && usuario !== '' && contrasena !== '' && perfiles !== '' && estado !== '') {
            let datos = { nombre, usuario, contrasena, perfiles, estado };

            let jsonDatos = JSON.stringify(datos);
            //Consulto si exite el mismo nombre
            fetch(process.env.REACT_APP_SERVIDOR +"/consulta_usuario/" + usuario + "/0").then((result) => {
                result.json().then((resp) => {
                    console.table(resp)

                    if (Object.keys(resp).length === 0) {
                        fetch(process.env.REACT_APP_SERVIDOR +"/insertar_usuario", {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${access_token}`,
                                Permission: '1'
                            },
                            body: jsonDatos
                        }).then((resp) => {
                            console.log("resp", resp);
                        }).catch((err) => {
                            if(err.response.status === 403){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'No tienes permiso para esta acción'
                                })
                            }
                        })

                        icono = "success";
                        titulo = "Mensaje";
                        mensaje = "Registro Exito";
                        window.location =window.location; 
                        
                    } else {
                        icono = "error";
                        titulo = "Mensaje";
                        mensaje = "Este documento tiene datos relacionado con otros.\n Por favor validar el nombre";
                    }
                });
            });

        } else {
            icono = "error";
            titulo = "Mensaje";
            mensaje = "Por favor llenar todos los campos";
        }

        if(mensaje !== ''){

            mostrarAlert(icono, titulo, mensaje);
        }
    }
    function ActualizarCargo() {
        if (nombre !== '') {
            if (usuario !== '') {
                if (contrasena !== '') {
                    if(perfiles !== ''){
                        if (estado !== '') {
                            let datoUsuario = { 
                                idUsuario, nombre, usuario, contrasena, perfiles, estado
                            }
                            let jsonDatos = JSON.stringify(datoUsuario);

                            fetch(process.env.REACT_APP_SERVIDOR +"/actualizar_usuario", {
                                method: "PUT",
                                //mode: 'no-cors',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${access_token}`,
                                    Permission: '1'
                                },
                                body: jsonDatos
                            }).then((resp) => {
                                console.log("resp", resp);
                            }).catch((err) => {
                                if(err.response.status === 403){
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'No tienes permiso para esta acción'
                                    })
                                }
                            })
                            icono = "success";
                            titulo = "Mensaje";
                            mensaje = "Actualizacion Exitosa";

                        } else {
                            icono = "error";
                            titulo = "Mensaje";
                            mensaje = "Ingre el esatdo del usuario";

                        }
                    }else{
                        icono = "error";
                        titulo = "Mensaje";
                        mensaje = "Llena el pefil del usuario";
                    }
                } else {
                    icono = "error";
                    titulo = "Mensaje";
                    mensaje = "Llena la contraseña del usuario";
                }
            } else {

                icono = "error";
                titulo = "Mensaje";
                mensaje = "Llena el usuario del usuario";
            }

        } else {

            icono = "error";
            titulo = "Mensaje";
            mensaje = "Llena el nombre del usuario";
        }

        mostrarAlert(icono, titulo, mensaje);
    }

    function ConsultarUsuario() {

        fetch(process.env.REACT_APP_SERVIDOR +"/consulta_usuario_perfiles/"+usuarioLogeado).then((result) => {
                    result.json().then((resp) => {
                        console.log("PERFIL")
                        console.table(resp[0].perfiles)
                        if(resp[0].perfiles === 'Administrador' ){
                            setOcultar('block');
                        }else{
                            window.location.href = "/Menu";
                        }
                    });
                });
        let Modificar = locacion.pathname.includes("Modificar");//Valida la URL
        if (Modificar) {

            setModificar('block'); // Habiliar boton de modificar

            let idUsuario = locacion.search.split('=');

            fetch(process.env.REACT_APP_SERVIDOR +"/consulta_usuario_id/" + idUsuario[1]).then((result) => {
                result.json().then((resp) => {
                    console.table(resp);
                    setIdUsuario(resp[0].id);
                    setNombre(resp[0].nombre);
                    setUsuario(resp[0].usuario);
                    setContrasena(resp[0].contrasena);
                    setPerfiles(resp[0].perfiles);
                    setEstado(resp[0].estado);

                });
            });
        } else {
            setRegistrar("block");
        }
    }

    function mostrarAlert(icono, titulo, mensaje) {
        Swal.fire({
            icon: icono,
            title: mensaje
        })
    }
    function GetUsuario() {
        const cookie = new Cookies();
       const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep')
        if(access_token === null){
          window.location.href = "/Menu";
        }
    }
    useEffect(() => {
        GetUsuario();
    }, []);
    
    
    useEffect(() => {
        ConsultarUsuario();
    }, []);


    return (

        <>
            <Container style={{display:ocultar}}>
                <h1>FORMULARIO USUARIO</h1>
                <Form noValidate validated={validatedFormulario1} onSubmit={handleSubmit1} >
                    <Row className="justify-content-md-center">

                        <Col xs={4}>
                            <Form.Group >
                                <Form.Label>Nombre del usuario</Form.Label>
                                <Form.Control size="sm" type="text" value={nombre} placeholder="Ingrese el nombre del usuario" required onChange={(e) => { setNombre(e.target.value) }} />
                            </Form.Group>

                        </Col>

                        <Col xs={4}>
                            <Form.Group >
                                <Form.Label>Usuario</Form.Label>
                                <Form.Control size="sm" type="text" value={usuario} placeholder="Ingrese el correo del usuario" onChange={(e) => { setUsuario(e.target.value) }} />
                            </Form.Group>

                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-md-center">

                        <Col xs={4}>
                            <Form.Group >
                                <Form.Label>Contraseña del usuario</Form.Label>
                                <Form.Control size="sm" type="text" value={contrasena} placeholder="Ingrese la contraseña del usuario" onChange={(e) => { setContrasena(e.target.value) }} />
                            </Form.Group>

                        </Col>
                        <Col xs={4}>
                            <Form.Group >
                                <Form.Label>Pefiles</Form.Label>
                                <Form.Select value={perfiles} onChange={(e) => { setPerfiles((e.target.value)) }}>

                                    <option></option>
                                    <option value='Administrador'>Administrador</option>
                                    <option value='Usuario'>Usuario</option>
                                    <option value='Responsable Gestion Institucional'>Responsable Gestion Institucional</option>
                                    <option value='Comportamiento Humano'>Comportamiento Humano</option>
                                    <option value='Vehiculos Seguros'>Vehículos Seguros</option>
                                    <option value='Infraestructura Segura'>Infraestructura Segura</option>
                                    <option value='Atecion Victimas'>Ateción Victimas</option>

                                </Form.Select>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                    <Col xs={4}>
                        <Form.Group >
                            <Form.Label>Estado</Form.Label>
                            <Form.Select value={estado} onChange={(e) => { setEstado((e.target.value)) }}>

                                <option></option>
                                <option value='Activo'>Activo</option>
                                <option value='Inactivo'>Inactivo</option>

                            </Form.Select>
                        </Form.Group>

                    </Col>
                    <Col xs={4}></Col>
                    </Row>
                    <br />
                    <Row className="justify-content-md-center">
                        <Col xs={2}>

                            <Button variant="primary" style={{ display: registrar }} onClick={() => Registrar()} >Registrar</Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs={2}>

                            <Button variant="primary" style={{ display: modificar }} onClick={() => ActualizarCargo()} >Actualizar</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>

        </>
    );

}

export default FomularioDescripcionCargo;