import { Container, Navbar, Nav, NavDropdown, Row, Col, Badge, Modal, Form, Button, Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { RiFileEditFill } from 'react-icons/ri';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import logo from './img/logodatralabs.png';
//import {Chart} from 'react-google-charts';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CardMantenimeinto from './components/CardMantenimiento';



function Navegador() {
  const cookie = new Cookies();
  const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');

  
  const nombre_user = cookie.get('nombre_user');
  const nombre_empresa = cookie.get('nombre_empresa');
  const logo_empresa = cookie.get('logo_empresa');
  
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [documents, setDocuments] = useState([]);
  const [documentsInfra, setDocumentsInfra] = useState([]);
  const [tipoVehiculo, setTipoVehiculo] = useState([]);
  const [perfilAdmin, setPerfilAdmin] = useState(false);
  const [infoDocument, setInfoDocument] = useState({
    document: '',
    id_document: ''
  });
  const [documentInfra, setDocumentInfra] = useState({
    document: '',
    id_document: '',
    id_archivo: ''
  });

  const [documentsSafeCars, setDocumentsSafeCars] = useState({
    id_document: 0,
    respo_comp_seguro: null,
    plan_prep_respu_emergencia_vial: null,
    vias_seguras: null,
    planificacion_desplazamiento: null,
    gestion_cambio: null,
    archivo_retencion: null,
    id_compania_fk: '',
  });

  const [infoDocumentSafeCar, setInfoDocumentSafeCar] = useState('');
  const [showMantenimiento, setShowMantenimiento] = useState(false);
  const [tipoNav, setTipoNav] = useState(true);

  const queryDocumentsInfra = async () => {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    try {
      const { data } = await axios.get(process.env.REACT_APP_SERVIDOR + '/api/v2/list/archivos/infraesctructura', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });

      setDocumentsInfra(data);
    } catch (error) {
      console.log('Error while  loading documents infraestructura: ' + error.message);
    }
  };
  const queryDocuments = async () => {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + '/api/v2/lits/documents',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDocuments(data);
    } catch (error) {
      console.log('Error while loading documents : ' + error.message);
    }
  };
  const queryDocumentsCarsSafe = async () => {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    try {
      const { data } = await axios.get(process.env.REACT_APP_SERVIDOR + '/api/v2/list/documents/vehiculos/seguros', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });

      setDocumentsSafeCars(data[0]);
    } catch (error) {
      console.log('Error while loading documents cars safe: ' + error.message);
    }
  };
  const queryDeleteDocumentsSafeCars = async (document) => {
    try {
      const { data } = await axios.delete(process.env.REACT_APP_SERVIDOR + `/api/v2/delete/documents/cars/safe/${document}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permission: '1'
        }
      })

      if (data.message === 'success') {
        await queryDocumentsCarsSafe();
        Swal.fire({
          icon: 'success',
          title: 'Eliminación exitoso'
        })
      } else if (data.message === 'no exist') {
        Swal.fire({
          icon: 'error',
          title: 'El archivo no existe',
        });
      }
    } catch (error) {
      console.log('Error while deleting documents cars safe: ' + error.message);
    }
  };
  const queryInsertDocumentSafeCars = async (file) => {
    try {
      let formData = new FormData();
      formData.append('pdfVehiculsSeguros', file);
      const { data } = await axios.post(process.env.REACT_APP_SERVIDOR + `/api/v2/insert/document/vehiculos/seguros/${infoDocumentSafeCar}`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permision: '1'
        }
      });

      if (data.message === 'success') {
        await queryDeleteDocumentsSafeCars();
        Swal.fire({
          icon: 'success',
          title: 'Registro exitoso'
        })
        window.location = window.location;
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'Error',
          title: 'No tienes permisos para esta acción',
        });
      }
      console.log('Error while inserting documents car safe: ' + error.message)
    }
  };
  const queryPerfilAdmin = async () => {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + '/api/v2/get/perfil/user',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      setPerfilAdmin(data.perfil);
    } catch (error) {
      console.log('Error while loading perfil admin: ' + error.message);
    }
  };
  const queryDeleteDocument = async document => {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
        '/api/v2/delete/document/institucional/ftp',
        { document },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: '1'
          },
        }
      );

      if (data.message === 'success') {
        await queryDocuments();
        Swal.fire({
          icon: 'success',
          title: 'Eliminación exitoso'
        })
      } else if (data.message === 'no exist') {
        Swal.fire({
          icon: 'error',
          title: 'El archivo no existe',
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permisos para esta acción'
        })
      }
      console.log('Error while deleting document: ' + error.message);
    }
  };
  const queryInsertDocument = async (file) => {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    try {
      let formData = new FormData();
      formData.append('pdf_document_institucional', file);
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
        `/api/v2/insert/documentos/compania/${infoDocument.document}/${infoDocument.id_document}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: '1',
          },
        }
      );

      if (data.message === 'success') {
        queryDocuments();
        setInfoDocument({
          document: '',
          id_document: ''
        })
        Swal.fire({
          icon: 'success',
          title: 'Registro exitoso'
        })
        window.location = window.location;
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'Error',
          title: 'No tienes permisos para esta acción',
        });
      }
      console.log('Error while inserting document: ' + error.message);
    }
  };
  const queryInsertDocumentInfra = async (file) => {
    try {
      let formData = new FormData();
      formData.append('pdf_document_infraestructura', file);
      const { data } = await axios.post(process.env.REACT_APP_SERVIDOR + `/api/v2/insert/archivos/infraesctructura/${documentInfra.document}/${documentInfra.id_document}/${documentInfra.id_archivo}`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permission: '1',
        }
      });

      if (data.message === 'success') {
        queryDocumentsInfra();
        setDocumentInfra({
          document: '',
          id_document: '',
          id_archivo: ''
        })
        Swal.fire({
          icon: 'success',
          title: 'Registro exitoso'
        })
        window.location = window.location;
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permisos para esta acción'
        })
      }
      console.log('Error inserting document: ' + error.message);
    }
  };
  const queryDeleteDocumentInfra = async (document) => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/delete/archivos/infraesctructura', { id_archivo: document }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permission: '1',
        }
      });

      if (data.message === 'success') {
        queryDocumentsInfra();
        Swal.fire({
          icon: 'success',
          title: 'Eliminación exitosa'
        });
      } else if (data.message === 'no exist') {
        Swal.fire({
          icon: 'error',
          title: 'El archivo no existe'
        })
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permisos para esta acción'
        })
      }
      console.log('Error deleting document infraesctructura: ' + error.message);
    }
  };
  const queryTipoVehiculo = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + '/api/v2/listar/vehiculos_compania',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (data.estado) {
        setTipoVehiculo(data.datos);
      }

    } catch (error) {
      console.log(
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  const onChangeFile = (e) => {
    let file = e.target.files[0];
    queryInsertDocument(file);
  };

  const onChangeFileInfra = e => {
    let file = e.target.files[0];
    queryInsertDocumentInfra(file);

  }

  const onChangeFileSafeCars = e => {
    let file = e.target.files[0];
    queryInsertDocumentSafeCars(file);

  }
  const onChangeTipoVehiculo = e => {
    queryTipoVehiculo()
    setShowMantenimiento(true)

  }
  const handleCloseModal = e => {
    setShowMantenimiento(false);
  }
  const handleVehiculo = (id_vehiculo) => { 
    cookie.set('tipo_vehiculo', id_vehiculo, { path: '/' })
    window.location.href = '../gestion-mantenimiento-vehiculos';
  }
 
  function cerrarSesion() {
    cookie.remove('perv_v2_cookies_user_kdfoeinapjdkfawep');
    cookie.remove('compania_user');
    window.location.href = '/';
  }

  const OnchangeMenu = ()=>{
    window.location.search = '';
    cookie.remove('tipo_vehiculo');
    window.location.href = '/Menu';
  }

  useEffect(() => {
    queryTipoVehiculo();
    queryDocuments();
    queryPerfilAdmin();
    queryDocumentsInfra();
    queryDocumentsCarsSafe();
    if (window.location.pathname.includes('/gestion-mantenimiento')) {
      setTipoNav(false)
    } else {
      setTipoNav(true)
    }
  }, []);

  return ( 

    <>
      {
        tipoNav
          ?
          <Navbar bg="dark" variant="dark" expand="xl" fixed='top' className="mb-5">
            <Container>
              <Navbar.Brand href="../Menu" className="flex-fill"><img src={logo} alt="logo" style={{ maxHeight: "50%", maxWidth: "25%" }}  /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="flex-fill">
                  <NavDropdown
                    title="Planear"
                    id="basic-nav-dropdown"
                    direction="up"
                    variant="dark"
                    className="flex-fill"
                  >
                    {documents.map((item, i) => (
                      <Row key={i}>
                        <Col xs="9">{/* md="7" */}
                          {
                            i === 5 ?
                              <NavDropdown.Item
                                className='textoNav'
                                variant="dark"
                                href="/caracterizacion-evaluacion-control-de-riesgo">
                                <div className='col-12'>
                                  {item.desc_documento + ' '}
                                </div>
                              </NavDropdown.Item>
                              :
                              <NavDropdown.Item
                                className='textoNav'
                                variant="dark"
                                target="_blank"
                                href={
                                  item.name_archivo === null || item.name_archivo === ''
                                    ? '#'
                                    : process.env.REACT_APP_HOST_FTP +
                                    'pdfsInstitucional/' +
                                    item.name_archivo
                                }
                              >
                                <div className=' col-12 '>
                                  {item.desc_documento + ' '}
                                </div>
                                {
                                  !item.name_archivo ? <Badge bg="danger">No existe</Badge> : null
                                }
                              </NavDropdown.Item>
                          }
                        </Col>
                        {perfilAdmin
                          ?
                          i === 5 ? null :
                            <>
                              <Col xs="1" md="1" className="text-end">
                                <label htmlFor={'btn-file-pdf-institucional'}>
                                  <RiFileEditFill onClick={() => {
                                    setInfoDocument({
                                      document: item.desc_documento,
                                      id_document: item.id_documentos
                                    })
                                  }} />
                                </label>
                              </Col>
                              <Col xs="1" md="1" className="text-end">
                                <BsFillTrashFill
                                  onClick={() =>
                                    queryDeleteDocument(item.name_archivo)}
                                />
                              </Col>
                              <input
                                accept="application/pdf"
                                id={'btn-file-pdf-institucional'}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={onChangeFile} />
                            </>
                          : null}
                      </Row>
                    ))}

                  </NavDropdown>
                  <input type="file" name="document_safe_cars" id="btn-file-safe-cars" style={{ display: 'none' }} accept="application/pdf" onChange={onChangeFileSafeCars} />

                  <NavDropdown title="Hacer" id="basic-nav-dropdown" className="flex-fill">
                    <NavDropdown.Item href="/gestion-tareas/calendario" style={{ width: '500px' }}>
                      P9. Plan Anual de Trabajo
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/gestion-tareas/calendario">
                      P10. Plan Anual de Capacitación
                    </NavDropdown.Item>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.respo_comp_seguro ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.respo_comp_seguro : '#'}>
                          <div className=' col-12'>
                            P11. Responsabilidad y Comportamiento Seguro
                          </div>
                          {
                            !documentsSafeCars.respo_comp_seguro ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {
                        perfilAdmin ?
                          <>
                            <Col xs="1" md="1" className="text-end">
                              <label htmlFor='btn-file-safe-cars'>
                                <RiFileEditFill onClick={() => {
                                  setInfoDocumentSafeCar('respo_comp_seguro')
                                }} />
                              </label>
                            </Col>
                            <Col xs="1" md="1" className="text-end">
                              <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('respo_comp_seguro')} />
                            </Col>
                          </>
                          : null
                      }
                    </Row>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.plan_prep_respu_emergencia_vial ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.plan_prep_respu_emergencia_vial : '#'} >
                          <div className='col-12'>
                            P12. Plan de Preparación y Respuesta Ante Emergencias Viales
                          </div>
                          {
                            !documentsSafeCars.plan_prep_respu_emergencia_vial ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {
                        perfilAdmin ?
                          <>
                            <Col xs="1" md="1" className="text-end">
                              <label htmlFor='btn-file-safe-cars'>
                                <RiFileEditFill onClick={() => {
                                  setInfoDocumentSafeCar('plan_prep_respu_emergencia_vial')
                                }} />
                              </label>
                            </Col>
                            <Col xs="1" md="1" className="text-end">
                              <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('plan_prep_respu_emergencia_vial')} />
                            </Col>
                          </>
                          : null
                      }
                    </Row>
                    <NavDropdown.Item className='textoNav' href="/RegistrosCaracteristicas" >
                      P13. Investigación Interna de Siniestros Viales
                    </NavDropdown.Item>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.vias_seguras ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.vias_seguras : '#'} >
                          <div className=' col-12'>
                            P14. Vias Seguras
                          </div>
                          {
                            !documentsSafeCars.vias_seguras ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {
                        perfilAdmin ?
                          <>
                            <Col xs="1" md="1" className="text-end">
                              <label htmlFor='btn-file-safe-cars'>
                                <RiFileEditFill onClick={() => {
                                  setInfoDocumentSafeCar('vias_seguras')
                                }} />
                              </label>
                            </Col>
                            <Col xs="1" md="1" className="text-end">
                              <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('vias_seguras')} />
                            </Col>
                          </>
                          : null
                      }
                    </Row>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.planificacion_desplazamiento ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.planificacion_desplazamiento : '#'} >
                          <div className=' col-12'>
                            P15. Planificación de Desplazamientos
                          </div>
                          {
                            !documentsSafeCars.planificacion_desplazamiento ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {
                        perfilAdmin ?
                          <>
                            <Col xs="1" md="1" className="text-end">
                              <label htmlFor='btn-file-safe-cars'>
                                <RiFileEditFill onClick={() => {
                                  setInfoDocumentSafeCar('planificacion_desplazamiento')
                                }} />
                              </label>
                            </Col>
                            <Col xs="1" md="1" className="text-end">
                              <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('planificacion_desplazamiento')} />
                            </Col>
                          </>
                          : null
                      }
                    </Row>
                    <NavDropdown.Item className='textoNav' href="/vehiculos-seguros/inspeccion-vehiculos" >
                      P16. Inspección de Vehículos
                    </NavDropdown.Item>
                    <Modal show={showMantenimiento} onHide={handleCloseModal} backdrop="static" size='lg' centered aria-labelledby="contained-modal-title-vcenter">
                      <Modal.Header closeButton />
                      <Modal.Body>
                        {
                          tipoVehiculo.length > 0
                            ?
                            <Row lg={2} md={2} xs={1}>
                              {
                                tipoVehiculo.map((item, i) => (
                                  <Col key={i} className="mb-3"  style={{maxHeight: '300px'}}>
                                    <a onClick={() => { handleVehiculo(item.id) }} style={{ color: 'black', cursor: 'pointer' }}><CardMantenimeinto props={{datos: item,}}/></a>
                                  </Col>
                                ))

                              }
                            </Row>
                            :
                            <Row><h2>No hay vehiculos registrados</h2></Row>
                        }
                      </Modal.Body>

                    </Modal>
                    <NavDropdown.Item className='textoNav' onClick={onChangeTipoVehiculo}>
                      P17. Mantenimiento y Control de Vehículos
                    </NavDropdown.Item>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.gestion_cambio ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.gestion_cambio : '#'}>
                          <div className=' col-5'>
                            P18. Gestión de Cambios
                          </div>
                          {
                            !documentsSafeCars.gestion_cambio ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {
                        perfilAdmin ?
                          <>
                            <Col xs="1" md="1" className="text-end">
                              <label htmlFor='btn-file-safe-cars'>
                                <RiFileEditFill onClick={() => {
                                  setInfoDocumentSafeCar('gestion_cambio')
                                }} />
                              </label>
                            </Col>
                            <Col xs="1" md="1" className="text-end">
                              <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('gestion_cambio')} />
                            </Col>
                          </>
                          : null
                      }
                    </Row>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.archivo_retencion ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.archivo_retencion : '#'}>
                          <div className=' col-12'>
                            P19. Archivo y Retención
                          </div>
                          {
                            !documentsSafeCars.archivo_retencion ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {
                        perfilAdmin ?
                          <>
                            <Col xs="1" md="1" className="text-end">
                              <label htmlFor='btn-file-safe-cars'>
                                <RiFileEditFill onClick={() => {
                                  setInfoDocumentSafeCar('archivo_retencion')
                                }} />
                              </label>
                            </Col>
                            <Col xs="1" md="1" className="text-end">
                              <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('archivo_retencion')} />
                            </Col>
                          </>
                          : null
                      }
                    </Row>
                  </NavDropdown>

                  <NavDropdown title="Verificar" id="basic-nav-dropdown" className="flex-fill">
                    <NavDropdown.Item className='textoNav' href="/Indicadores" style={{ width: '500px' }}>
                      P20. Indicadores
                    </NavDropdown.Item>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.regis_anali_siniestros_viales ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.regis_anali_siniestros_viales : '#'}>
                          <div className=" col-12">
                            P21. Registro y Análisis Estadístico de Siniestros Viales
                          </div>
                          {
                            !documentsSafeCars.regis_anali_siniestros_viales ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {perfilAdmin ?
                        <>
                          <Col xs="1" md="1">
                            <label htmlFor="btn-file-safe-cars">
                              <RiFileEditFill onClick={() => {
                                setInfoDocumentSafeCar('regis_anali_siniestros_viales')
                              }} />
                            </label>
                          </Col>
                          <Col xs="1" md="1">
                            <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('regis_anali_siniestros_viales')} />
                          </Col>
                        </>
                        : null
                      }
                    </Row>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.auditoria ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.auditoria : '#'}>
                          <div className=" col-12">
                            P22. Autitoría
                          </div>
                          {
                            !documentsSafeCars.auditoria ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {perfilAdmin ?
                        <>
                          <Col xs="1" md="1">
                            <label htmlFor="btn-file-safe-cars">
                              <RiFileEditFill onClick={() => {
                                setInfoDocumentSafeCar('auditoria')
                              }} />
                            </label>
                          </Col>
                          <Col xs="1" md="1">
                            <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('auditoria')} />
                          </Col>
                        </>
                        : null
                      }
                    </Row>
                  </NavDropdown>

                  <NavDropdown className="flex-fill" title="Actuar" id="basic-nav-dropdown">
                    <NavDropdown.Item className='textoNav' href="/gestion-tareas/calendario" style={{ width: '500px' }}>
                      <div className=" col-12">
                        P23. Mejora Contínua
                      </div>
                    </NavDropdown.Item>
                    <Row>
                      <Col xs="9">
                        <NavDropdown.Item className='textoNav' target="_blank" href={documentsSafeCars.meca_participacion_comunicacion ? process.env.REACT_APP_HOST_FTP + '/pdfsVehiculosSeguros/' + documentsSafeCars.meca_participacion_comunicacion : '#'}>
                          <div className=" col-12">
                            P24. Mecanismos de Participación y Comuniación
                          </div>
                          {
                            !documentsSafeCars.meca_participacion_comunicacion ? <Badge bg="danger">No existe</Badge> : null
                          }
                        </NavDropdown.Item>
                      </Col>
                      {
                        perfilAdmin ?
                          <>
                            <Col xs="1" md="1">
                              <label htmlFor="btn-file-safe-cars">
                                <RiFileEditFill onClick={() => {
                                  setInfoDocumentSafeCar('meca_participacion_comunicacion')
                                }}
                                />
                              </label>
                            </Col>
                            <Col xs="1" md="1">
                              <BsFillTrashFill onClick={() => queryDeleteDocumentsSafeCars('meca_participacion_comunicacion')}
                              />
                            </Col>
                          </>
                          : null
                      }
                    </Row>
                  </NavDropdown>

                  <NavDropdown  className="flex-fill" title="Administración y Usuarios" id="basic-nav-dropdown" >
                    <NavDropdown.Item href="/VehiculoSeguroHojaVidaVehiculos">
                      Ver Vehículos
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/conductores">
                      Ver Conductores
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/administrar-encuestados">
                      Administrar Encuestados
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/GestionUsuario">
                      Gestión Usuarios
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/encuesta/esv">
                      ESV
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/vehiculos-seguros/indicador-ESV">
                      Indicador ESV
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown  className="flex-fill align=right" title={<img src={process.env.REACT_APP_HOST_FTP + '/documentoLogo/'+logo_empresa} alt={logo.empresa} style={{ maxHeight: "50%", maxWidth: "25%" }}/>}>
                    <NavDropdown.Item>
                      {nombre_empresa}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => cerrarSesion()}>
                      Cerrar Sesión
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          :
          <Navbar bg="dark" variant="dark" expand="xl" fixed='top' className="mb-3">
            <Container>
              <Navbar.Brand onClick={() => OnchangeMenu()} style={{cursor: 'pointer'}}>PESV</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link variant="dark" href="../../gestion-mantenimiento-vehiculos">MTTO</Nav.Link>
                  <Nav.Link variant="dark" href="/gestion-mantenimiento/consultar-kilometraje">Kilometraje/Horometro</Nav.Link>
                  <Nav.Link variant="dark" href="/gestion-mantenimiento/consultar-mantenimiento">Mantenimiento</Nav.Link>
                  <Nav.Link variant="dark" href="/gestion-mantenimiento/ficha-mantenimiento">Ficha de Mantenimiento</Nav.Link>
                  <Nav.Link variant="dark" href="/gestion-mantenimiento/indicador-flota">Indicador de Flota</Nav.Link>
                  <Nav.Link variant="dark" href="/gestion-mantenimiento/informeKilometraje">Informe Kilometraje</Nav.Link>
                  <Nav.Link variant="dark" href="/gestion-mantenimiento/consultar-rutinas">Rutinas</Nav.Link>
               
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
      }
    </>

  );
}
export default Navegador;
