import React from 'react';
import { Container} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function CardGraficas({props}) {
    
    const cookie = new Cookies ();
    const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');

    const {id, nombre } = props;    
    const [dataIndicador, setDataIndicador] = useState ([]);


    const consultar = async () =>{
        const consultar = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            `/api/v2/consultarIndicadores/id/graficas`,{id},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    Permission: '1',
                },
            }
            )
            if(consultar.data.message){
                const datos = consultar.data.resultado
                setDataIndicador(datos)   
            }
    }
    
    const data = {
      labels: dataIndicador.map(e => e.frecuencia),
      datasets: [
        {
          axis: 'x',
          data: dataIndicador.map(e => e.indicador),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235)",
          borderWidth: 2,
          borderRadius: 10,
          stroke: "black",
        },
      ],                      
    };
    const options = {
      scales: {
        
        y: {
          title:{
            display: true,
            text: "INDICADOR",
            font: {
              size: 16,
              color: "black",
              weight: "bold",
            },
          },
          beginAtZero: true,
          suggestedMax: Math.max(...dataIndicador.map(e => e.indicador)) + 2,
        },
      },      
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true
        }
      },
      interaction: {
        intersect: false,
      },
      animation: {
        duration: 1500,
      },
    };

    
    useEffect(() => {
        consultar();
    }, [])
    
    return (
        <>
            <br/>           
            <Container className="mt-2 text-center">
                <h3>{nombre.toUpperCase()}</h3>
                <Bar data={data} options={options} />    
            </Container>
            <br/><br/>
        </>
    )
}



export default CardGraficas;