import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from "sweetalert2";

function RegistrarKilometraje() {
    const cookie = new Cookies ();
    const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');    
    const tipo_vehiculo = cookie.get('tipo_vehiculo');

  const navigate = useNavigate();

    const [validated, setValidated] = useState(false);    
    const [validacionTipoVehiculo, setValidacionTipoVehiculo] = useState(true);    
    const [validacionAccion, setValidacionAccion] = useState(false);    
    const[vehiculo, setVehiculo] = useState([]);
    
    const[datos, setDatos] = useState({
        Fecha: moment().format('YYYY-MM-DD'),
        Vehiculo: '',
        Kilometraje: '',
        Horometro: '',
        Galones: '',
        Precio: '',
        Observacion: ''
    });

    const onChangeInfo = e =>{
        const {name, value} = e.target;
        setDatos ({
        ...datos,
        [name]: value,
        });
    };
    
    const ConsultarVehiculos  = async() => {
        
        if(tipo_vehiculo === '4'){setValidacionTipoVehiculo(false);}//Si es 4 es Maquinaria Amarilla y debe mostrar solo la parte del horometro

        const {data} = await axios.post (
            process.env.REACT_APP_SERVIDOR +
              '/api/v2/mantenimiento/consultar/vehiculos',{tipo: tipo_vehiculo},
            {
                 headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );          
          setVehiculo(data.datos);

        if(window.location.search !== ''){   
            let id_kilometraje= (window.location.search).split('=')[1];    
            const {data} = await axios.post (
                process.env.REACT_APP_SERVIDOR +
                  '/api/v2/mantenimiento/listar/Kilometraje/id',{tipo: tipo_vehiculo, id_kilometraje: id_kilometraje},
                {
                  headers: {
                    Authorization: `Bearer ${access_token}`,
                  },
                }
              );         
              setValidacionAccion(true) 
            if(data.estado){
                const datosKilometraje = data.datos[0];    
                setDatos({
                    Fecha: moment(datosKilometraje.fecha).format('YYYY-MM-DD'),
                    Vehiculo: datosKilometraje.id_vehiculo_fk,
                    Kilometraje: datosKilometraje.kilometraje,
                    Horometro: datosKilometraje.kilometraje,
                    Galones: datosKilometraje.galones,
                    Precio: datosKilometraje.precio,
                    Observacion: datosKilometraje.observacion
                });
            }
        }
    }
    
    const validityForm = async (e) => {
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
          } else {
            e.preventDefault();
            
            if(!validacionAccion){
                await RegistrarKilometraje();
            }else{
                await ModificarKilometraje();

            }
            
          }
        };   
    const RegistrarKilometraje = async ()=>{
        try {
            
            const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR + '/api/v2/mantenimiento/registrar_kilimetraje',
            datos,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
              },
            }
          );
          
          if(data.message === "success"){
            await queryCumplimiento()
            mostrarAlert("success", "Mensaje", "Registro Exitoso");
            setDatos({
                Fecha: moment().format('YYYY-MM-DD'),
                Vehiculo: '',
                Kilometraje: '',
                Horometro: '',
                Galones: '',
                Precio: '',
                Observacion: ''
            });
          }
  
          if (data.message === 'success') {
            setValidated(false);
          }
        } catch (error) {
          console.log('Error insert kilometraje: ' + error.message);
          if (error.response.status === 403) {
            Swal.fire ({
              icon: 'error',
              title: 'No tienes permiso para esta acción',
            });
          }
        }
    }
    const ModificarKilometraje = async ()=>{
        try {
            let id_kilometraje = (window.location.search).split('=')[1];
            const tipo_vehiculo = cookie.get('tipo_vehiculo');
            const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR + '/api/v2/mantenimiento/actualizar_kilimetraje',
            {dato: datos, tipo: tipo_vehiculo, idKilometraje: id_kilometraje},
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
              },
            }
          );
          if(data.message === "success"){
            await queryCumplimiento()
            mostrarAlert("success", "Mensaje", "Actualización Exitoso");
            setDatos({
                Fecha: moment().format('YYYY-MM-DD'),
                Vehiculo: '',
                Kilometraje: '',
                Horometro: '',
                Galones: '',
                Precio: '',
                Observacion: ''
            });
            setValidated(false);
            navigate('/gestion-mantenimiento/consultar-kilometraje');
          }
        } catch (error) {
          console.log('Error updated kilometraje: ' + error.message);
          if (error.response.status === 403) {
            Swal.fire ({
              icon: 'error',
              title: 'No tienes permiso para esta acción',
            });
          }
        }
    }
    const queryCumplimiento = async () =>{
        await axios.post(
            process.env.REACT_APP_SERVIDOR + '/api/v2/listar/mantenimiento/informe_cumplimiento/tipo_vehiculo',
            {tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,                    
                },
            }
        );
    }
    const mostrarAlert = (icono, titulo, mensaje) => {
        Swal.fire({
            icon: icono,
            title: mensaje,
        });
    }

    useEffect(()=>{
        ConsultarVehiculos();
    }, []);


    return (
        <>
        {
            validacionTipoVehiculo
            ?
            <Container className="text-center mt-3">
                <Row className="mb-3">
                    <h1>Formulario Kilometraje</h1>
                </Row>
                <Row className="mb-3">
                    <Form noValidate validated={validated} onSubmit={e => validityForm(e)}>
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Form.Label>Fecha</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group >
                                    <Form.Control required name="Fecha" type="date" value={datos.Fecha} onChange={onChangeInfo}/>
                                    <Form.Control.Feedback type="invalid">Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>    
                        </Row>
                        <br/>                        
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Form.Label>Vehículo</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                        <Form.Select required name="Vehiculo" value={datos.Vehiculo} onChange={onChangeInfo}>
                                            <option value="">Seleccione...</option>
                                            {
                                                vehiculo.map((fila, id) =>(
                                                    <option key={id} value={fila.id_vehiculo}>{fila.placa}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Obligatorio
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>   
                        <br/>                     
                        <Row className="mb-1 align-items-center justify-content-center">  
                            <Col xs="2"><Form.Label>Kilometraje</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                    <Form.Control required name="Kilometraje" type="number" value={datos.Kilometraje} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>  
                        <br/> 
                        <Row className="mb-1 align-items-center justify-content-center">                      
                            <Col xs="2"><Form.Label>Galones</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                    <Form.Control required name="Galones" type="number"  value={datos.Galones} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>  
                        <br/> 
                        <Row className="mb-1 align-items-center justify-content-center">      
                            <Col xs="2"><Form.Label>Precio</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                    <Form.Control required name="Precio" type="number" value={datos.Precio} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/> 
                        <Row className="mb-1 align-items-center justify-content-center">  
                            <Col xs="2"><Form.Label>Observaciones</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                    <Form.Control style={{minHeight: '100px'}} required name="Observacion" maxLength={250} type="textarea" value={datos.Observacion} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Observaciones</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>  
                        <br/>

                        <Row className="mb-1 align-items-center justify-content-center">      
                            <Col xs="2"><Button  type="submit" variant='dark'>Aceptar</Button></Col>                            
                        </Row>
                    </Form>
                    
                </Row>
            </Container>
            :
        
            <Container className="text-center mt-3">
                <Row className="mb-3">
                    <h1>Formulario Horometro</h1>
                </Row>
                <Row className="mb-3">
                    <Form noValidate validated={validated} onSubmit={e => validityForm(e)}>
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Form.Label>Fecha</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group >
                                    <Form.Control required name="Fecha" type="date" value={datos.Fecha} onChange={onChangeInfo}/>
                                    <Form.Control.Feedback type="invalid">Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>    
                        </Row>
                        <br/>                        
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Form.Label>Vehículo</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                        <Form.Select required name="Vehiculo" value={datos.Vehiculo} onChange={onChangeInfo}>
                                            <option value="">Seleccione...</option>
                                            {
                                                vehiculo.map((fila, id) =>(
                                                    <option key={id} value={fila.id_vehiculo}>{fila.placa}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Obligatorio
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>   
                        <br/>                     
                        <Row className="mb-1 align-items-center justify-content-center">  
                            <Col xs="2"><Form.Label>Horometro</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                    <Form.Control required name="Horometro" type="number" value={datos.Horometro} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>  
                        <br/> 
                        <Row className="mb-1 align-items-center justify-content-center">  
                            <Col xs="2"><Form.Label>Observaciones</Form.Label></Col>
                            <Col xs="6">
                                <Form.Group>
                                    <Form.Control style={{minHeight: '100px'}} required name="Observacion" maxLength={250} type="textarea" value={datos.Observacion} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Observaciones</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>  
                        <br/> 

                        <Row className="mb-1 align-items-center justify-content-center">      
                            <Col xs="2"><Button  type="submit" variant='dark'>Aceptar</Button></Col>                            
                        </Row>
                    </Form>
                    
                </Row>
            </Container>
        }
        </>
    )
}
export default RegistrarKilometraje;