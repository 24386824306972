import { Container, Row, Col, Table, Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

function Inicio() {
    const cookie = new Cookies;
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    const tipo_vehiculo = cookie.get('tipo_vehiculo');

    const [tipoVehiculo, setTipoVehiculo] = useState('')
    
    const [vehiculo, setVehiculo] = useState([])
    const [showInfo, setShowInfo] = useState(false);
    const [htmlString, setHtmlString] = useState('');
    const [matenimientoTotal, setMatenimientoTotal] = useState(0);

    const handleVehiculo = () => {
        const tipo = cookie.get('tipo_vehiculo');
        switch (tipo) {
            case '1': setTipoVehiculo('Moto'); break;
            case '2': setTipoVehiculo('Vehiculo'); break;
            case '3': setTipoVehiculo('Vehiculo de carga'); break;
            case '4': setTipoVehiculo('Maquinaria Amarilla'); break;
            default: setTipoVehiculo('Vehiculo'); break;
        }
    }
    const ConsultarVehiculos = async () => {

        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/mantenimiento/consultar_cumplimiento/vehiculos', { tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
            console.log(data)
       /* if(data.estado){
            let sumaDias = 0;
            for (let i = 0; i < data.datos.length; i++) {
                sumaDias += data.datos[i].suma_dias;                
            }
            
            let totalDias = 26 * data.datos.length;

            let resultado = (((totalDias - sumaDias) / totalDias)*100);
            if(resultado < 0){
                resultado *= -1;
            }
            setMatenimientoTotal(resultado)
            setVehiculo(data.datos);

        }*/
        if(data.estado){
            let sumaDias = 0;
            let cumple=0;
            for (let i = 0; i < data.datos.length; i++) {      
                if(data.datos[i].cumple=="SI"){
                     cumple++;
                }         
            }
            
            let totalcumple = data.datos.length;

            let resultado = (((cumple) / totalcumple)*100);
            
            setMatenimientoTotal(resultado)
            setVehiculo(data.datos);

        }
    }

    const info = async (id, placa) => {
        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/listar/mantenimiento/informe_analisis', { tipo: tipo_vehiculo, id_vehiculo: id },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        setHtmlString("")    
        if (data !== '') {
            setHtmlString(data)           
            setShowInfo(true)
        }else{
            setHtmlString(`<Table class='table table-bordered text-center'>

            <thead><tr> <th>VEHÍCULO</th><th colspan='5' >${placa}</th> </tr></thead>
            <tbody>
              <tr> <th>RUTINA</th><th>ULTIMO MNTO</th> <th>KM ACTUAL</th> <th>PROX MNTO</th> <th>CUMPL</th> <th>DÍAS</th> </tr>
            </tbody>
            </Table>`)                      
            setShowInfo(true)
        
        }
    }
   
    const closeModal = async () =>{
        setHtmlString("");
        setShowInfo(false)
    }
    useEffect(() => {
        ConsultarVehiculos();
        handleVehiculo();
    }, [])
    return (  
        <>
            <Container className="mt-5 text-center">
                <Row className="mb-5">
                    <Col>
                        <h2>Sistema Gestión de Mantenimiento de {tipoVehiculo}</h2>
                    </Col>
                    <br/><br/>
                    <Col xs="18">
                        <Table>
                            <thead>
                                <tr>
                                    <th>VEHICULOS</th>
                                    <th>CUMPLIMIENTO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    vehiculo.map((fila, id) => (
                                        <tr key={id}>
                                            <td value={fila.id_vehiculo}>{fila.placa.toUpperCase()}</td>
                                            {
                                                fila.cumple === "NO"
                                                ?
                                                <td><Button variant='danger' onClick={() => info(fila.id_vehiculo, fila.placa)}>NO CUMPLE</Button></td>
                                                :
                                                <td><Button variant='success' onClick={() => info(fila.id_vehiculo, fila.placa)}>CUMPLE</Button></td>
                                            }
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <td><strong>TOTAL</strong></td>
                                    <td><strong>{matenimientoTotal.toFixed(2)}%</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                
                <Modal
            show={showInfo}
            aria-labelledby="example-modal-sizes-title-sm"
            onHide={() => closeModal()}
            size='lg'
        >
            <Modal.Header closeButton />
            <Modal.Body style={{ margin: '2%' }}>
                <Container >
                    <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
                </Container>
            </Modal.Body>
        </Modal>
            </Container>
            
        </>
    );

}
export default Inicio;