import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

function ConsultarNumeros() {
  const access_token = new Cookies().get('perv_v2_cookies_user_kdfoeinapjdkfawep');

  const [dataSurveys, setDataSurveys] = useState([]);
  const [queryPermisos, setQueryPermisos] = useState([]);
  const querySurvey = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_SERVIDOR + '/api/v2/consult/numeros/telefono', {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
      
      setDataSurveys(data);
    } catch (error) {
      console.log('Error getting survey data: ' + error.message);
    }
  }

  const modifyUser = async (id, numero, accion) => {
    try {
      
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + '/api/v2/update/numeros/telefono',
        { id, numero, accion },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: '1',
          },
        }
      );

      if (data.message === 'success') {
        querySurvey();
      }else if (data.message === 'Ya existe el número') {
        Swal.fire ({
          icon: 'info',
          title: 'No fue posible registrar',
          text: 'El número que intentastes activar ya existe y no puede ser duplicado.',
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log('Error modifyUser: ' + error.message);
    }
  };

  useEffect(() => {
    querySurvey();
  }, [])
  return (
    <Container className="text-center mt-2">

      <Row className="mb-3 justify-content-center">
        <h3>Consultar Numeros Registrados</h3>
      </Row>
      <Row className="mb-6 justify-content-center align-items-center">
        <Col className="col-auto">
          <Button variant="dark" href="/registrar-numero-celular">Registrar Encuestados</Button>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
          <Table responsive bordered striped hover>
            <thead>
              <tr>
                <th>Numero</th>
                <th>Centro</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {
                dataSurveys.map((numero, i) =>
                  <tr key={i}>
                    <td>{numero.numero_celular}</td>
                    <td>{numero.centro_descripcion}</td>
                    {
                      numero.estado === 'Activo'
                        ?
                        <td><Button variant="danger" onClick={() => modifyUser(numero.id,numero.numero_celular, 1)}>Desactivar</Button></td>
                        :
                        <td><Button variant="success" onClick={() => modifyUser(numero.id,numero.numero_celular, 0)}>Activar</Button></td>

                    }
                  </tr>
                )
              }
            </tbody>
          </Table>
        </Col>
      </Row>

    </Container>
  );

}
export default ConsultarNumeros;