import React, {useEffect, useState} from 'react';
import {Container, Card, Row, Col, Modal, Button, Form, Badge} from 'react-bootstrap';
import {BsFillTrashFill, BsPencilSquare} from 'react-icons/bs';
import {RiFileEditFill} from 'react-icons/ri';
import axios from 'axios';
import Cookies from 'universal-cookie';
import conductor from '../img/conductor_img.png';
import Swal from 'sweetalert2';
import moment from 'moment/moment';

function CardDrivers({props}) {
  const cookie = new Cookies ();
  const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');
  const {id_conductor, nombre, img, data, queryData, id_centro_fk} = props;
  const now = moment().utc().subtract(5, 'hours');
  const [imgDriver, setImgDriver] = useState (img);
  const [showInfo, setShowInfo] = useState (false);
  const [showModalFile, setShowModalFile] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState (false);
  const [document, setDocument] = useState ('');
  const infoDeleteFile = {nombre_conductor: nombre, id_centro_fk};
  const [dataCentros, setDataCentros] = useState ([]);
  const [tipoVehiculo, setTipoVehiculo] = useState ([]);

  const [imgConductor, setImgConductor] = useState (false);
  const [infoInserFile, setInfoInserFile] = useState({
    file: '',
    fecha: ''
  });
  const [infoDriver, setInfoDriver] = useState ({
    id_conductor: id_conductor,
    documento_conductor: '',
    nombre_conductor: '',
    tipo_conductor: '',
    id_centro_fk: '',
  });

  const onChangeFileFecha = e => {
    let fecha = e.target.value;
    setInfoInserFile({
      ...infoInserFile,
      fecha: fecha
    })
  }

  const onChangeFilePdf = async e => {
    let file = e.target.files[0];
    setInfoInserFile({
      ...infoInserFile,
      file: file
    })
   
  };
  const onChangeInfoDriver = e => {
    const {name, value} = e.target;
    setInfoDriver ({
      ...infoDriver,
      [name]: value,
    });
  };
  const onChangeImg = e => {
    setImgConductor (e.target.files[0]);
  };
  const handleCloseModalInsert = () => {
    setShowModalUpdate (false);
  };
  const queryCentros = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/centro',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCentros (data);
    } catch (error) {
      console.log (
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  const queryTipoVehiculo = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/listar/vehiculos',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setTipoVehiculo (data.datos);
      
    } catch (error) {
      console.log (
        'Error while fetching data vehiculo from server: ' + error.message
      );
    }
  };
  const modalInsert = (
    <Modal
      show={showModalUpdate}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Información Conductor</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '2%'}}>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              value={infoDriver.nombre_conductor}
              name="nombre_conductor"
              type="text"
              placeholder="Nombre"
              onChange={onChangeInfoDriver}
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Documento</Form.Label>
            <Form.Control
              value={infoDriver.documento_conductor}
              name="documento_conductor"
              type="number"
              placeholder="Documento"
              onChange={onChangeInfoDriver}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Tipo conductor</Form.Label>
            <Form.Select
              value={infoDriver.tipo_conductor}
              name="tipo_conductor"
              placeholder="Tipo conductor"
              onChange={onChangeInfoDriver}
            >
              <option value="no existe">Elige tipo conductor</option>                
                {tipoVehiculo.map((item, i) => (
                <option key={i} value={item.nombre}>
                  {item.nombre}
                </option>
              ))}

            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Centro</Form.Label>
            <Form.Select
              value={id_centro_fk}
              name="id_centro_fk"
              placeholder="Centro"
              onChange={onChangeInfoDriver}
            >
              <option value="no existe">Elige centro</option>
              {dataCentros.map ((item, i) => (
                <option key={i} value={item.id_centro}>
                  {item.centro_descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              name="img_driver"
              type="file"
              placeholder="Imagen"
              onChange={onChangeImg}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={e => queryUpdateFileDriver (e)}>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );


  const queryUpdateDriver = async (id_conductor) => {
    try {

      
      const {data} = await axios.post (
        process.env.REACT_APP_SERVIDOR +'/api/v2/select/conductores/mantenimiento',{id_conductor},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if(data.message === 'success'){
        const {documento_conductor, nombre_conductor, tipo_conductor, id_centro_fk} = data.resultado[0];
        console.log(data.resultado[0])
        let grupo = tipo_conductor;
        if(tipo_conductor === 'moto'){grupo = 'Motos'}
        if(tipo_conductor === 'particularpasajeros'){grupo = '}Vehículos particulares y publicos'}
        if(tipo_conductor === 'carga'){grupo = 'Vehículos de carga'}
        if(tipo_conductor === 'pesada'){grupo = 'Maquinaria amarilla'}

        setInfoDriver({
          
          id_conductor: id_conductor,
          documento_conductor: documento_conductor,
          nombre_conductor: nombre_conductor,
          tipo_conductor: grupo,
          id_centro_fk: id_centro_fk,
        });
      }
      setShowModalUpdate(true)

      /* const {data} = await axios.get(process.env.REACT_APP_SERVIDOR + '/api/v2/select/conductores/mantenimiento', {id_conductor});
      console.log(data) */
    } catch (error) {
      console.log('Error updating driver: ' + error.message);
    }
  }
  const queryUpdateFileDriver = async () => {
      
      // Construye el formdata para enviar la imagen
      let formData = new FormData ();
      formData.append ('img_driver_mantenimiento', imgConductor);
      try {
        if (infoDriver.nombre_conductor === '') {
          Swal.fire ({
            icon: 'error',
            title: 'El nombre es obligatorio',
          });
        } else if (infoDriver.documento_conductor === '') {
          Swal.fire ({
            icon: 'error',
            title: 'El documento es obligatorio',
          });
        } else if (
          infoDriver.tipo_conductor === '' ||
          infoDriver.tipo_conductor === 'no existe'
        ) {
          Swal.fire ({
            icon: 'error',
            title: 'El tio de conductor es obligatorio',
          });
        } else if (
          infoDriver.id_centro_fk === '' ||
          infoDriver.id_centro_fk === 'no existe'
        ) {
          Swal.fire ({
            icon: 'error',
            title: 'El centro es obligatorio',
          });
        } else {
          const {data} = await axios.post (
            process.env.REACT_APP_SERVIDOR +
              '/api/v2/update/conductores/mantenimiento',
            infoDriver,
            {
              headers: {
                Authorization: 'Bearer ' + access_token,
                Permission: '1',
              },
            }
          );
          if (imgConductor) {
            await axios.post (
              process.env.REACT_APP_SERVIDOR +
                `/api/v2/insert/img/conductores/mantenimiento/${infoDriver.nombre_conductor}/${infoDriver.id_centro_fk}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                  Permission: '1',
                  Accept: 'application/json',
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            await queryData();
          }
  
          if (data.message === 'success') {
            setShowModalUpdate (false);
            setInfoDriver ({
              id_conductor: '',
              documento_conductor: '',
              nombre_conductor: '',
              tipo_conductor: '',
              id_centro_fk: '',
            });
            setImgConductor (false);
            queryData ();
          }
        }
      } catch (error) {
        if (error.response.status === 403) {
          Swal.fire ({
            icon: 'error',
            title: 'No tienes permiso para esta acción',
          });
          console.log (
            'Error while inserting data driver server: ' + error.message
          );
        }
      }
  }
  const queryInsertFileDriver = async () => {
    let formData = new FormData ();
    formData.append ('filePdfDriver', infoInserFile.file);
    try {
      const {data} = await axios.post (
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/files/documentation/driver/${nombre}/${id_centro_fk}/${document}/${infoInserFile.fecha}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Performance: '1',
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (data.message === 'success') {
        queryData ();
        setShowModalFile(false);
        setInfoInserFile({
          file: '',
          fecha: ''
        })
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
    }
  }

  const queryDeleteFileFtp = async document => {
    try {
      const {data} = await axios.post (
        process.env.REACT_APP_SERVIDOR +
          '/api/v2/delete/files/ftp/document/drivers',
        {...infoDeleteFile, document},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Performance: '1',
          },
        }
      );
      if (data.message === 'success') {
        queryData ();
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
    }
  };
  const queryDeleteDriver = async (id_conductor, id_centro) => {
    try {
      const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/delete/conductor/mantenimiento', {
        id_conductor,
        id_centro
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permission: '1'
        }
      });

      if(data.message === 'success'){
        queryData();
      }

    } catch (error) {
      console.log('Error deleting driver: ' + error.message);
    }
  }

  const modalFile = (
    <Modal show={showModalFile} onHide={() => setShowModalFile(false)}>
      <Modal.Body style={{margin: '2%'}}>
        <Form.Group className="mb-3">
          <Form.Label>File</Form.Label>
          <Form.Control type="file" accept='application/pdf' onChange={onChangeFilePdf} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Fecha vencimiento</Form.Label>
          <Form.Control type="date" name="fecha_vencimiento" onChange={onChangeFileFecha}/>
        </Form.Group>
        <Button onClick={() => queryInsertFileDriver()}>Guardar</Button>
      </Modal.Body>
    </Modal>
  )

  const info = (
    <Modal show={showInfo} onHide={() => setShowInfo (false)}>
      <Modal.Header closeButton>
        <Modal.Title style={{marginLeft: '0px'}}>
          {nombre}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '2%'}}>
        <Container>
          <Row>
            
            <Col xs={3} md={3}>
              Licencia
            </Col>
            <Col xs={7} md={7}>
              {data.licencia_conductor === '' ||
                data.licencia_conductor === null ||
                undefined
                ? 'Sin documento'
                : <a
                    href={
                      process.env.REACT_APP_HOST_FTP +
                        'pdfsConductores/' +
                        data.licencia_conductor
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none"
                  >
                    {moment(data.licencia_conductor_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='col-10 text-truncate'>{data.licencia_conductor}</Badge> : <Badge bg="success" className='col-10 text-truncate'>{data.licencia_conductor}</Badge>}
                  </a>}
            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill
                  aria-label="upload pdf"
                  onClick={() => {
                    setShowModalFile(true);
                    setDocument ('licencia_conductor')}}
                />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill
                onClick={() => queryDeleteFileFtp ('licencia_conductor')}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={3} md={3}>
              Contrato 
            </Col>
            <Col xs={7} md={7}>
              {data.revision_medica_conductor === '' ||
                data.revision_medica_conductor === null ||
                undefined
                ? 'Sin documento'
                : <a
                    href={
                      process.env.REACT_APP_HOST_FTP +
                        'pdfsConductores/' +
                        data.revision_medica_conductor
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none"
                  >
                    {moment(data.revision_medica_conductor_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='col-10 text-truncate'>{data.revision_medica_conductor}</Badge> : <Badge bg="success" className='col-10 text-truncate'>{data.revision_medica_conductor}</Badge>}
                  </a>}
            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill
                  aria-label="upload pdf"
                  onClick={() => {
                    setShowModalFile(true);
                    setDocument ('revision_medica_conductor')}}
                />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill
                onClick={() => queryDeleteFileFtp ('revision_medica_conductor')}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={3} md={3}>
              Historial de Multas
            </Col>
            <Col xs={7} md={7}>
              {data.antecedentes_judiciales_conductor === '' ||
                data.antecedentes_judiciales_conductor === null ||
                undefined
                ? 'Sin documento'
                : <a
                    href={
                      process.env.REACT_APP_HOST_FTP +
                        'pdfsConductores/' +
                        data.antecedentes_judiciales_conductor
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none"
                  >
                    {moment(data.antecedentes_judiciales_conductor_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='col-10 text-truncate'>{data.antecedentes_judiciales_conductor}</Badge> : <Badge bg="success" className='col-10 text-truncate'>{data.antecedentes_judiciales_conductor}</Badge>}
                  </a>}
            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill
                  aria-label="upload pdf"
                  onClick={() =>{
                    setShowModalFile(true);
                    setDocument ('antecedentes_judiciales_conductor')}}
                />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill
                onClick={() =>
                  queryDeleteFileFtp ('antecedentes_judiciales_conductor')}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={3} md={3}>
              Adicionales
            </Col>
            <Col xs={7} md={7}>
              {data.adicional === '' ||
                data.adicional === null ||
                undefined
                ? 'Sin documento'
                : <a
                    href={
                      process.env.REACT_APP_HOST_FTP +
                        'pdfsConductores/' +
                        data.adicional
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none"
                  >
                    {moment(data.adicional_fecha_ven, "YYYY-MM-DD").isBefore(now) ? <Badge bg="danger" className='col-10 text-truncate'>{data.adicional}</Badge> : <Badge bg="success" className='col-10 text-truncate'>{data.adicional}</Badge>}
                  </a>}
            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill
                  aria-label="upload pdf"
                  onClick={() =>{
                    setShowModalFile(true);
                    setDocument ('adicional')}}
                />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill
                onClick={() =>
                  queryDeleteFileFtp ('adicional')}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowInfo (false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect (
    () => {
      const updateImg = () => {
        setImgDriver (img);
      };
      updateImg ();
      queryCentros();
      queryTipoVehiculo();
    },
    [img]
  );

  return(
    <>
    <Card 
      className="mx-auto"
      style={{width: '18rem', height: '100%'}}
      
    >
      <Card.Img onClick={() => setShowInfo(true)} style={{padding: '8%', height: '200px'}} variant="top" src={img ? process.env.REACT_APP_HOST_FTP + 'imagenesConductores/' + imgDriver : conductor} />
      <Card.Body className='mb-3'>
        <Row>
          <Col className='text-start' xs="8">
            <Card.Title>{nombre}</Card.Title>
          </Col>
          <Col className='text-end' xs="2">
            <Button onClick={() => queryUpdateDriver(data.id_conductor)} variant="primary" style={{width: '30px', height: '30px', padding: '0px'}}><BsPencilSquare /></Button>
          </Col>
          <Col className='text-end' xs="1">
            <Button onClick={() => queryDeleteDriver(data.id_conductor, id_centro_fk)} variant="danger" style={{width: '30px', height: '30px', padding: '0px'}}><BsFillTrashFill /></Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    {modalFile}
    {info}
    {modalInsert}
    </>
  )
}

export default CardDrivers;
