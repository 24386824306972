import './App.css';
import {Rutas} from './Rutas';

function App () {
  return (
    <div>
      <Rutas />
    </div>
  );
}

export default App;

