import React, { useState } from 'react';
import { Col, Container, Row, Button, Form, ProgressBar, Modal } from 'react-bootstrap';
import ModalUploadComponent from './ComponentsModal/ModalUploadCsv';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import moment from 'moment/moment';
import archivo from '../Doc/FormatoEncuesta.csv';
import Swal from 'sweetalert2';

function AdministrarEncuestado() {
  const access_token = new Cookies().get('perv_v2_cookies_user_kdfoeinapjdkfawep');

  const [dataVersions, setDataVersions] = useState([]);
  const [dataSurveys, setDataSurveys] = useState([]);

  const [tipoVehiculo, setTipoVehiculo] = useState([]);
  const [showTipo, setShowTipo] = useState(false);
  const [rangeDate, setRangeDate] = useState({
    initialDate: moment().format('YYYY-MM-DD'),
    finalDate: moment().format('YYYY-MM-DD'),
    version: '',
    tipo: '',
  });

  const onChangeRangeDate = e => {
    const { name, value } = e.target;
    setRangeDate(
      {
        ...rangeDate,
        [name]: value
      }
    )
  }

  const queryVersions = async () => {
    try {
      const access_token = new Cookies().get('perv_v2_cookies_user_kdfoeinapjdkfawep');
      const { data } = await axios.get(process.env.REACT_APP_SERVIDOR + '/api/v2/list/versions/surveys', {
        headers: {
          Authorization: 'Bearer ' + access_token
        }
      })
      if (data.estado) {
        setDataVersions(data.dato);
      }
    } catch (error) {
      console.log('Error getting versions list: ', error.message)
    }
  }
  const queryTipoVehiculo = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + '/api/v2/listar/vehiculos',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.estado) {
        setTipoVehiculo(data.datos);
      }

    } catch (error) {
      console.log(
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  const querySurvey = async () => {
    try {
      if(rangeDate.version !== ''){
        if(rangeDate.tipo !== ''){
          if(rangeDate.initialDate !== ''){
            if(rangeDate.finalDate !== ''){

              const { data } = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/calculate/survey/for/phone', rangeDate, {
                headers: {
                  Authorization: `Bearer ${access_token}`
                }
              })
              
              if(data.length > 0){
                setDataSurveys(data);
                setShowTipo(false)
              }


            }else{
              alerta("Debes seleccionar la Fecha Final")
            }
          }else{
            alerta("Debes seleccionar la Fecha Incial")
          }
        }else{
          alerta("Debes seleccionar el tipo de encuesta")
        }
      }else{
        alerta("Debes seleccionar la Versión")
      }
    } catch (error) {
      console.log('Error getting survey data: ' + error.message);
    }
  }
  const downloadFile = async () => {
    try {
      fetch(archivo)
        .then(resp => resp.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute("target", "_blank");
          link.download = 'FormatoEncuesta.csv';
          link.href = url;
          link.click();
        });

    } catch (error) {
      console.log('Error getting versions list: ', error.message)
    }
  }
  const alerta = (mensaje) =>{
    Swal.fire({
      icon: 'error',
      title: "ADVERTENCIA",
      text: mensaje,
      showConfirmButton: false,
      focusConfirm: false,
  })
  }
  useEffect(() => {
    queryVersions();
    queryTipoVehiculo();
  }, [])

  return (
    <Container className="text-center mt-2">
      <br/>
      <Row className="mb-3 justify-content-center">
        <h3>ADMINISTRAR ENCUESTADOS</h3>
      </Row>
      <br/>
      <Row className="mb-4 justify-content-center align-items-center">
        <Col xs="auto">
          <Row className='encuentaFiltro'>                    
            <Col xs="auto">
              <Button variant="dark" onClick={() =>setShowTipo(true)}>Consultar</Button>
            </Col>
            <Col xs="auto">
              <Button variant="dark" href="/consultar-numero-celular">Encuestados</Button>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Row className='encuentaFiltro'>
            <Col xs="auto">
              <Button variant="danger" onClick={() => downloadFile()}>Descargar</Button>
            </Col>
            <Col xs="auto">
              <ModalUploadComponent queryVersions={queryVersions} />
            </Col>
            
          </Row>
        </Col>
      </Row>
      <br />
      <Row>
        {
          dataSurveys.map((survey, i) =>
            <Row key={i} className="mb-4">
              <Col xs="3">{survey.celular}</Col>
              <Col xs="3">{survey.nombre}</Col>
              <Col><ProgressBar animated now={survey.answered} label={`${survey.answered}%`} /></Col>
            </Row>
          )
        }
      </Row>
      <Modal show={showTipo} onHide={() => setShowTipo(false)} backdrop="static" size='lg' >
                <Modal.Header closeButton />
                <Modal.Body  className="p-0 m-4" style={{ maxHeight: '500px'}}>
                <Row>
                        <Row lg={2} md={2} xs={1}>
                          <Col xs="auto" className="mb-2"><Form.Label>Versión</Form.Label></Col>
                            <Col className="mb-3" >
                              <Form.Select name="version" value={rangeDate.version} onChange={onChangeRangeDate}>
                                <option value="">Elige una versión</option>
                                {
                                  dataVersions.map((item, i) =>
                                    <option key={i} value={item.versions}>{moment(item.versions).add(5, 'hours').format("DD-MM-YYYY HH:mm:ss")}</option>
                                  )
                                }
                              </Form.Select>
                            </Col>    
                        </Row>
                        <Row lg={2} md={2} xs={1}>
                          <Col xs="auto" className="mb-2"><Form.Label>Tipo Encuesta: </Form.Label></Col>
                          <Col className="mb-2" >
                              <Form.Group>
                                    <Form.Select name="tipo" value={rangeDate.tipo} onChange={onChangeRangeDate}>
                                      <option value={""}>Selecciona el tipo de encuesta</option>
                                      {
                                        tipoVehiculo.map((item, i) =>
                                          <option key={i} value={item.id}>{item.nombre}</option>
                                          )
                                        }
                                    <option value={"5"}>ESV</option>
                                    </Form.Select>
                                    <Form.Control.Feedback>Obligatorio</Form.Control.Feedback>
                              </Form.Group>  
                          </Col>
                        </Row>
                        <Row lg={2} md={2} xs={1}>
                            <Col xs="auto" className="mb-2"><Form.Label>Rango Inicio</Form.Label></Col>
                            <Col className="mb-3" >
                              <Form.Group>
                                <Col className="mb-2"><Form.Control type="date" name="initialDate" value={rangeDate.initialDate} onChange={onChangeRangeDate} /></Col>
                              </Form.Group>
                            </Col>
                        </Row>
                        <Row lg={2} md={2} xs={1}>
                            <Col xs="auto" className="mb-2"><Form.Label>Rango Final</Form.Label></Col>
                            <Col className="mb-3" >
                              <Form.Group>
                                <Col className="mb-2"><Form.Control type="date" name="finalDate"  value={rangeDate.finalDate} onChange={onChangeRangeDate} /></Col>
                              </Form.Group>
                            </Col>
                        </Row>                        
                      </Row>
                  <Row><Button variant="dark" onClick={() => querySurvey()}>Consultar</Button></Row>
                </Modal.Body>
      </Modal>
    </Container>
  )
}

export default AdministrarEncuestado;