
import { useEffect, useState } from "react";
import {Container, Table, Form, Row, Col, Button, InputGroup} from 'react-bootstrap';

function IndexArchivos(){
    const [usuario, setUsuario] = useState(localStorage.getItem("ajfdkjakfljasdkljflkasjdflkasjdlfkaslkjffklasjdflkajslkfjalkdfjaksljdfklajdlfkjjfalk"));
    const [visualizarMensaje, setVisualizarMensaje] = useState('none');
    const [visualizacion, setVisualizacion] = useState('none');
    const [tabla, setTabla] = useState([]);
    const [pilar, setPilar] = useState('');


    function GetUsuario() {
        if (usuario === null) {
          window.location.href = "/";
        } else {
          fetch(process.env.REACT_APP_SERVIDOR +"/consulta_usuario_perfiles/" + usuario).then(
            (result) => {
              result.json().then((resp) => {
                if (
                  resp[0].perfiles === "Administrador" ||
                  resp[0].perfiles === "Comportamiento Humano"
                ) {
                  console.log("Entro");                  
                  setVisualizacion('block')
                } else {
                  window.location.href = "/Menu";
                }
              });
            }
          );
        }
      }
      
      function LlenarTabla(){
        let idPilar = pilar;
        if(idPilar !== ''){
          
          fetch(process.env.REACT_APP_SERVIDOR +"/consultarFormato/"+idPilar).then(
              (result) => {
                result.json().then((resp) => {
                  if (Object.keys(resp).length !== 0) {
  
                    console.table(resp)
                    setTabla(resp)
                    setVisualizarMensaje('none')
                  }else{
                    setTabla([])
                    setVisualizarMensaje('block')
                  }
                });
              }
            );
        }else{
          setTabla([])
          setVisualizarMensaje('block')
        }

          
      }
      useEffect(() => {
        GetUsuario();
      }, []);
    
      
    
      return (
        <>
        
          <Container style={{display: visualizacion}}>
            <br/>
            <h1>FORMATO PARA SUBIR ARCHIVOS</h1>
            <Form>
              <Row>
                <Col xs={4}>
                  <Form.Group>
                        <Form.Label>Nombre del Pilar</Form.Label>
                        <Form.Select value={pilar} onChange={(e) => {setPilar(e.target.value);}}>
                          
                          <option ></option>
                          <option value="1">Gestion institucional seguridad vial</option>
                          <option value="2">Comportamiento Humano</option>
                          <option value="3">Vehiculo Seguros</option>
                          <option value="4">Infraestructura Segura</option>
                          <option value="5">Atencion Victimas</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
             
              </Row>
              <br/>
                    <Button onClick={()=>LlenarTabla()} >Consultar</Button>{/*  */}
            </Form>
            <br/><br/>
            <Table striped bordered hover size="sm"style={{textAlig:'center'}} >
                    <thead style={{ textAlign: 'center'}}>
                    <tr >
                        <th><strong>Nombre</strong></th>
                        <th><strong>Fecha</strong></th>
                        <th><strong>Archivo</strong></th>
                        <th><strong>Acción</strong></th>
                    </tr>
                </thead>
                <tbody>
                  {
                    tabla.map((fila, id)=> (
                      <tr  key={id}>
                        <td style={{ textAlign: 'center'}}>{fila.nombre}</td>
                        <td style={{ textAlign: 'center'}}>{fila.fecha}</td>
                        <td style={{ textAlign: 'center'}}><a  style={{ color: 'black', textAlign: 'center', cursor:'pointer'}} href={fila.direccion} target="_blank"><strong>Documento de {fila.nombre}</strong></a></td>
                        <td style={{ textAlign: 'center'}}><Button  href={'/FormatoArchivos/Modificar?id='+fila.id+'&clase='+fila.clase_fk}>Modificar</Button></td>
                      </tr>
                    )
                    )}
                    <tr>
                      <td colSpan={4} style={{display: visualizarMensaje}}>No hay datos registrados</td>
                    </tr>
                </tbody>
                

            </Table>

          </Container>
        </>
      );
}
export default IndexArchivos;
