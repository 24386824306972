import React, {useEffect, useState} from 'react';
import {
  Container,
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Alert,
} from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

function PreguntasEsv () {
  const cookie = new Cookies ();
  const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');
  const [questions, setQuestions] = useState ([]);
  const [show, setShow] = useState (false);
  const [showInsert, setShowInsert] = useState (false);
  const [validatedDatos, setValidatedDatos] = useState (false);
  const [modifyQuestion, setModifyQuestion] = useState ({
    pregunta: '',
    id_pregunta: '',
  });
  const [insertQuestion, setInsertQuestion] = useState ({
    grupo_preguntas: '',
    tipo_pregunta: '',
    orden: '',
    pregunta: '',
    tipo_respuesta: '',
    respuesta_correcta: '',
  });
  const [groupQuestion, setGroupQuestion] = useState ('esv');

  const queryData = async () => {
    const cookie = new Cookies ();
    const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR +
          `/consulta_pregunta_co?groupQuestion=${groupQuestion}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setQuestions (data);
      if (data.length === 0) {
        setValidatedDatos (true);
      }
    } catch (error) {
      console.log ('Error query questions: ' + error.message);
    }
  };

  const queryModificarQuestion = async () => {
    try {
      if (modifyQuestion.pregunta === '') {
        Swal.fire ({
          icon: 'error',
          title: 'La descripción de la pregunta es obligatorio',
        });
        return;
      }

      const {data} = await axios.post (
        process.env.REACT_APP_SERVIDOR +
          '/api/v2/modify/preguntas/preoperacional',
        modifyQuestion,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: '1',
          },
        }
      );

      if (data.message === 'success') {
        setModifyQuestion ({
          pregunta: '',
          id_pregunta: '',
        });
        queryData ();
        setShow (false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log ('Error query modificar question: ' + error.message);
    }
  };

  const onChangeQuestion = e => {
    const {name, value} = e.target;

    setModifyQuestion ({
      ...modifyQuestion,
      [name]: value,
    });
  };

  const handleModalQuestion = id_pregunta => {
    setShow (true);
    setModifyQuestion ({
      ...modifyQuestion,
      id_pregunta: id_pregunta,
    });
  };

  const queryDeleteQuestion = async id_pregunta => {
    try {
      const {data} = await axios.post (
        process.env.REACT_APP_SERVIDOR +
          '/api/v2/delete/question/preoperacional',
        {id_pregunta},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: '1',
          },
        }
      );

      if (data.message === 'success') {
        queryData ();
        Swal.fire ({
          icon: 'success',
          title: 'Eliminación correcta',
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log ('Error query deleting question: ' + error.message);
    }
  };

  const onChangeInsertQuestion = e => {
    const {name, value} = e.target;

    if (value === 'Abierta' || value === 'SinRespuesta') {
      setInsertQuestion ({
        ...insertQuestion,
        [name]: value,
        respuesta_correcta: '',
      });
    } else {
      setInsertQuestion ({
        ...insertQuestion,
        [name]: value,
      });
    }
  };

  const queryInsertQuestion = async () => {
    try {
      if (insertQuestion.grupo_preguntas === '') {
        Swal.fire ({
          icon: 'error',
          title: 'Grupo preguntas es obligatorio',
        });
      } else if (insertQuestion.pregunta === '') {
        Swal.fire ({
          icon: 'error',
          title: 'Pregunta es obligatorio',
        });
      } else if (insertQuestion.orden === '') {
        Swal.fire ({
          icon: 'error',
          title: 'Orden es obligatorio',
        });
      } else if (insertQuestion.tipo_respuesta === '') {
        Swal.fire ({
          icon: 'error',
          title: 'Tipo respuesta es obligatorio',
        });
      } else {
        if (
          insertQuestion.tipo_respuesta === 'SiNo' &&
          insertQuestion.respuesta_correcta === ''
        ) {
          Swal.fire ({
            icon: 'error',
            title: 'Respuesta correcta es obligatorio',
          });
        } else {
          const {data} = await axios.post (
            process.env.REACT_APP_SERVIDOR +
              '/api/v2/insert/question/preoperacional',
            insertQuestion,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: '1',
              },
            }
          );

          if (data.message === 'success') {
            queryData ();
            setShowInsert (false);
            setInsertQuestion ({
              grupo_preguntas: '',
              tipo_pregunta: '',
              orden: '',
              pregunta: '',
              tipo_respuesta: '',
              respuesta_correcta: '',
            });
          }
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log ('Error query inserting question: ' + error.message);
    }
  };

  const modalInsert = (
    <Modal show={showInsert} onHide={() => setShowInsert (false)}>
      <Modal.Header closeButton>
        <Modal.Title>Datos nueva pregunta</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: '5%', margin: '0px'}}>
        <Form>
          <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label>Grupo Preguntas</Form.Label>
            <Form.Select
              name="grupo_preguntas"
              onChange={onChangeInsertQuestion}
            >
              <option value="">Elige grupo</option>
              <option value="esv">Esv</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Pregunta</Form.Label>
            <Form.Control
              name="pregunta"
              type="text"
              palceholder="Pregunta"
              onChange={onChangeInsertQuestion}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Orden</Form.Label>
            <Form.Control
              name="orden"
              type="text"
              palceholder="Orden"
              onChange={onChangeInsertQuestion}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Tipo respuesta</Form.Label>
            <Form.Select
              name="tipo_respuesta"
              onChange={onChangeInsertQuestion}
            >
              <option value="">Elige tipo respuesta</option>
              <option value="SiNo">Si-No</option>
              <option value="Abierta">Abierta</option>
              <option value="SinRespuesta">Sin respuesta</option>
            </Form.Select>
          </Form.Group>
          {insertQuestion.tipo_respuesta === 'SiNo'
            ? <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Respuesta correcta</Form.Label>
                <Form.Select
                  name="respuesta_correcta"
                  onChange={onChangeInsertQuestion}
                >
                  <option value="">Elige una respuesta para la pregunta</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
            : null}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setInsertQuestion ({
              grupo_preguntas: '',
              tipo_pregunta: '',
              orden: '',
              pregunta: '',
              tipo_respuesta: '',
              respuesta_correcta: '',
            });
            setShowInsert (false);
          }}
        >
          Cerrar
        </Button>
        <Button variant="primary" onClick={() => queryInsertQuestion ()}>
          Registrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect(() => {
    queryData();
  }, [])

  return (
    <Container className="mt-2 text-center">
      <h1 className="text-center">PREGUNTAS ESV</h1>
      <Row className="mb-3">
        <Col xs="3">
          <Button variant="success" onClick={() => setShowInsert (true)}>
            Añadir Pregunta
          </Button>

          {modalInsert}
        </Col>
      </Row>
      <Modal show={show} onHide={() => setShow (false)}>
        <Modal.Header closeButton>
          <Modal.Title>Información Pregunta</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: '5%', margin: '0px '}}>
          <Form id="form-modify-question">
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Nueva Pregunta</Form.Label>
              <Form.Control
                name="pregunta"
                as="textarea"
                value={modifyQuestion.pregutna}
                rows={3}
                onChange={onChangeQuestion}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow (false);
              setModifyQuestion ({
                pregunta: '',
                id_pregunta: '',
              });
            }}
          >
            Cerrar
          </Button>
          <Button
            htmlFor="form-modify-question"
            variant="primary"
            onClick={() => queryModificarQuestion ()}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="text-center justify-content-center align-items-center">
        {questions.length > 0
          ? <Table responsive hover bordered striped>
              <thead>
                <tr className="text-center">
                  <th>Orden</th>
                  <th>Pregunta</th>
                  <th colSpan={2}>Acción</th>
                </tr>
              </thead>
              <tbody>
                {questions.map ((item, i) => (
                  <tr key={i}>
                    <td className="text-center">{item.orden}</td>
                    <td>{item.pregunta}</td>
                    <td className="text-center">
                      <Button
                        variant="warning"
                        onClick={() => handleModalQuestion (item.id)}
                      >
                        Modificar
                      </Button>
                    </td>
                    <td className="text-center">
                      <Button
                        variant="danger"
                        onClick={() => queryDeleteQuestion (item.id)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          : groupQuestion !== '' && validatedDatos
              ? <Alert variant="primary" className="text-center fs-4 w-50">
                  No hay preguntas relacionadas con este grupo de preguntas
                </Alert>
              : null}
      </Row>
    </Container>
  );
}
export default PreguntasEsv;
