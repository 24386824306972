import { Container, Button, Form, Row, Col, Table } from "react-bootstrap";
import { useEffect, useState } from "react";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


function Divulgacion() {

    const [usuario, setUsuario] = useState(localStorage.getItem('ajfdkjakfljasdkljflkasjdflkasjdlfkaslkjffklasjdflkajslkfjalkdfjaksljdfklajdlfkjjfalk'));
    const [id_observaciones, setId_observaciones] = useState("");
    const [datos, setDatos] = useState([]);
    const [ocultar, setOcultar] = useState("none");

    const [nombre, setNombre]=useState('')
    const [co, setCO]=useState('')

    const MySwal = withReactContent(Swal);
    let icono = "";
    let titulo = "";
    let mensaje = "";

  function Archivo() {
    let fl = document.getElementById("archivo");
    console.log(fl.files[0]);
    const data = new FormData();
    data.append("accion", 'Divulgacion');
    data.append("id_observaciones", id_observaciones);
    data.append("centro", co);
    data.append("nombre", nombre);
    data.append("file", fl.files[0]);
    console.log(data)
    if(fl.files[0] !== undefined){
      fetch(process.env.REACT_APP_SERVIDOR +'/api/subir-archivo', {
          method: 'POST',
          body: data
      })
      .then(response => response.json())
      .then(data => {
          console.log(data);
          if(data.status == "Error"){
            icono = "error";
            titulo = "Mensaje";
            mensaje = "Error al subir el archivo";
          }else{
            icono = "success";
            titulo = "Mensaje";
            mensaje = "Archivo registrado exitosamente";
            window.location = window.location; 
          }
          //alert(data.status+' mensaje:'+data.message);
      })
      .catch(error => {
          console.log(error);
      });
  }else{
    icono = "error";
    titulo = "Mensaje";
    mensaje = "Ingresa el archivo a registrar";
  }
  if(mensaje !== ''){

    mostrarAlert(icono, titulo, mensaje);
  }
}
function mostrarAlert(icono, titulo, mensaje) {
  Swal.fire({
      icon: icono,
      title: mensaje
  })
}


  function ConsultaArchivos() {
    fetch(process.env.REACT_APP_SERVIDOR +"/consultaDivulgaciones").then((result) => {
      result.json().then((resp) => {
        console.table(resp);
        setDatos(resp);
      });
    });
  }
  function CantidadObservaciones() {
    fetch(process.env.REACT_APP_SERVIDOR +"/cantidad_divulgaciones").then((result) => {
      result.json().then((resp) => {
        console.table(resp);
        setId_observaciones(resp[0].cantidad);
      });
    });
  }
  function GetUsuario() {
    /* console.log("USUSARIO")
    console.log(usuario) */
    
    fetch(process.env.REACT_APP_SERVIDOR +"/consulta_usuario_perfiles/"+usuario).then((result) => {
        result.json().then((resp) => {
            if( resp[0].perfiles === 'Administrador' ||
            resp[0].perfiles === "Responsable Gestion Institucional" ||
            resp[0].perfiles === "Comportamiento Humano" 
            ){
                console.log("Entro")
                setOcultar('block');
            }
        });
    });
}
useEffect(() => {
    GetUsuario();
}, []);
  useEffect(() => {
    ConsultaArchivos();
  }, []);
  useEffect(() => {
    CantidadObservaciones();
  }, []);

  return (
    <>
      <br />
      <h1>Divulgación de la Política de SV</h1>

      <Container>
        <br />
        <div>
          <p className="texto">
            La empresa cuenta con una página web en la cual por medio del link{" "}
            <a href="https://192.168.10.7/calidad/CONSULTA/SubirArchivo/files/PL-SIG-008.%20POLITICA%20DE%20SEGURIDAD%20VIAL.v001.pdf">
              https://192.168.10.7/calidad/CONSULTA/SubirArchivo/files/PL-SIG-008.%20POLITICA%20DE%20SEGURIDAD%20VIAL.v001.pdf
            </a>{" "}
            se puede consultar la política de seguridad vial de manera interna
            para todos los trabajadores que tienen acceso a la página; de igual
            forma se divulga la política por medio de las carteleras ubicadas en
            las sedes de la empresa y en socialización con el personal.
          </p>
          <br />
        </div>
        <div style={{display: ocultar, border: '2px solid black'}} >
            <h2 style={{textAlign:'center'}}>Registro de archivo de divulgación</h2>
            <br/>
            <Form>
                <Row>
                    
                    <Col xs={4}>
                        <Form.Group>
                            <Form.Label>Centro Operativo</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Ingrese el centro operativo"  onChange={(e) => { setCO(e.target.value) }} />
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <Form.Group>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Ingrese el nombre del archivo"  onChange={(e) => { setNombre(e.target.value) }} />
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                    <Form.Group id="formFileMultiple"  className="mb-3" enctype="multipart/form-data">
                        <Form.Label>Archivo</Form.Label>
                        <Form.Control type="file" id="archivo" className="archivo" />
                    </Form.Group>

                    </Col>
                    
                </Row>
                <Row
                className="justify-content-md-center"
                style={{
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                }}
                >
                <Button
                    style={{ width: "100px" }}
                    onClick={(e) => {
                    Archivo();
                    }}
                >
                    Registrar
                </Button>
                </Row>
            </Form>
            <br />

        </div>
            <br />
        <div style={{textAlign:'center', border: '2px solid black'}}>
            <p>
            <strong>Soporte de capacitaciones: </strong>
            </p>
            {datos.map((fila, id) => (
                <p>
                    ►<a href={fila.url_pdf} key={id}>{fila.centro+"-"+fila.nombre}</a>
                </p>
            
            ))}

        </div>
      </Container>
    </>
  );
}
export default Divulgacion;
