import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Form, Button, Modal } from 'react-bootstrap';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import Cookies from 'universal-cookie';
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
import moment from 'moment';
import {BsFillTrashFill} from 'react-icons/bs';
import {RiFileEditFill} from 'react-icons/ri';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

function InspeccionVehiculos() {
  const access_token = new Cookies().get(
    'perv_v2_cookies_user_kdfoeinapjdkfawep'
  );
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  

  const [validated, setValidated] = useState(false);
  const [surveyValidated, setSurveyValidated] = useState(false);
  const [exportExcelValidated, setExportExcelValidated] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [dataindex, setDataindex] = useState(false);
  const [showExportExcel, setShowExportExcel] = useState(false);
  const [requiredDataSurvey, setrequiredDataSurvey] = useState({
    groupQuestion: '',
    warning: '',
    initialDate: moment().format('YYYY-MM-DD'),
    finalDate: moment().format('YYYY-MM-DD'),
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [infoExportExcel, setInfoExportExcel] = useState({
    groupQuestion: '',
    warning: '',
    initialDate: '',
    finalDate: '',
  });
  const [dataSurveys, setDataSurveys] = useState([]);
  const [dataColor, setDataColor] = useState([]);
  const [headersTable, setHeadersTable] = useState([]);
  const [surveyUpdate, setSurveyUpdate] = useState([]);
  const [showModalInsert, setShowModalInsert] = useState (false);
  const [showModalFile, setShowModalFile] = useState (false);
  const [data, setData] = useState([]);  
  const [tipoVehiculo, setTipoVehiculo] = useState([]);
  const [tipoVehiculoPlaca, setTipoVehiculoPlaca] = useState([]);
  const fechaActual = moment(new Date).format('YYYY-MM-DD')

  const onChangeRequiredDataSurvey = e => {
    const { name, value } = e.target;
    setrequiredDataSurvey({
      ...requiredDataSurvey,
      [name]: value,
    });
  };
  const [infoInsertFile, setInfoInsertFile] = useState({file: ''});

  const validityForm = (e, page) => {
    let validacion = '';
    if (e !== null) {
      const form = e.currentTarget;
      validacion = form.checkValidity();
    } else {
      validacion = false;
    }

    if (validacion === false) {
      e.preventDefault ();
      e.stopPropagation ();
      setValidated(true);
    } else {
      e.preventDefault();
      querySurveys(page);
    }
  };

  const querySurveys = async page => {
    try {
      const { groupQuestion, initialDate, finalDate, warning } = requiredDataSurvey;
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
        `/api/v2/list/surveys?groupQuestion=${groupQuestion}&initialDate=${initialDate}&finalDate=${finalDate}&warning=${warning}&page=${page || pagination.currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const headersTableAux = [];
      for (let key in data.data[0]) {
        headersTableAux.push(key);
      }
      console.log("headersTableAux")
      console.log(headersTableAux)
      setHeadersTable(headersTableAux)
      setDataSurveys(data.data)
      setDataColor(data.dataColor)
      
      console.log("data.data")
      console.log(data.data)
      console.log(data.dataColor)
      console.log(headersTable)
      setPagination({
        currentPage: page || pagination.currentPage,
        totalPages: data.pages,
      });
      setValidated(false);
    } catch (error) {
      console.log('Error query survays: ' + error.message);
    }
  };

  const queryUpdateSurvey = async e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setSurveyValidated(true);
    } else {
      e.preventDefault();
      try {
        const { data } = await axios.put(
          process.env.REACT_APP_SERVIDOR + '/api/v2/update/survey',
          surveyUpdate,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: '1',
            },
          }
        );

        if (data.message === 'success') {
          setShowSurvey(false);
          setSurveyValidated(false);
          querySurveys(pagination.currentPage);
        }
      } catch (error) {
        console.log('Error updated survey: ' + error.message);
      }
    }
  };

  const queryExportExcel = async e => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
        `/api/v2/dowload/excel/survey?initialDate=${requiredDataSurvey.initialDate} 00:00:00&finalDate=${requiredDataSurvey.finalDate} 23:59:59&groupQuestion=${requiredDataSurvey.groupQuestion}&page=${pagination.currentPage}&warning=${requiredDataSurvey.warning}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' });
      const file = new Blob([excelBuffer], { type: fileType });
      saveAs(
        file,
        `Encuesta_fecha_consulta${moment().format('DD-MM-YYYY')}_${moment().unix()}.xlsx`
      );
      setShowExportExcel(false);
      setInfoExportExcel({
        groupQuestion: '',
        initialDate: '',
        finalDate: '',
      });
     
    } catch (error) {
      console.log('Error exporting Excel file: ' + error.message);
    } 
  };

  const onChangeSurvey = e => {
    const { name, value } = e.target;
    setSurveyUpdate({
      ...surveyUpdate,
      [name]: value,
    });
  };

  const onChangeExportExcel = e => {
    const { name, value } = e.target;
    setInfoExportExcel({
      ...infoExportExcel,
      [name]: value,
    });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:child td, &:child th': {
      border: 1,
    },
  }));

  const ModalUpdateSurvey = (
    <Modal
      show={showSurvey}
      onHide={() => setShowSurvey(false)}
      dialogClassName="modal-80w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>Encuesta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id="formularioSurvey"
          noValidate
          validated={surveyValidated}
          onSubmit={queryUpdateSurvey}
        >
          <Row lg={3} md={3} sm={2} xs={2}>
            {headersTable.map(
              (question, i) =>
                i === 0
                  ? null
                  : <Col key={i} className="mb-2">
                    <Form.Group>
                      <Form.Label>
                        <div
                          className="text-truncate col-12"
                          style={{ maxWidth: '150px' }}
                        >
                          {question}
                        </div>
                      </Form.Label>
                    </Form.Group>
                    <Form.Control
                      name={question}
                      placeholder="Question"
                      value={surveyUpdate[question]}
                      onChange={onChangeSurvey}
                      required
                    />
                  </Col>
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button form="formularioSurvey" variant="dark" type="submit">
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
  const handleCloseModalInsert = () => {
    setShowModalInsert (false);
  };
  const onChangeFilePdf = async e => {
    let file = e.target.files[0];
    setInfoInsertFile({
      ...infoInsertFile,
      file: file,
    })
  }
  const modalFile = (
    <Modal show={showModalFile} onHide={() => setShowModalFile(false)}>
    <Modal.Body style={{margin: '2%'}}>
      <Form.Group className="mb-3">
        <Form.Label>File</Form.Label>
        <Form.Control type="file" accept='application/pdf' onChange={onChangeFilePdf} />
      </Form.Group>
      <Button onClick={() => queryInserFileCar()}>Guardar</Button>
    </Modal.Body>
  </Modal>
  )
  
  const modalInsert = (
    <Modal 
    show={showModalInsert}
    onHide={handleCloseModalInsert}
    backdrop="static"
    >
      <Modal.Body style={{margin: '2%'}}>
        <Container>
          <Row>
            <Col xs={3} md={3}>
              PROCEDIMIENTO
            </Col>
            <Col xs={7} md={7} style={{color: '#f58b84'}}>
              {data === undefined || data.nombre_documentacion === '' || data.nombre_documentacion === null ? 'NO HAY INFORME' : <a target={'new'} href={process.env.REACT_APP_HOST_FTP+"pdf_pesv/"+data.nombre_documentacion}>INFORME</a>}
              
            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill aria-label="upload pdf" onClick={() => {setShowModalFile(true);}} />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill onClick={() => queryDeleteFileFtp()} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
      </Modal.Footer>

    </Modal>
  );
  
  const queryData = async () => {
    try {
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/formato_archivos_compania',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setData(data[0]);
    } catch (error) {
      console.log ('Error while fetching data from server: ' + error.message);
    }
  };


   const queryInserFileCar = async () => {
    let formData = new FormData();
    formData.append("filePdfVehiculos", infoInsertFile.file);
    try {
      if(infoInsertFile.file === '' || infoInsertFile.file === null){
        Swal.fire({
          icon: 'error',
          title: 'Debes elegir un archivo'
        });
      }else if(infoInsertFile.fecha === ''){
        Swal.fire({
          icon: 'error',
          title: 'Debes elegir una fecha de vencimiento'
        })
      }else{
        let pagina = "Inspeccion_Vehiculo";
        const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + `/api/v2/files/formato_archivos_compania/${pagina}`,
        formData,
        {
          headers: {
            Permission: '1',
            Authorization: `Bearer ${access_token}`,
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          }
        });
        if(data.message === 'success'){
          setShowModalFile(false);
          setInfoInsertFile({file: ''})
          queryData();
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
    }
  }
    const queryDeleteFileFtp = async () => {
    try {
      let pagina = {nombre: "Inspeccion_Vehiculo"};
      
      const {data} = await axios.post(
        process.env.REACT_APP_SERVIDOR + `/api/v2/files/delete/formato_archivos_compania/`,pagina,
      {
        headers: {
          Permission: '1',
          Authorization: `Bearer ${access_token}`,
        }
      });
      if(data.message === 'success'){
        queryData()
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire ({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log('Error while deleting file from ftp: ' + error.message);
    }
  }
  const queryLista = async () =>{
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + '/api/v2/listar/vehiculos',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if(data.estado){
        setTipoVehiculo(data.datos)
      }

    } catch (error) {
      console.log(
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  const queryListaPlaca = async () =>{
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
        `/api/v2/listar/vehiculosPlaca?tipo=3`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if(data.estado){
        setTipoVehiculoPlaca(data.datos)
        console.log("PLACAS")
        console.log(data.datos)
      }

    } catch (error) {
      console.log(
        'Error while fetching data centro from server: ' + error.message
      );
    }
  };
  useEffect(() => { 
    querySurveys()   
    queryLista()
    queryData()
    queryListaPlaca()
    
    //validityForm(null, "Vehículos de carga");
  }, []);

  return (
    <Container className="mt-3 text-center inspeccion">
      <Row className="mb-3">
        <Col>
          <h2>INSPECCIÓN VEHÍCULOS</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="18">
          <Form
            noValidate
            validated={validated}
            onSubmit={e => validityForm(e, 1)}
          >
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="auto formVehiculo">
                <Form.Label className="fs-6">Grupo de Preguntas</Form.Label>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Select
                  onChange={onChangeRequiredDataSurvey}
                  name="groupQuestion"
                  required
                  value={requiredDataSurvey.groupQuestion}
                >
                  <option value="">Elige grupo de preguntas</option>
                {
                  tipoVehiculo.map((fila, id) =>(
                    <option key={id} value={fila.id}>{fila.nombre}</option>
                  ))
                }
                </Form.Select>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Label className="fs-6">Advertencia</Form.Label>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Select
                  onChange={onChangeRequiredDataSurvey}
                  name="warning"
                  value={requiredDataSurvey.warning}
                >
                <option value="" style={{ backgroundColor: 'white' }}>Todos</option>
                <option value="Verde" style={{ backgroundColor: '#a8f584', color: 'black' }}>OK</option>
                <option value="Amarillo" style={{ backgroundColor: '#f9f65b', color: 'black' }}>EN PROCESO</option>
                <option value="Rojo" style={{ backgroundColor: '#f58b84', color: 'black' }}>ADVERTENCIA</option>
             
                
                </Form.Select>
              </Col>
             
              <Col xs="auto formVehiculo">
                <Form.Label>Rango de Fechas</Form.Label>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Control
                  type="date"
                  name="initialDate"
                  value={requiredDataSurvey.initialDate}
                  onChange={onChangeRequiredDataSurvey}
                  autoComplete="off"
                />
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Control
                  type="date"
                  name="finalDate"
                  value={requiredDataSurvey.finalDate}
                  onChange={onChangeRequiredDataSurvey}
                  autoComplete="off"
                />
              </Col>
              <Col xs="auto formVehiculo">
                <Button variant="dark" type="submit">Consultar</Button>
              </Col>
              <Col xs="auto formVehiculo">
                <Button variant="dark" onClick={() => (window.location.href = '/lista-preguntas-preoperacional')}>
                  Lista Chequeo Preoperacional
                </Button>
              </Col>
              <Col xs="auto formVehiculo">
                <Button variant="danger"  onClick={() => setShowModalInsert (true)}>
                  Procedimiento
                </Button>
              </Col>
              <Col xs="auto formVehiculo" className="text-end">
            <Button
              variant="success"
              onClick={() => queryExportExcel()}
            >
              Exportar Excel
            </Button>
          </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      
      {ModalUpdateSurvey}      
      {modalInsert}
      {modalFile}
      
    <div>

    {dataSurveys.map((survey, index) =>
                      index < 0
                        ? null
                        : 
                        <Accordion expanded={expanded === index} onChange={handleChange(index)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header-${index}"
          sx={{ backgroundColor: survey['Estado'] === 'Verde' ? '#a8f584' : survey['Estado'] === 'Amarillo' ? '#f9f65b' : '#f58b84', justifyContent: 'flex-end'  }}
        >
          <Typography sx={{ width: '25%', flexShrink: 0 }}>
                                Fecha :
                                {survey['Fecha']}
                              </Typography>
                              <Typography sx={{ width: '50%', flexShrink: 0, textAlign: 'left'  }}>
                              Nombre : {survey[headersTable[4]]}
                              </Typography>
                              <Typography sx={{ width: '15%', flexShrink: 0, textAlign: 'left'  }}>
                                Placa : {survey[headersTable[5]]}
                              </Typography>
          <Typography sx={{width: '10%', color: 'text.secondary', justifyContent: 'flex-end' }}>{survey['Estado'] === 'Verde' ? 'OK' : survey['Estado'] === 'Amarillo' ? 'EN PROCESO' : 'ADVERTENCIA'}</Typography>
        </AccordionSummary>
        
        
       {headersTable.map( (header, i) =>
                      i < 1
                        ? null
                        :<AccordionDetails>
          <Typography sx={{ color: 'text.secondary', textAlign: 'left' }}>
                     {header}:
          </Typography>
          
             <Typography sx={{ color: 'text.primary', textAlign: 'left'}}>
                     {survey[header]}
                        
             </Typography>
             
        </AccordionDetails>)}
        <AccordionActions>
        <Button
                              variant="warning"
                              onClick={() => {
                                setShowSurvey(true);
                                setSurveyUpdate(survey);
                              }}
                            >
                              Modificar
                            </Button>
        </AccordionActions>
      </Accordion>
                  )}
    </div>
    </Container>

  );
}

export default InspeccionVehiculos;
