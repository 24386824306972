import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Chart } from 'react-google-charts';
import { Container, Row, Col, Form, Button, Modal, Table } from 'react-bootstrap';
import { Paper } from '@mui/material';
import { fontSize } from '@mui/system';

function IndicadorESV() {
  
  const access_token = new Cookies().get(
    'perv_v2_cookies_user_kdfoeinapjdkfawep'
  );
  const [validated, setValidated] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);

  const [rangeDate, setRangeDate] = useState({
    initialDate: '',
    finalDate: '',
  });
  const [dataSurveys, setDataSurveys] = useState([]);
  const [dataEncuesta, setDataEncuesta] = useState([]);
  const [dataId, setDataId] = useState([]);
  const [visualizacionMensaje, setVisualizacionMensaje] = useState('none');
  const [visualizacionBottonS, setVisualizacionBottonS] = useState('revert');
  const [visualizacionBottonD, setVisualizacionBottonD] = useState('none');

  const optionsChart = {
    titlePosition: 'none',
    pieHole: 0.7,
    pieSliceBorderColor: '#222222',    
    legend: 'none',
    labels: {
      font: {
          size: 8
      }
  }

  };

  const validityForm = (e) => {

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();

      if (dataEncuesta.length > 0) {

        if (rangeDate.initialDate !== '' && rangeDate.finalDate !== '') {
          setDataId([])
          setShowSurvey(true);
        } else {
          alert("Ingresa la fecha")
          e.stopPropagation();
          setValidated(false);
        }

      } else {
        alert("No tiene preguntas relacionadas")
      }
    }
  };
  const llenararchivos = (estado) => {

    let opcPregunta = [];
    let opc = document.getElementsByClassName('selector');
    let id_pregunta = document.getElementsByClassName('id_pregunta');

    for (let i = 0; i < opc.length; i++) {
      if (estado) {
        setVisualizacionBottonS('none')
        setVisualizacionBottonD('revert')

        opcPregunta.push(parseInt(id_pregunta[i].value));
        opc[i].checked = true;
      } else {
        setVisualizacionBottonS('revert')
        setVisualizacionBottonD('none')

        opc[i].checked = false;
      }
    }
    setDataId(opcPregunta)
  }
  const modalPreguntas = (

    <Modal
      show={showSurvey}
      onHide={() => { setShowSurvey(false); setVisualizacionBottonS('revert'); setVisualizacionBottonD('none') }}
      dialogClassName="modal-80w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton><h1>Preguntas de la encuesta</h1></Modal.Header>
      <Modal.Body>
        <Table >
          <tbody>
            {
              dataEncuesta.map((fila, id) => (
                <tr key={id} style={{ padding: '10px' }}>
                  <input type="hidden" className="id_pregunta" value={fila.id} />
                  <td><input type="checkbox" className="selector" id={'selectorPregunta' + fila.id} value={fila.id} onChange={() => handleGuardar(fila.id)} /></td>
                  <td style={{ textAlign: 'left' }} >{fila.pregunta}</td>
                </tr>
              ))
            }
            <tr><td colSpan={3}>
              <Button form="formularioSurvey" variant="succes" style={{ display: visualizacionBottonS }} onClick={(e) => { llenararchivos(true) }}><b>SELECCIONAR TODO</b></Button>
              <Button form="formularioSurvey" variant="succes" style={{ display: visualizacionBottonD }} onClick={(e) => { llenararchivos(false) }}><b>DESELECCIONAR TODO</b></Button>
              {/* 
            <input type="button" value='Seleccionar todo' onChange={() => llenararchivos()}  /> */}
            </td></tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>

          </Col>
          <Col>
          </Col>
        </Row>
        <Button form="formularioSurvey" variant="dark" onClick={() => { queryGroupQuestions(); setShowSurvey(false); }}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
  const handleGuardar = id => {
    let array = dataId;

    let estado = document.getElementById('selectorPregunta' + id).checked;

    if (array.length > 0) {
      let opc = array.find(e => e === id);

      if (estado) {
        if (!opc) {
          array.push(id)
        }
      } else {
        if (opc) {
          let indice = array.findIndex(e => e === id);
          array.splice(indice, 1)
        }
      }
    } else {
      array.push(id)
    }
    setDataId(array)
  }

  const onChangeRangeDate = e => {
    const { name, value } = e.target;
    setRangeDate({
      ...rangeDate,
      [name]: value,
    });
  };

  const queryGroupQuestions = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
        `/api/v2/list/data/chart/survey?initialDate=${rangeDate.initialDate}&finalDate=${rangeDate.finalDate}&id_pregunta=${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataSurveys(data);
      if (data.length === 0) {
        setVisualizacionMensaje('block');
      }

    } catch (error) {
      console.log('Error getting data group questions: ' + error);
    }

  };
  const queryEncuestas = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
        `/consulta_pregunta_co/inicadorESV?groupQuestion=2`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataEncuesta(data);
    } catch (error) {
      console.log('Error getting data group questions: ' + error);
    }
  }
  useEffect(() => {
    queryEncuestas();
  }, []);

  return (
    <Container className="mt-3 text-center">
      <Row className="mb-3">
        <h3>INDICADOR ESV</h3>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {validityForm(e);  setVisualizacionBottonS('revert'); setVisualizacionBottonD('none')}}>
            <Row className="align-items-center text-center justify-content-center">
              <Col xs="auto">
                <Form.Label className="fs-4">Rango de Fechas</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  type="date"
                  name="initialDate"
                  required
                  onChange={onChangeRangeDate}
                />
              </Col>
              <Col xs="auto">
                <Form.Control
                  type="date"
                  name="finalDate"
                  required
                  onChange={onChangeRangeDate}
                />
              </Col>
              <Col xs="auto">
                <Button variant="dark" type="submit">Consultar</Button>
              </Col>
              {modalPreguntas}
            </Row>
          </Form>
        </Col>
      </Row>
      {dataSurveys.length > 0
        ? <Row md="2" ms="2" xs="1" >
          {dataSurveys.map((survey, i) => (

            <Col key={i} className="mb-2" style={{ minWidth: '400px', minHeight: '300px', margin: '5px 0px 5px 0px' }} >
              <Paper elevation={3} sx={{ padding: '0px' }} style={{ minHeight: '350px' }} >
              <Chart
                  chartType="BarChart"
                  data={survey.dataChart}
                  options={optionsChart}
                  width={'100%'}
                  height={'280px'}
                />
                <div className="text-truncate col-12 px-1"
                  style={{ textAlign: 'justify', maxWidth: 'max-content', whiteSpace: 'unset' }}
                >
                  {survey.question}
                </div>
              </Paper>
            </Col>
          ))}
        </Row>
        :
        <h6 style={{ display: visualizacionMensaje }}>HO HAY INFORMACION REGISTRADA DETRO DE ESTE RANGO DE FECHAS</h6>
      }
    </Container>
  );
}

export default IndicadorESV;
