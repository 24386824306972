import {
  Container,
  Table,
  Form,
  Col,
  Row,
  Modal,
  Button,
  Spinner
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
import { RiFileEditFill } from 'react-icons/ri';
import { BsFillTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import moment from 'moment';

function Registros() {
  const access_token = new Cookies().get('perv_v2_cookies_user_kdfoeinapjdkfawep');
  const [usuario, setUsuario] = useState(
    localStorage.getItem(
      'ajfdkjakfljasdkljflkasjdflkasjdlfkaslkjffklasjdflkajslkfjalkdfjaksljdfklajdlfkjjfalk'
    )
  );

  const [evento, setEvento] = useState('');
  const [data, setData] = useState([]);
  const [datos, setDatos] = useState([]);
  const [ocultar, setOcultar] = useState('revert');
  const [showModalEspera, setShowModalEspera] = useState(true);
  const [validarExistencias, setValidarExistencias] = useState(true);
  const [showModalFile, setShowModalFile] = useState(false);
  const [infoInsertFile, setInfoInsertFile] = useState({ file: '' });
  const [showModalInsert, setShowModalInsert] = useState(false);

  const llenarCamposUsuario = async (accion) => {
    if (accion === null || accion === '') { accion = 0; }

    const { data } = await axios.get(
      process.env.REACT_APP_SERVIDOR + `/consultar_caracterizacion/${accion}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    if (data.estado) {
      setDatos(data.datos);
      setShowModalEspera(false);
    } else {
      setValidarExistencias(false);
      setShowModalEspera(false);
    }
  }

  const queryData = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/formato_archivos_compania_registros',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      console.log("Paso");
      setData(data[0]);
      console.log(data[0]);
    } catch (error) {
      console.log('Error while fetching data from server: ' + error.message);
    }
  };

  function GetUsuario() {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    if (access_token === null) {
      window.location.href = '/';
    }
  }

  const queryExportExcel = async e => {
    let informacion = []

    for (let i = 0; i < datos.length; i++) {
      informacion[i] = {
        Fecha_Accidente: datos[i].fecha_accidente,
        Cedula: datos[i].cedula,
        Nombre: datos[i].nombre_empleado,
        Centro: datos[i].centro,
        Evento: datos[i].evento,
        Tipo_Contrato: datos[i].tipo_contratacion,
        Mecanismo: datos[i].mecanismo_accidente,
        Tipo_Lesion: datos[i].tipo_lesion,
        Lugar_Suceso: datos[i].lugar_suceso,
        Parte_del_Cuerpo_Afectada: datos[i].cuerpo_afecatado,
        Agente_Lesion: datos[i].agente_lesion,
        Descipcion: datos[i].descripcion_accidente,
        Cargo: datos[i].cargo,
        Comportamiento: datos[i].comportamiento_condicion,
        Dias_de_Incapacidad: datos[i].dias_incapacidad,
        Accion_Correctiva: datos[i].accion_correctiva,
        Responsable_Accion: datos[i].responsable_accion,
        Fecha_accion: datos[i].fecha_ejecucion_accion,
      };
    }
    try {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      const ws = XLSX.utils.json_to_sheet(informacion);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' });
      const file = new Blob([excelBuffer], { type: fileType });
      saveAs(
        file,
        `Caracterizacion${moment().format('DD-MM-YYYY')}_${moment().unix()}.xlsx`
      );
    } catch (error) {
      console.log('Error exporting Excel file: ' + error.message);
    }
  };

  const cerrarModalEspera = async () => {
    setShowModalEspera(false);
  }
  const handleCloseModalInsert = () => {
    setShowModalInsert(false);
  };

  const onChangeFilePdf = async e => {
    let file = e.target.files[0];
    setInfoInsertFile({
      ...infoInsertFile,
      file: file,
    });
  }

  const modalFile = (
    <Modal show={showModalFile} onHide={() => setShowModalFile(false)}>
      <Modal.Body style={{ margin: '2%' }}>
        <Form.Group className="mb-3">
          <Form.Label>File</Form.Label>
          <Form.Control type="file" accept='application/pdf' onChange={onChangeFilePdf} />
        </Form.Group>
        <Button onClick={() => queryInserFileCar()}>Guardar</Button>
      </Modal.Body>
    </Modal>
  )

  const queryDeleteFileFtp = async () => {
    try {
      let pagina = { nombre: "Inspeccion_Registros" };

      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + `/api/v2/files/delete/formato_archivos_compania`, pagina,
        {
          headers: {
            Permission: '1',
            Authorization: `Bearer ${access_token}`,
          }
        });
      if (data.message === 'success') {
        queryData();
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
      console.log('Error while deleting file from ftp: ' + error.message);
    }
  }

  const modalInsert = (
    <Modal
      show={showModalInsert}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Body style={{ margin: '2%' }}>
        <Container>
          <Row>
            <Col xs={3} md={3}>
              PROCEDIMIENTO
            </Col>
            <Col xs={7} md={7} style={{ color: '#f58b84' }}>
              {data === undefined || data.nombre_documentacion === '' || data.nombre_documentacion === null ? 'NO HAY INFORME' : <a target={'new'} href={process.env.REACT_APP_HOST_FTP + "pdf_pesv/" + data.nombre_documentacion}>INFORME</a>}

            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill aria-label="upload pdf" onClick={() => { setShowModalFile(true); }} />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill onClick={() => queryDeleteFileFtp()} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const queryInserFileCar = async () => {
    let formData = new FormData();
    formData.append("filePdfVehiculos", infoInsertFile.file);
    try {
      if (infoInsertFile.file === '' || infoInsertFile.file === null) {
        Swal.fire({
          icon: 'error',
          title: 'Debes elegir un archivo'
        });
      } else if (infoInsertFile.fecha === '') {
        Swal.fire({
          icon: 'error',
          title: 'Debes elegir una fecha de vencimiento'
        });
      } else {
        let pagina = "Inspeccion_Registros";
        const { data } = await axios.post(process.env.REACT_APP_SERVIDOR + `/api/v2/files/formato_archivos_compania/${pagina}`,
          formData,
          {
            headers: {
              Permission: '1',
              Authorization: `Bearer ${access_token}`,
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          });
        if (data.message === 'success') {
          setShowModalFile(false);
          setInfoInsertFile({ file: '' });
          queryData();
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permiso para esta acción',
        });
      }
    }
  }

  useEffect(() => {
    GetUsuario();
    llenarCamposUsuario(null);
    queryData();
  }, []);

  return (
    <Container className="text-center mt-3">
      <h1>REGISTROS DE CARACTERIZACIÓN</h1>
      <Row className="mb-3">
        <Col xs='2'>
          <Form.Label column sm="2">Eventos</Form.Label>
        </Col>
        <Col xs='3'>
          <Form.Select onChange={e => setEvento(e.target.value)}>
            <option value=""></option>
            <option value="CONDICION DE PELIGRO VIAL">Condicion de peligro vial</option>
            <option value="CONDICION DE PELIGRO LABORAL">Condicion de peligro laboral</option>
            <option value="CONDICIÓN DE SALUD">Condicion de salud</option>
            <option value="SINIESTRO VIAL">Siniestro vial</option>
            <option value="ACCIDENTE LABORAL">Accidente laboral (Transito/Condución)</option>
          </Form.Select>
        </Col>
        <Col xs='1'>
          <Button variant='dark' onClick={() => llenarCamposUsuario(evento)}>Consultar</Button>
        </Col>
        <Col xs='1'>
          <Button variant="dark" onClick={() => (window.location.href = '/AtecionVictimasTarjetaReporte')}>Registrar</Button>
        </Col>
        <Col xs='2'>
          <Button variant="success" onClick={() => queryExportExcel()}>Exportar</Button>
        </Col>

        <Col xs="2">
          <Button variant="danger" onClick={() => setShowModalInsert(true)}>
            Procedimiento
          </Button>
        </Col>
      </Row>
      <br />
      <Modal
        show={showModalEspera}
        backdrop="static"
        keyboard={false}
        onHide={cerrarModalEspera}
        centered
      >
        <Modal.Body>
          <div className='spinnerEspera'>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className='spannerGrow'
            />
            <label><strong>Cargando Información</strong></label>
          </div>
        </Modal.Body>
      </Modal>
      {modalFile}
      {modalInsert}
      <Table responsive hover bordered >
        <thead>
          <tr>
            <th style={{ minWidth: '100px' }}>Evento</th>
            <th style={{ minWidth: '100px' }}>Fecha de Accidente</th>
            <th style={{ minWidth: '100px' }}>Cedula</th>
            <th style={{ minWidth: '150px' }}>Nombre Implicado</th>
            <th style={{ minWidth: '50px' }}>Centro</th>
            <th style={{ minWidth: '150px' }}>Tipo de contratación</th>
            <th style={{ minWidth: '150px' }}>Mecanismo</th>
            <th style={{ minWidth: '150px' }}>Tipo de lesión</th>
            <th style={{ minWidth: '80px' }}>Lugar del suceso</th>
            <th style={{ minWidth: '150px' }}>Parte del cuerpo afectada</th>
            <th style={{ minWidth: '150px' }}>Agente lesión</th>
            <th style={{ minWidth: '400px' }}>Descripción del accidente</th>
            <th style={{ minWidth: '100px' }}>Cargo</th>
            <th style={{ minWidth: '100px' }}>Comportamiento o condición</th>
            <th style={{ minWidth: '100px' }}>Días incapacidad</th>
            <th style={{ minWidth: '200px' }}>Información del evento</th>
            <th style={{ minWidth: '200px' }}>Lección Aprendida</th>
            <th style={{ minWidth: '50px', display: ocultar }}>Accion</th>
          </tr>
        </thead>
        <tbody>
          {datos.map((fila, id) => (
            <tr key={id} style={{ textAlign: 'justify' }}>
              <td>{fila.evento}</td>
              <td>{fila.fecha_accidente}</td>
              <td>{fila.cedula}</td>
              <td>{fila.nombre_implicado}</td>
              <td>{fila.centro}</td>
              <td>{fila.tipo_contratacion}</td>
              <td>{fila.mecanismo_accidente}</td>
              <td>{fila.tipo_lesion}</td>
              <td>{fila.lugar_suceso}</td>
              <td>{fila.cuerpo_afecatado}</td>
              <td>{fila.agente_lesion}</td>
              <td>{fila.descripcion_accidente}</td>
              <td>{fila.cargo}</td>
              <td>{fila.comportamiento_condicion}</td>
              <td>{fila.dias_incapacidad}</td>
              <td style={{ textAlign: 'center' }}>
                {
                  fila.leccion_aprendida !== ''
                    ?
                    <a style={{ color: 'black', border: 'none' }} target="_new" href={process.env.REACT_APP_HOST_FTP + "/documentoCaracterizacion/" + fila.leccion_aprendida}>VER</a>
                    :
                    'No hay Archivo'
                }
              </td>
              <td style={{ textAlign: 'center', color: 'black', border: 'none' }}>
                {
                  fila.fotografias_evento !== ''
                    ?
                    <a style={{ color: 'black', border: 'none' }} target="_new" href={process.env.REACT_APP_HOST_FTP + "/documentoCaracterizacion/" + fila.fotografias_evento}>VER</a>
                    :
                    'No hay Archivo'
                }
              </td>
              <td style={{ display: ocultar }}>
                <Button variant='dark'
                  href={'AtecionVictimasCaracterizacion/Modificar?id=' + fila.id}
                >
                  Modificar
                </Button>
              </td>
            </tr>
          ))}
          {
            !validarExistencias
              ?
              <tr><td colSpan={22}>NO HAY DATOS REGISTRADOS</td></tr>
              : null
          }
        </tbody>
      </Table>
    </Container>
  );
}
export default Registros;
