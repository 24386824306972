import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';

function RegistrarNumero () {
  const [validated, setValidated] = useState (false);
  const [centros, setCentros] = useState ([]);
  const [infoPhone, setInfoPhone] = useState ({
    numero_celular: '',
    id_centro_fk: '',
  });

  let navigate = useNavigate();
  const queryCentros = async () => {
    try {
      const cookie = new Cookies ();
      const access_token = cookie.get (
        'perv_v2_cookies_user_kdfoeinapjdkfawep'
      );
      const {data} = await axios.get (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/centro',
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      setCentros (data);
    } catch (error) {
      console.log ('Error while querying api centros: ' + error.message);
    }
  };

  const queryInsertPhone = async e => {
    const cookie = new Cookies ();
    const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');
    const form = e.currentTarget;
    if (form.checkValidity () === false) {
      e.preventDefault ();
      e.stopPropagation ();
      setValidated (true);
    } else {
      e.preventDefault ();
      try {
        const {data} = await axios.post (
          process.env.REACT_APP_SERVIDOR + '/api/v2/insert/numeros/telefono',
          infoPhone,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: '1',
            },
          }
        );

        if (data.message === 'success') {
          Swal.fire (
            'Registro exitoso',
            'Ya puedes utilizar el número',
            'success'
          );
          setInfoPhone ({
            numero_celular: '',
            id_centro_fk: '',
          });
          setValidated (false);
          navigate('/consultar-numero-celular')
        } else if (data.message === 'Ya existe el número') {
          Swal.fire ({
            icon: 'info',
            title: 'No fue posible registrar',
            text: 'El número que intentaste registrar ya existe y no puede ser duplicado.',
          });
        }
      } catch (error) {
        console.log (
          'Error during validation and inserting number: ' + error.message
        );
      }
    }
  };

  const onChangePhone = e => {
    const {name, value} = e.target;
    setInfoPhone ({
      ...infoPhone,
      [name]: value,
    });
  };

  useEffect (() => {
    queryCentros ();
  }, []);

  return (
    <Container
      className="text-center"
      style={{
        width: '60%',
      }}
    >
      <Row>
        <h1>Registrar Número Celular</h1>
      </Row>
      <Row>
        <Form noValidate validated={validated} onSubmit={queryInsertPhone}>
          <Row className="mb-3 text-center justify-content-center">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Número Celular</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Número Celular"
                name="numero_celular"
                value={infoPhone.numero_celular}
                onChange={onChangePhone}
                maxLength={10}
              />
              <Form.Control.Feedback type="invalid">
                Número de celular obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 text-center justify-content-center">
            <Form.Group as={Col} md="6" controlId="idCentro">
              <Form.Label>Centro</Form.Label>
              <Form.Select
                placeholder="Centro"
                name="id_centro_fk"
                value={infoPhone.id_centro_fk}
                onChange={onChangePhone}
                required
              >
                <option value="">Asigna un centro</option>
                {centros.map ((element, e) => (
                  <option key={e} value={element.id_centro}>
                    {element.centro_descripcion}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Centro obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="text-center justify-content-center">
            <Col md={6}>
              <Button type="submit">Registrar número</Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}

export default RegistrarNumero;
