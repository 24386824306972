import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import Cookies from 'universal-cookie';

function ModalUploadComponent ({queryVersions}) {
   const access_token = new Cookies().get('perv_v2_cookies_user_kdfoeinapjdkfawep');
   const [show, setShow] = useState(false);
   const [uploadFile, setUploadFile] = useState(false);
   const [centroSelected, setCentroSelected] = useState(false);
   const [dataCentros, setDataCentros] = useState([]);

   const onChangeFile = e => {
      let file = e.target.files[0];
      const formData = new FormData();
      formData.append('csv_encuestados' ,file);
      setUploadFile(formData);
   }

   const queryCentros = async () => {
      try {
         const access_token = new Cookies().get('perv_v2_cookies_user_kdfoeinapjdkfawep');
         const {data} = await axios.get(process.env.REACT_APP_SERVIDOR + '/api/v2/list/centro', {
            headers: {
               Authorization: 'Bearer ' + access_token
            }
         });

         setDataCentros(data)
      } catch (error) {
         console.log('Error getting centros : ' + error.message);
      }
   }

   const queryUploadFile = async () =>  {
      try {
         const {data} = await axios.post(process.env.REACT_APP_SERVIDOR + `/api/v2/upload/file/csv?centro=${centroSelected}`, uploadFile, {
            headers: {
               Authorization: `Bearer ${access_token}`,
               Permission: '1'
            }
         });
         setShow(false);
         queryVersions();
      } catch (error) {
         console.log('Error uploading file csv: ' + error.message);
      }
   }

   useEffect(() => {
      queryCentros();
   }, [])

   return(
      <>
      <Button variant="success" onClick={() => setShow(true)}>Importar csv</Button>
      <Modal show={show} onHide={() => setShow(false)}>
         <Modal.Header closeButton>
            <Modal.Title className='mx-1'>Cargar CSV</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form.Control className='mb-2' type="file" onChange={onChangeFile} />
            <Form.Select name="centro" onChange={e => setCentroSelected(e.target.value)}>
               <option value="">Elige un centro</option>
               {
                  dataCentros.map((centro, i) => 
                     <option key={i} value={centro.id_centro}>{centro.centro_descripcion}</option>
                  )
               }
            </Form.Select>
         </Modal.Body>
         <Modal.Footer>
            <Button variant="dark" onClick={() => queryUploadFile()}>Cargar</Button>
         </Modal.Footer>
      </Modal>
      </>
   )
}

export default ModalUploadComponent;