import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from "sweetalert2";

function RegistrarMantenimiento() {
    const cookie = new Cookies();
    const access_token = cookie.get('perv_v2_cookies_user_kdfoeinapjdkfawep');
    const tipo_vehiculo = cookie.get('tipo_vehiculo');

    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [validacionAccion, setValidacionAccion] = useState(false);
    const [vehiculo, setVehiculo] = useState([]);
    const [rutina, setRutina] = useState([]);
    const [datosRutina, setDatosRuntina] = useState([]);
    const [validacionRutina, setValidacionRutina] = useState(false);
    const [validarFactura, setValidarFactura] = useState(false);

    const [datos, setDatos] = useState({
        Fecha: moment().format('YYYY-MM-DD'),
        Vehiculo: '',
        Kilometraje: '',
        Proveedor: '',
        Factura: '',
        Precio: '',
        Rutina: '',
        Descripcion: ''
    });
    const [datosFactura, setDatosFactura] = useState({
        FechaFactura: '',
        Documento: ''
    });

    const onChangeInfo = e => {
        const { name, value } = e.target;
        setDatos({
            ...datos,
            [name]: value,
        });
    };const onChangeFileFecha = e => {
        let fecha = e.target.value;
        setDatosFactura({
          ...datosFactura,
          FechaFactura: fecha
        })
      }
    
      const onChangeFilePdf = async e => {
        let file = e.target.files[0];
        setDatosFactura({
          ...datosFactura,
          Documento: file
        })
       
      };

    const ConsultarVehiculos = async () => {

        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/mantenimiento/consultar/vehiculos', { tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        setVehiculo(data.datos);

        if (window.location.search !== '') {
            let id_mantenimiento = (window.location.search).split('=')[1];
            const { data } = await axios.post(
                process.env.REACT_APP_SERVIDOR +
                '/api/v2/listar/consultar/Mantenimiento/id', { tipo: tipo_vehiculo, id_mantenimiento: id_mantenimiento },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );
            setValidarFactura(true)
            setValidacionAccion(true)
            if (data.estado) {
                const datosMantenimiento = data.datos[0];
                setDatos({
                    Fecha: moment(datosMantenimiento.fecha).format('YYYY-MM-DD'),
                    Vehiculo: datosMantenimiento.id_vehiculo_fk,
                    Kilometraje: datosMantenimiento.kilometraje,
                    Proveedor: datosMantenimiento.proveedor,
                    Factura: datosMantenimiento.factura,
                    Precio: datosMantenimiento.precio_factura,
                    Rutina: datosMantenimiento.id_rutina_fk,
                    Descripcion: datosMantenimiento.descripcion
                });                
                
            }
        }
    }
    const ConsultarRutina = async () => {

        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/listar/rutinas', { tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        console.log(data)
        if(data.estado){

            setRutina(data.datos);
        }else{
            Swal.fire({
                icon: 'warning',
                title: 'Mensaje',
                text: 'No existen rutinas registradas',
                showConfirmButton: false,
                footer: '<a href="/gestion-mantenimiento/consultar-rutinas">REGISTRAR</a>'
            })
        }
    }
    const MostrarTablaRutina = async (e) => {
        onChangeInfo(e);

        setValidacionRutina(false)
        setDatosRuntina([])

        let rutina = e.target.value;

        const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
            '/api/v2/mantenimiento/consultar_runtinas', { id_rutina: rutina, tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );

        if (data.estado) {
            setDatosRuntina(data.datos)
            setValidacionRutina(true)
        } else {
            setValidacionRutina(false)
            setDatosRuntina([])

        }
    }
    const validityForm = async (e) => {
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault();

            if (!validacionAccion) {
                await RegistrarMatenimiento();
            } else {
                await ModificarMantenimiento();

            }

        }
    };
    const RegistrarMatenimiento = async () => {
        try {
            
            const { data } = await axios.post(
                process.env.REACT_APP_SERVIDOR + '/api/v2/mantenimiento/registrar_mantenimientos',
                datos,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Permission: '1',
                    },
                }
            );
            if (data.message === "success") {
                await queryCumplimiento();
                mostrarAlert("success", "Mensaje", "Registro Exitoso");
            /*    setDatos({
                    Fecha: moment().format('YYYY-MM-DD'),
                    Vehiculo: '',
                    Kilometraje: '',
                    Proveedor: '',
                    Factura: '',
                    Precio: '',
                    Rutina: '',
                    Descripcion: ''
                });*/
                setValidacionRutina(false);
                setValidated(false);
                navigate('/gestion-mantenimiento/registrar-mantenimiento/Modificar?id='+data.idMantenimiento);             
                window.location.replace('');
            }
        } catch (error) {
            console.log('Error insert mantenimiento: ' + error.message);
            if (error.response.status === 403) {
                Swal.fire ({
                  icon: 'error',
                  title: 'No tienes permiso para esta acción',
                });
              }
        }
    }
    const ModificarMantenimiento = async () => {
        try {
            let id_kilometraje = (window.location.search).split('=')[1];
            const tipo_vehiculo = cookie.get('tipo_vehiculo');
            const { data } = await axios.post(
                process.env.REACT_APP_SERVIDOR + '/api/v2/mantenimiento/actualizar_mantenimiento',
                { dato: datos, tipo: tipo_vehiculo, idKilometraje: id_kilometraje },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Permission: '1',
                    },
                }
            );
        
            if (data.message === "success") {
                queryInsertFileFactura(id_kilometraje);   
                await queryCumplimiento();
                mostrarAlert("success", "Mensaje", "Actualización Exitoso");
                setDatos({
                    Fecha: moment().format('YYYY-MM-DD'),
                    Vehiculo: '',
                    Kilometraje: '',
                    Horometro: '',
                    Galones: '',
                    Precio: ''
                });
                setValidated(false);
                navigate('/gestion-mantenimiento/consultar-mantenimiento');             
            } 
           
        } catch (error) {
            console.log('Error updated mantenimiento: ' + error.message);
            if (error.response.status === 403) {
                Swal.fire ({
                  icon: 'error',
                  title: 'No tienes permiso para esta acción',
                });
              }
        }
    }  
    const queryInsertFileFactura = async (id) => {
        const vehiculos = vehiculo.find(element => element.id_vehiculo === datos.Vehiculo);
         let formData = new FormData ();
        formData.append ('filePdfDriver', datosFactura.Documento);
        try {
          const {data} = await axios.post (
            process.env.REACT_APP_SERVIDOR +
              `/api/v2/mantenimiento/files/documentation/${id}/${vehiculos.placa}/${datosFactura.FechaFactura}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Performance: '1',
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          if (data.message === 'success') {
            mostrarAlert("success", "Mensaje", "Actualización Exitoso");
                setDatos({
                    Fecha: moment().format('YYYY-MM-DD'),
                    Vehiculo: '',
                    Kilometraje: '',
                    Proveedor: '',
                    Factura: '',
                    Precio: '',
                    Rutina: '',
                    Descripcion: ''
                });
                setValidated(false);
                navigate('/gestion-mantenimiento/consultar-mantenimiento');
          }
        } catch (error) {
          if (error.response.status === 403) {
            Swal.fire ({
              icon: 'error',
              title: 'No tienes permiso para esta acción',
            });
          }
        } 
    }
    const queryCumplimiento = async () =>{
        await axios.post(
            process.env.REACT_APP_SERVIDOR + '/api/v2/listar/mantenimiento/informe_cumplimiento/tipo_vehiculo',
            {tipo: tipo_vehiculo },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
    }

    const mostrarAlert = (icono, titulo, mensaje) => {
        Swal.fire({
            icon: icono,
            title: mensaje,
        });
    }

    useEffect(() => {
        ConsultarRutina();
        ConsultarVehiculos();
    }, []);


    return (
        <>
            <Container className="text-center mt-3">
                <Row className="mb-3">
                    <h1>Formulario de Mantenimiento</h1>
                </Row>
                <Row className="mb-3">
                    <Form noValidate validated={validated} onSubmit={e => validityForm(e)}>
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2">
                                <Form.Label>Fecha: </Form.Label></Col>
                            <Col xs="3">
                                <Form.Group >
                                    <Form.Control required name="Fecha" type="date" value={datos.Fecha} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid">Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="2"><Form.Label>Vehículo: </Form.Label></Col>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Select required name="Vehiculo" value={datos.Vehiculo} onChange={onChangeInfo}>
                                        <option value="">Seleccione...</option>
                                        {
                                            vehiculo.map((fila, id) => (
                                                <option key={id} value={fila.id_vehiculo}>{fila.placa}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Obligatorio
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2">{tipo_vehiculo === '4' ? <Form.Label>Horometro: </Form.Label> : <Form.Label>Kilometraje: </Form.Label>}</Col>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Control required name="Kilometraje" type="number" value={datos.Kilometraje} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="2"><Form.Label>Proveedor:</Form.Label></Col>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Control required name="Proveedor" type="text" value={datos.Proveedor} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Form.Label>Factura: </Form.Label></Col>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Control required name="Factura" type="text" value={datos.Factura} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="2"><Form.Label>Precio Factura:</Form.Label></Col>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Control required name="Precio" type="number" value={datos.Precio} onChange={onChangeInfo} />
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Form.Label>Rutina</Form.Label></Col>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Select required name="Rutina" value={datos.Rutina} onChange={(e) => MostrarTablaRutina(e)}>
                                        <option value="">Seleccione...</option>
                                        {
                                            rutina.map((fila, id) => (
                                                <option key={id} value={fila.rutina}>{fila.rutina}</option>
                                            ))
                                        }
                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="2"><Form.Label>Descripción de Actividad:</Form.Label></Col>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Control required name="Descripcion" type="textarea" value={datos.Descripcion} onChange={onChangeInfo}  style={{ minHeight: '150px' }} maxLength={250}/>
                                    <Form.Control.Feedback type="invalid"> Obligatorio</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        {
                            validarFactura
                            ?
                                <Row className="mb-1 align-items-center justify-content-center">
                                    <Col xs="2"><Form.Label>Fecha Factura</Form.Label></Col>
                                    <Col xs="3">
                                        <Form.Group>
                                            <Form.Control name="FechaFactura" type="date" onChange={onChangeFileFecha} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="2"><Form.Label>Documento de Facturación:</Form.Label></Col>
                                    <Col xs="3">
                                        <Form.Group>
                                            <Form.Control type="file" accept='application/pdf' onChange={onChangeFilePdf} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            :
                            <Row></Row>
                        }
                        <br />

                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="2"><Button type="submit" variant='dark'>Aceptar</Button></Col>
                        </Row>
                    </Form>
                    <br />
                </Row>
            </Container>


            {
                validacionRutina
                    ?
                    <Container className="text-center mt-3">
                        <Row className="mb-1 align-items-center justify-content-center">
                            <Col xs="12">
                                <Table stickyHeader aria-label="table-sticky">
                                    <thead>
                                        <tr>
                                            <th>Rutina</th>
                                            <th>Kilometraje</th>
                                            <th>Tipo</th>
                                            <th>Operaciones</th>
                                            <th>Actividad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            datosRutina.map((fila, id) =>
                                                <tr key={id}>
                                                    <td>{fila.rutina}</td>
                                                    <td>{fila.kilometraje}</td>
                                                    <td>{fila.tipo}</td>
                                                    <td>{fila.operaciones}</td>
                                                    <td>{fila.actividad}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>

                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container></Container>
            }
        </>
    )
}
export default RegistrarMantenimiento;