import { Container, Button, Navbar, Nav, NavDropdown, Modal, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react';


import integrantes from '../img/firma.png';


function Cosevi() {
  

    const [show, setShow] = useState(false);

  function modalActa(){
    return(
      <>
            <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Prueba
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
      </Modal>
      </>
    );
    }

    return (
        <>
        <br/>
            <h1>CONFORMACIÓN DEL COMITÉ DE SEGURIDAD VIAL</h1>
            
            <Container>
            <br/>
                <div>
                    
                    <p className='texto'>
                    Este comité busca que la SOCIEDAD FERRETERA DE COMERCIO S.A.S de manera interdisciplinaria tenga un constante control de su riesgo vial mediante la pro-actividad frente a los factores de riesgo que identifica, y la generación de planes de acción para disminuir la probabilidad de materialización de factores de riesgo en accidentes de tránsito.<br/><br/>
                    El Comité de Seguridad Vial será nombrado por el Representante Legal. El nombramiento de los integrantes del comité de seguridad vial, se realizará mediante acta escrita, se realizará una reunión de nombramiento, previa convocatoria, para socializar, explicar los objetivos, responsabilidades, roles y funciones.<br/><br/>
                    Esta reunión se realizará por primera vez para la conformación del comité de seguridad vial y será presidida por el Representante Legal de la SOCIEDAD FERRETERA DE COMERCIO SAS, cada vez que se presente cambio del funcionario o trabajador que desempeña un rol en el comité de seguridad vial, se hará una nueva reunión para oficializar el nombramiento del nuevo integrante del comité y el retiro del anterior.<br/><br/>

                    </p>
                    <br/>
                    <p style={{textAlign: 'right'}}>
                    <strong>Acta del comité de Seguridad Vial<br/> </strong>
                    Anexo No 1. Conformación del Comité de Seguridad Vial<br/>
                    Noviembre de 2021<br/>
                   
                    </p>
                    <h3>Objetivo del Comité de Seguridad Vial</h3>
                    <h5>Objetivo General</h5>
                    <p className='texto'>
                    Promover una cultura de seguridad vial, estableciendo las acciones concretas encaminadas al desarrollo e implementación del Plan Estratégico de Seguridad Vial (PESV), con el fin de prevenir, atender y controlar la ocurrencia de incidentes y accidentes viales, fortaleciendo la importancia del cuidado de la salud e integridad física de todo el personal de la SOCIEDAD FERRETERA DE COMERCIO S.A.S. (SOFERCO), anulando y/o mitigando los efectos surgidos de dicha eventualidad y articulando dichas actividades al Sistema de Gestión de la Seguridad y Salud en el Trabajo de la empresa.
                    </p>
                    <br/>
                    <h5>Objetivos Específicos</h5>
                    <p className='texto'>                   

                    •	Planear, organizar y ejecutar acciones necesarias para controlar en forma oportuna y segura el normal desarrollo de las actividades cotidianas de los conductores, promoviendo en los usuarios de la vía, la adopción de conductas apropiadas y el cumplimiento de las normas de tránsito vigentes. <br/>
                    •	Capacitar y entrenar a todo el personal que conduzca vehículos dentro de las actividades propias de su cargo, en temas como manejo preventivo (técnicas) y seguridad vial (normatividad), generando y fomentando cultura y buenas prácticas que contribuyan a la reducción de los índices de accidentalidad vial mediante la participación permanente de todos los actores viales (peatones, pasajeros y conductores), creando conciencia y cambios de actitud frente a los diferentes riesgos viales. <br/>
                    •	Garantizar el mantenimiento preventivo de los vehículos de la Compañía asignados a los Conductores. <br/>
                    •	Identificar los peligros y evaluar los riesgos en materia de Seguridad Vial presentes en las vías internas y externas utilizadas durante el desarrollo de las actividades propias de la empresa. <br/>
                    •	Garantizar la atención oportuna en caso de presentarse algún evento de tránsito, en desarrollo de sus actividades. <br/>

                    </p>
                    <br/>
                    <h5>Directrices de la Alta Dirección</h5>
                    <p className='texto'>                   
                        La seguridad vial en la SOCIEDAD FERRETERA DE COMERCIO S.A.S. (SOFERCO),  es compatible con la dirección estratégica de la empresa y cuenta con un alto nivel de compromiso frente a la prevención de accidentes de tránsito, reducción de las muertes y la disminución del riesgo de lesiones o daños derivados de los mismos.<br/> <br/>
                        La SOCIEDAD FERRETERA DE COMERCIO S.A.S. (SOFERCO) mediante su representante legal, se compromete a dar cumplimiento a los lineamientos establecidos en el Plan Estratégico de Seguridad Vial de la empresa, articulado al Sistema de Gestión de la Seguridad y Salud en el Trabajo (SGSST), teniendo en cuenta el seguimiento y control a lo planeado, diseñado, implementado y las mediciones de las acciones que resulten para cumplir con los objetivos a favor de la seguridad vial en todos los niveles de la organización. <br/>

                    </p>
                    <br/>
                    <h5>Integrantes del Comité</h5>
                    <p className='texto'>            
                        La alta dirección de la SOCIEDAD FERRETERA DE COMERCIO S.A.S ha definido que el comité de seguridad vial estará conformado por los cargos de la empresa relacionados con logística y despachos<br/>
                    </p>
                    <br/>
                    <Table>
                      <thead>
                        <th>#</th>
                        <th>NOMBRE</th>
                        <th>CARGOS</th>
                        <th>AREA</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Rodrigo Cuadros Gil</td>
                          <td>Representante Legal y Gerente</td>
                          <td>Gerencia</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Steven Sánchez Rojas</td>
                          <td>Responsable  CEDI Palmira</td>
                          <td>Logística</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Brayan Arcila Cardona</td>
                          <td>Responsable  CEDI Buga</td>
                          <td>Logística</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Jesús Hernán Echeverría</td>
                          <td>Responsable Despachos Buga</td>
                          <td>Logística</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Lida Viviana Pérez</td>
                          <td>Responsable Despachos Palmira</td>
                          <td>Logística</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Lina Marcela Alvarez</td>
                          <td>Responsable Gestión Humana</td>
                          <td>Gestión Humana</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Leonardo González</td>
                          <td>Responsable Logístico</td>
                          <td>Logística</td>
                        </tr>
                      </tbody>
                    </Table>
                    <br/>
                    <br/>
                    <h5>Roles y Funciones del Comité</h5>
                    <p className='texto'>     
                    
                        El establecimiento del Comité de Seguridad Vial o Comité del Plan Estratégico de Seguridad Vial, que diseñará, definirá, programará, gestionará, etc., todos los aspectos necesarios para la puesta en marcha del PESV, a que hace relación la Ley 1503 de 2011 y el Decreto 2851 de 2013, será un escenario estratégico en el proceso de participación para la planeación y ejecución las distintas fases del plan. <br/> <br/>
                        La SOCIEDAD FERRETERA DE COMERCIO S.A.S cuenta con la conformación del comité de seguridad vial los cuales desarrollan las siguientes funciones: <br/> <br/>
                        ▪	Analizará los resultados obtenidos en el diagnóstico inicial y formulará la hoja de ruta a seguir, conducente a reforzar los aspectos favorables encontrados, mitigar los riesgos y diseñar acciones para garantizar un cambio de actitud en los diversos actores de la movilidad en la compañía. <br/>
                        ▪	Identificar los factores de riesgo y establecer un plan de acción personalizado para cada uno de ellos.  <br/>
                        ▪	Presentar, discutir y determinar los programas académicos a desarrollar con los distintos actores.  <br/>
                        ▪	Considerar la adopción de un estándar de seguridad, que garantice la integridad y bienestar y minimice los riesgos de un accidente de tránsito.  <br/>
                        ▪	Evaluar los requerimientos y la oferta disponible, frente a proveedores y talleres para los procesos de diagnóstico, mantenimiento preventivo y mantenimiento correctivo de los vehículos.  <br/>
                        ▪	Programar fechas, tiempos y lugares para las capacitaciones con los conductores, sus equipos de trabajo y otros actores de la empresa, entidad u organización.  <br/>
                        ▪	Determinar las acciones de control o auditorías viales que se consideren pertinentes. <br/>
                        ▪	Presentar las campañas y acciones de acompañamiento a desarrollar durante todo el año.  <br/>
                        ▪	Establecer los cronogramas de las diversas actividades a ejecutar y hacer seguimiento de las mismas.  <br/>
                        ▪	Elaborar informes periódicos para la Gerencia, Ministerio de Transporte, organismo de tránsito u otros interesados, que den cuenta de las acciones programas, adelantadas y por ejecutar, analizando el impacto, costo-beneficio y aporte en la generación de hábitos, comportamientos y conductas favorables a la seguridad vial del país.       <br/> <br/>

                        El comité será el encargado de definir la visión, los objetivos, alcance y vigilar el cumplimiento del Plan Estratégico y establecer planes encaminados a la mejora continua. <br/>

                    </p>
                    
                    <br/>
                    <h5>Roles y Funciones de la Alta Dirección</h5>
                    <p className='texto'>                   
                       
                        ▪	Involucrar un representante de cada área de la organización, según sea pertinente y que se entiende, participe en la creación y difusión de los objetivos de la seguridad vial. <br/>
                        ▪	Definir los roles y funciones de cada integrante de la organización, a efectos de cumplir los objetivos trazados en el Plan Estratégico de Seguridad Vial. <br/>
                        ▪	Implicarse en su financiación, asegurando el presupuesto necesario para la implantación de las diferentes medidas que se vayan a adoptar. <br/>
                        ▪	Establecer un proceso ágil, dinámico y eficaz de participación de los trabajadores y de los representantes sindicales, si fuera el caso. <br/>
                        ▪	Garantizar a los grupos de trabajo el tiempo que precisen para sus reuniones. <br/>
                        ▪	Adoptar las decisiones oportunas que hagan posible que se lleven a buen término las medidas que se propongan. <br/>
                        ▪	Garantizar que el personal que labora en cada área cumpla con lo establecido en el Plan Estratégico de Seguridad Vial y los documentos relacionados aplicables. <br/>

                    </p>
                    
                    <br/>
                    <h5>Frecuencia de Reuniones del Comité SV</h5>
                    <p className='texto'>                   
                    El Comité SV desarrollará reuniones con una frecuencia trimestral de manera ordinaria y de manera extraordinaria cuando se requiera, previa citación del Responsable PESV.
                    </p>
                    <br/>
                    {/* <a href='ActaConformacionCOSEVI'  onClick={() => setShow(true)}>Acta de conformación</a> */}
                    <a href='ActaConformacionCOSEVI'  >Acta de conformación</a>
                    <br/><br/>

                </div>
                {/* <modalActa /> */}
            </Container>
        </>
        );
    }
    export default Cosevi;